import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid, Container, CardActionArea, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useAuth } from '../../auth/AuthContext';
import config from '../../config';
import AsyncStorage from '@react-native-async-storage/async-storage';

function SelectTournee({ onTourneesSelectionnees, selectedTournees }) {
    const [tournees, setTournees] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // État pour la valeur de recherche
    const [filteredTournees, setFilteredTournees] = useState([]); // État pour les tournées filtrées
    const [selectedTourneeIds, setSelectedTourneeIds] = useState([]);
    const [page, setPage] = useState(1);
    const tourneesParPage = 6;
    const { currentUser } = useAuth();

    const [collectiviteId, setCollectiviteId] = useState('');

  useEffect(() => {
    const fetchCollectiviteID = async () => {
      const collectiviteId = await AsyncStorage.getItem('selectedCollectiviteId');
      setCollectiviteId(collectiviteId);
    };

    fetchCollectiviteID();
  }, [])

    useEffect(() => {
        if(collectiviteId){
        const fetchTournees = async () => {
 
            try {
                console.log(`${config.domain}/api/obtenir_trajets?iduser=${currentUser.iduser}&collectivite_id=${collectiviteId}`)
                const response = await axios.get(`${config.domain}/api/obtenir_trajets?iduser=${currentUser.iduser}&collectivite_id=${collectiviteId}`);
                setTournees(response.data);
                setFilteredTournees(response.data); // Initialise les tournées filtrées avec toutes les tournées
            } catch (error) {
                console.error('Erreur lors de la récupération des tournées:', error);
            }
        };

        fetchTournees();
    }
    }, [currentUser.iduser,collectiviteId]);

  
    useEffect(() => {
        // Initialiser avec les tournées sélectionnées passées en prop, si disponibles
        if (selectedTournees && selectedTournees.length > 0) {
            setSelectedTourneeIds(selectedTournees);
        }
        console.log("test 5 , ")
        console.log(selectedTournees)
    }, [selectedTournees, onTourneesSelectionnees]);

    useEffect(() => {
        // Filtre les tournées basé sur le terme de recherche
        const results = tournees.filter(tournee =>
            tournee.nom.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredTournees(results);
        setPage(1); // Réinitialise la pagination à la première page
    }, [searchTerm, tournees]);

    const handleToggleTourneeSelection = (tournee) => {
        // Vérifier si la tournée est déjà sélectionnée
        const currentIndex = selectedTourneeIds.findIndex(selected => selected.id === tournee.id);
        const newSelectedTourneeIds = [...selectedTourneeIds];
    
        if (currentIndex === -1) {
            // Si la tournée n'est pas déjà sélectionnée, l'ajouter
            newSelectedTourneeIds.push({ id: tournee.id, nom: tournee.nom });
        } else {
            // Si la tournée est déjà sélectionnée, la supprimer
            newSelectedTourneeIds.splice(currentIndex, 1);
        }
    
        setSelectedTourneeIds(newSelectedTourneeIds);
        if (onTourneesSelectionnees) {
            // Passer les ID des tournées sélectionnées si nécessaire
            onTourneesSelectionnees(newSelectedTourneeIds);
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const indexOfLastTournee = page * tourneesParPage;
    const indexOfFirstTournee = indexOfLastTournee - tourneesParPage;
    const currentTournees = filteredTournees.slice(indexOfFirstTournee, indexOfLastTournee);

    const isSelected = (id) => selectedTourneeIds.some(tournee => tournee.id === id);


    return (
        <Container style={{ marginTop: 20, marginBottom: 20, background: '#f0f0f0', padding: 20, borderRadius: 10 }}>
            <Typography variant="h4" component="h1" style={{ marginBottom: 20, textAlign: 'center' }}>
                Sélectionnez vos tournées
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                label="Rechercher une tournée"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ marginBottom: 20 }}
            />
            <Grid container spacing={3} justifyContent="center">
                {currentTournees.map(tournee => (
                    <Grid item key={tournee.id} xs={12} sm={6} md={4}>
                        <Card 
                            onClick={() => handleToggleTourneeSelection(tournee)}
                            style={{ 
                                cursor: 'pointer',
                                backgroundColor: isSelected(tournee.id) ? '#cfe8fc' : '#fff',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Tournée ID: {tournee.id}
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        {tournee.nom}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Pagination
                count={Math.ceil(filteredTournees.length / tourneesParPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
            />
        </Container>
    );
}

export default SelectTournee;
