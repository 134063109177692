import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext'; // Assurez-vous que le chemin est correct
import { Button, Card, CardContent, Typography, Container, Grid , Checkbox  } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../config';
const styles = {
  container: {
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  textarea: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    height: '100px',
  },
  button: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
    marginTop : 5,
    
    
  },
  button_modifier: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
    marginLeft : 50,
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#f8f9fa',
    border: '1px solid #ddd',
  },
  title: {
    textAlign: 'center',
    color: '#007bff',
  },

  card: {
    marginBottom: '15px',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    margin: 0,
    fontWeight: 'bold',
  },
  cardBody: {
    fontSize: '0.9rem',
    color: '#666',
  },

  colorSwatch: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '10px',
    border: '1px solid #ddd', // Optionnel, ajoute un léger contour pour améliorer la visibilité sur fonds clairs
  },
  colorInput: {
    padding: '5px',
    margin: '5px 0', // Assurez-vous qu'il y a un peu d'espace autour pour ne pas bloquer l'affichage
    height : "50px"
  },

  buttonSupprimer: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#dc3545', // Rouge
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginLeft: '10px',
    ':hover': { // Notez que :hover pourrait ne pas fonctionner directement dans l'objet de style inline de React. Vous aurez besoin de CSS ou de Styled Components pour cela.
      backgroundColor: '#c82333' // Une teinte légèrement plus foncée pour le survol
    },

    buttonContainer: {
   
      display: 'flex',
      justifyContent: 'flex-end', // Aligner les boutons à droite

    },
    
  },
  
  
};

const CategoryManager = ({ userId }) => {
  const [categories, setCategories] = useState([]);
  const [nom, setNom] = useState('');
  const [informations, setInformations] = useState('');
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const [editingCategoryId, setEditingCategoryId] = useState(null);
const [editedNom, setEditedNom] = useState('');
const [editedInformations, setEditedInformations] = useState('');

const [color, setColor] = useState('#FFFFFF'); // Définit la couleur par défaut
const [editedColor, setEditedColor] = useState('#FFFFFF'); 


const [collectiviteId, setCollectiviteId] = useState('');
const [selectedCategories, setSelectedCategories] = useState(new Set());
useEffect(() => {
  const fetchCollectiviteID = async () => {
    const collectiviteId = await AsyncStorage.getItem('selectedCollectiviteId');
    setCollectiviteId(collectiviteId);
  };

  fetchCollectiviteID();
}, []);

  useEffect(() => {
    if(collectiviteId ){
    const loadCategories = async () => {
      try {
        const response = await fetch(`${config.domain}/api/get_categories?id_collectivite=${collectiviteId}`);
        if (!response.ok) {
          throw new Error('Problème lors du chargement des catégories');
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Erreur:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }
  }, [collectiviteId]);

  // const handleCreateCategory = async (e) => {
  //   e.preventDefault();
  //   const newCategory = { nom, informations, userid: currentUser.iduser, color };


  //   try {
  //     const response = await fetch('http://localhost:5005/api/create_category', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(newCategory),
  //     });
  //     if (!response.ok) {
  //       throw new Error('Problème lors de la création de la catégorie');
  //     }
  //     const createdCategory = await response.json();
  //     setCategories([...categories, createdCategory]);
  //     setNom('');
  //     setInformations('');
  //   } catch (error) {
  //     console.error('Erreur:', error);
  //   }
  // };

  // const handleCreateCategory = async (e) => {
  //   e.preventDefault();
  //   const newCategory = { nom, informations, userid: currentUser.iduser, color };
  
  //   // Affiche un toast de chargement et enregistre son ID
  //   const toastId = toast.loading("Création de la catégorie en cours...");
  
  //   try {
  //     const response = await fetch('http://localhost:5005/api/create_category', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(newCategory),
  //     });
  //     if (!response.ok) {
  //       // Pour capturer une réponse d'erreur plus spécifique du serveur, vous pouvez ajouter:
  //       // const errorResponse = await response.json();
  //       // throw new Error(errorResponse.message || 'Problème lors de la création de la catégorie');
  //       throw new Error('Problème lors de la création de la catégorie');
  //     }
  //     const createdCategory = await response.json();
  
  //     // Ferme le toast de chargement
  //     toast.dismiss(toastId);
  
  //     // Mise à jour de l'état avec la nouvelle catégorie
  //     setCategories((prevCategories) => [...prevCategories, createdCategory]);
  
  //     // Réinitialisation des champs du formulaire
  //     setNom('');
  //     setInformations('');
  
  //     // Affiche un toast de succès
  //     toast.success('Catégorie créée avec succès !');
  //   } catch (error) {
  //     // Ferme le toast de chargement en cas d'erreur
  //     toast.dismiss(toastId);
  
  //     console.error('Erreur:', error);
  //     // Affiche un toast d'erreur avec le message d'erreur
  //     toast.error(`Erreur lors de la création de la catégorie: ${error.message}`);
  //   }
  // };

  const handleDeleteCategory = async (categoryId) => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?');
    if (confirmDelete) {
      try {
        // Affiche un toast de chargement
        const toastId = toast.loading("Suppression de la catégorie en cours...");
        await fetch(`${config.domain}/api/delete_category/${categoryId}`, {
          method: 'DELETE',
          // Ajoutez des headers supplémentaires si nécessaire, comme un token d'authentification
        });
  
        // Mettez à jour l'état pour enlever la catégorie supprimée
        const newCategories = categories.filter(category => category.id !== categoryId);
        setCategories(newCategories);
  
        // Affichez un toast de succès
        toast.dismiss(toastId);
        toast.success("Catégorie supprimée avec succès.");
      } catch (error) {
        console.error('Erreur lors de la suppression de la catégorie:', error);
        toast.error("Erreur lors de la suppression de la catégorie.");
      }
    }
  };
  

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    const newCategory = { nom, informations, userid: currentUser.iduser, color };
  
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Création de la catégorie en cours...");
  
    try {
      const response = await fetch(`${config.domain}/api/create_category`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCategory),
      });
  
      if (!response.ok) {
        // Gérer une réponse d'erreur spécifique du serveur si nécessaire
        throw new Error('Problème lors de la création de la catégorie');
      }
      
      const createdCategory = await response.json();
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
      // Mise à jour de l'état avec la nouvelle catégorie correctement
      // Assurez-vous que la réponse inclut les champs 'nom' et 'color'
      setCategories((prevCategories) => [...prevCategories, { ...createdCategory, nom, color , collectiviteId }]);
  
      // Réinitialisation des champs du formulaire
      setNom('');
      setInformations('');
      setColor('#FFFFFF'); // Réinitialiser la couleur par défaut
  
      // Affiche un toast de succès
      toast.success('Catégorie créée avec succès !');
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      console.error('Erreur:', error);
      // Affiche un toast d'erreur avec le message d'erreur
      toast.error(`Erreur lors de la création de la catégorie: ${error.message}`);
    }
  };

  const editCategory = (category) => {
    setEditingCategoryId(category.id);
    setEditedNom(category.nom);
    setEditedInformations(category.informations || '');
    setEditedColor(category.color);
  };

  
  const handleUpdateCategory = async (e, categoryId) => {
    e.preventDefault();
    const updatedCategory = { nom: editedNom, informations: editedInformations, color: editedColor };
  
    try {
      const response = await fetch(`${config.domain}/api/update_category/${categoryId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedCategory),
      });
      if (!response.ok) {
        throw new Error('Problème lors de la mise à jour de la catégorie');
      }
      // Mise à jour de l'affichage des catégories
      const updatedCategories = categories.map((cat) => {
        if (cat.id === categoryId) {
          return { ...cat, nom: editedNom, informations: editedInformations };
        }
        return cat;
      });
      setCategories(updatedCategories);
      setEditingCategoryId(null); // Quitter le mode édition
    } catch (error) {
      console.error('Erreur:', error);
    }
  };


  const handleCheckboxChange = (event, id) => {
    
    const newSet = new Set(selectedCategories);
    if (event.target.checked) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }
    setSelectedCategories(newSet);
  };


  const handleAssociateClick = () => {
    // Ici, vous pouvez envoyer les ID des tournées sélectionnées à votre backend
    // pour les associer à une collectivité, par exemple
    const category_ids = Array.from(selectedCategories);
    
    // Vérifier si des tournées sont sélectionnées
    if (category_ids.length === 0) {
      alert("Veuillez sélectionner au moins une tournée à associer.");
      return;
    }

    console.log(`${config.domain}/api/collectivites/${collectiviteId}/categories`)
  console.log(category_ids)
    // Ici, vous pouvez envoyer les ID des tournées sélectionnées à votre backend
    // pour les associer à une collectivité, par exemple
    fetch(`${config.domain}/api/collectivites/${collectiviteId}/categories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category_ids,
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erreur lors de l\'association des tournées.');
      }
      alert('Tournées associées avec succès à la collectivité.');
      setSelectedCategories(new Set()); // Réinitialiser les tournées sélectionnées après l'association
    })
    .catch(error => {
      console.error('Erreur lors de l\'association des tournées :', error);
      alert('Une erreur est survenue lors de l\'association des tournées.');
    });
  };
  

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Gestion des Catégories</h2>
      
      <form onSubmit={handleCreateCategory} style={styles.form}>
        <input
          style={styles.input}
          type="text"
          value={nom}
          onChange={(e) => setNom(e.target.value)}
          placeholder="Nom de la catégorie"
          required
        />
        <textarea
          style={styles.textarea}
          value={informations}
          onChange={(e) => setInformations(e.target.value)}
          placeholder="Informations supplémentaires"
        />


<input
  style={styles.colorInput}
  type="color"
  value={color}
  onChange={(e) => setColor(e.target.value)}
  required
/>


        <button style={styles.button} type="submit">Créer la catégorie</button>
      </form>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAssociateClick}
        style={{ marginBottom: '20px' }}
      >
        Associer les calendriers sélectionnés
      </Button>
      {loading ? (
        <p>Chargement des catégories...</p>
      ) : (
        categories.length > 0 ? (
          <ul style={styles.list}>
           {categories.map((category) => (
  <li key={category.id} style={styles.listItem}
  >
     <Checkbox
                  checked={selectedCategories.has(category.id)}
                  onChange={e => handleCheckboxChange(e, category.id)}
                  inputProps={{ 'aria-label': 'Select categories' }}
                />
    {editingCategoryId === category.id ? (
      // Formulaire de modification
      <form onSubmit={(e) => handleUpdateCategory(e, category.id)}>
        <input
          style={styles.input}
          type="text"
          value={editedNom}
          onChange={(e) => setEditedNom(e.target.value)}
          placeholder="Nom de la catégorie"
        />
        <textarea
          style={styles.textarea}
          value={editedInformations}
          onChange={(e) => setEditedInformations(e.target.value)}
          placeholder="Informations supplémentaires"
        />
       <input
  style={styles.colorInput}
  type="color"
  value={editedColor}
  onChange={(e) => setEditedColor(e.target.value)}
  required
/>


        <button type="submit" style={styles.button}>Mettre à jour</button>
        <button onClick={() => setEditingCategoryId(null)} style={styles.button}>Annuler</button>
      </form>
    ) : (
      <>

        <strong>{category.nom}</strong> - {category.informations || 'Pas d\'informations supplémentaires'}
        <span style={{ ...styles.colorSwatch, backgroundColor: category.color }}></span>

        <div style={{margin : 10}}>

        <div style={styles.buttonContainer}>
        <button onClick={() => editCategory(category)} style={styles.button_modifier}>Modifier</button>
        <button onClick={() => handleDeleteCategory(category.id)} style={styles.buttonSupprimer}>Supprimer</button>
      </div>
      </div>

      </>
    )}
  </li>
))}

          </ul>
        ) : (
          <p>Aucune catégorie à afficher.</p>
        )
      )}
      <ToastContainer />
    </div>
  );
};

export default CategoryManager;
