

import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, GeoJSON, useMap, Marker, Popup } from 'react-leaflet';
import { useMapEvents, Circle } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import 'leaflet-textpath';
import DepartementSelector from './DepartementSelector'; // Ajustez le chemin selon l'organisation de vos fichiers
import DepartementSelector2 from './DepartementSelector2';
import config from '../../config';

import './buttonswitch.css'
import CommuneLabels from './CommuneLabels'

import { Button,  activeButtonStyle, inactiveButtonStyle, SecondMapContainer, SecondMapTitle } from './stylesMap'
import { useAuth } from '../../auth/AuthContext';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';



const MyMap = ({ onEnregistrerCommune, onEnregistrerRue, trajetId , departements }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [selectedCommunes, setSelectedCommunes] = useState(["22270"]);
  const [selectedCommuneRue, setSelectedCommuneRue] = useState({});
  const { currentUser } = useAuth();
  const [selectedBuildingIds, setSelectedBuildingIds] = useState(new Set());
  const [selectedDepartements, setSelectedDepartements] = useState([]);
  const mapRef = useRef(null); // Référence à l'instance de la carte
  const geoJsonLayerRef = useRef(null); // Pour stocker le calque GeoJSON actuel


  const [selectedCommunesAvecRues, setSelectedCommunesAvecRues] = useState([]);
  const [selectedRues, setSelectedRues] = useState([]);




  const secondMapRef = useRef(null);

  const [ruesData, setRuesData] = useState([]);
  const [boolcliqueCommune, setBoolcliqueCommune] = useState(false);
  const [selectionMode, setSelectionMode] = useState('select');
  const [selectionModeCommune, setSelectionModeCommune] = useState('select');
  const currentModeRefCommune = useRef(selectionModeCommune);
  const [selectionSize, setSelectionSize] = useState(20);
  useEffect(() => {
    currentModeRefCommune.current = selectionModeCommune;
  }, [selectionModeCommune]);


  const [loadingToastId, setLoadingToastId] = useState(null);

  useEffect(() => {
    if (loadingToastId) {
      toast.dismiss(loadingToastId);
      setLoadingToastId(null);
    }
  }, [selectedCommunes]); 






  useEffect(() => {
    if (trajetId) {
      const fetchDetailsTrajet = async () => {
        try {
          // Remplacez 'http://localhost:5005' par l'URL de base de votre API si différente
          console.log(`${config.domain}/api/obtenir_details_trajet/${trajetId}`)
          const response = await axios.get(`${config.domain}/api/obtenir_details_trajet/${trajetId}`);
          const detailsTrajet = response.data;

          // Mettre à jour l'état avec les données récupérées
          setSelectedCommunes(detailsTrajet.communes);
          setSelectedRues(detailsTrajet.rues.map(rue => ({
            id_adresse: rue.id_adresse,
            nom_voie: rue.nom_voie,
            numero: rue.numero,
            codeinsee: rue.codeinsee,
            code_postal : rue.code_postal
          })));

          // Si vous avez besoin d'afficher les communes et les rues sur la carte, ajustez ici
          // Par exemple, mettre à jour geoJsonData pour les communes
          // et utiliser setRuesData pour les rues si nécessaire

        } catch (error) {
          console.error('Erreur lors de la récupération des détails du trajet:', error);
          // Traiter l'erreur, par exemple, en affichant un message à l'utilisateur
        }
      };

      fetchDetailsTrajet();
    }
  }, [trajetId]); // Cet effet s'exécute lorsque trajetId change










  const communeLayers = useRef(new Map());





  const Mode = {
    SELECTION: "SELECTION",
    CHARGEMENT: "CHARGEMENT",
    SELECTION_RUES: "SELECTION_RUES",
    SELECTION_AVEC_RUES: "SELECTION_AVEC_RUES" // Nouveau mode
  };

  const [currentMode, setCurrentMode] = useState(Mode.SELECTION)
  const currentModeRef = useRef(currentMode);
  useEffect(() => {
    currentModeRef.current = currentMode;
  }, [currentMode]);






  useEffect(() => {
    const fetchGeoData = async () => {

      try {
        const response = await axios.post(`${config.domain}/api/get_communes`, {
          departements: departements,

        });

        console.log(response.data)
        
        setGeoJsonData(JSON.parse(response.data));
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchGeoData();
  }, [trajetId]);

  useEffect(() => {
   
    const fetchGeoData = async () => {
      try {
      

        const response = await axios.post(`${config.domain}/api/get_communes`, {
          departements: selectedDepartements,
        });
  
        console.log(response.data);
        setGeoJsonData(JSON.parse(response.data));
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
  
    if (selectedDepartements.length > 0) {
      fetchGeoData();
    }
  }, [selectedDepartements]);

  







  useEffect(() => {
    const fetchEnregistreesCommunes = async () => {
      try {
        const response = await axios.get(`${config.domain}/api/liste_codes_insee`);
        const codesInseeEnregistres = response.data; // devrait être un tableau de codes INSEE

        // Ici, vous devez déterminer la structure de votre objet commune
        // Par exemple, si vous ne stockez que les codes INSEE :
        const communesEnregistrees = codesInseeEnregistres.map(codeInsee => ({
          insee: codeInsee.toString(),
          // ajoutez d'autres propriétés si nécessaire, par exemple:
          name: 'Pommerit-le-Vicomte2', // Vous aurez besoin d'une manière de récupérer le nom de la commune via le code INSEE
          commune_entiere: true // ou false, selon la logique de votre application
        }));
        
        setSelectedCommunes(communesEnregistrees);
      } catch (error) {
        console.error('Erreur lors de la récupération des codes INSEE enregistrés:', error);
        toast.error("Impossible de charger les communes enregistrées");
      }
    };

    fetchEnregistreesCommunes();
  }, []);


  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties['Nom Officiel Commune']) {
      const communeName = feature.properties['Nom Officiel Commune'];
      const communeInsee = feature.properties['Code Officiel Commune'];
      communeLayers.current.set(communeInsee, layer);
  
      layer.on('click', async () => {
        console.log(communeInsee);
  
        // if (currentModeRef.current === Mode.SELECTION) {
        //   const isSelected = selectedCommunes.some(item => item.insee == communeInsee);
  
        //   if (currentModeRefCommune.current === 'select' && !isSelected) {
        //     // Afficher un toast de chargement
        //     // const toastId = toast.loading("Enregistrement de la commune en cours...");
        //     // setLoadingToastId(toastId);

        //     toast.success("lancement");


        //     try {
        //       const response = await axios.post('http://localhost:5000/api/enregistrer_commune', {
        //         inseeCode: communeInsee,
        //       });
  
        //       // // Supprimer le toast de chargement
        //       // if (toast.isActive(toastId)) {
        //       //   toast.dismiss(toastId);
        //       // }
  
        //       if (response.data.success) {
        //         // Ajouter la commune à l'état et changer le style du layer
        //         const newCommune = { name: communeName, insee: communeInsee, commune_entiere: true };
        //         setSelectedCommunes(prevCommunes => [...prevCommunes, newCommune]);
        //         layer.setStyle({ fillColor: 'blue', fillOpacity: 0.7 });
        //         // Afficher un toast de succès
        //         toast.success("Commune enregistrée avec succès");

                
        //       } else {
        //         // Gérer ici les réponses réussies mais indiquant une erreur logique
        //         toast.error("L'enregistrement de la commune a échoué");
        //         // if (toast.isActive(toastId)) {
        //         //   toast.dismiss(toastId);
        //         // }
        //       }
        //     } catch (error) {
        //       console.error('Erreur lors de l’enregistrement de la commune:', error);
        //       // Supprimer le toast de chargement en cas d'erreur et afficher un toast d'erreur
        //       // if (toast.isActive(toastId)) {
        //       //   toast.dismiss(toastId);
        //       // }
        //       toast.error("L'enregistrement de la commune a échoué: " + error.message);
        //     }
        //   } else if (currentModeRefCommune.current === 'deselect' && isSelected) {
        //     // Si on est en mode désélection et que la commune est déjà sélectionnée
        //     setSelectedCommunes(prevCommunes => prevCommunes.filter(item => item.insee !== communeInsee));
        //     layer.setStyle(defaultStyle); // Appliquer le style par défaut lors de la désélection
        //   }
        // }
      });
    }
  };

  

  useEffect(() => {
    console.log("Selected Communes:", selectedCommunes);
  }, [selectedCommunes]);


  useEffect(() => {
    console.log("Selected Rues:", selectedRues);
  }, [selectedRues]);

  useEffect(() => {
    // Applique la couleur bleue aux communes sélectionnées normalement
    selectedCommunes.forEach(commune => {
      const layer = communeLayers.current.get(commune.insee);
      if (layer) {
        if (commune.commune_entiere) {
          // Si commune_entiere est true, coloriser en bleu
          layer.setStyle({ fillColor: 'blue', fillOpacity: 0.7 });
        } else {
          // Si commune_entiere est false, coloriser en rouge
          layer.setStyle({ fillColor: 'red', fillOpacity: 0.7 });
        }
      }
    });


  });








  const defaultStyle = {

    fillColor: 'green',
    weight: 2,
    opacity: 1,
    color: 'white',
    fillOpacity: 0.5,

  };















  useEffect(() => {
    // Ajouter l'écouteur d'événements au montage du composant
    const disableContextMenu = (e) => {
      e.preventDefault(); // Empêcher l'ouverture du menu contextuel du navigateur
    };

    document.addEventListener('contextmenu', disableContextMenu);

    // Retirer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);





  const handleSelectModeCommune = () => {

    setSelectionModeCommune('select');
  };

  const handleDeselectModeCommune = () => {

    setSelectionModeCommune('deselect');
  };















  return (
    <>

{/* <DepartementSelector onDepartementsChange={setSelectedDepartements} /> */}

<DepartementSelector2 onDepartementsChange={setSelectedDepartements} />
      <div style={ButtonContainerStyle}>
        <div style={ColumnStyle}>
          <Button onClick={handleSelectModeCommune} style={selectionModeCommune === 'select' ? activeButtonStyle : inactiveButtonStyle}>
            Mode Sélection
          </Button>
          <Button onClick={handleDeselectModeCommune} style={selectionModeCommune === 'deselect' ? activeButtonStyle : inactiveButtonStyle}>
            Mode Désélection
          </Button>
        </div>

        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Centre le switch et aligne le texte verticalement */}
        
         
        </div>




      </div>








      <MapContainer
        center={[48.5603, -3.1494]}
        zoom={13}
        style={{ ...mapContainerStyle }}
        ref={mapRef}

        scrollWheelZoom={true}


      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="OpenStreetMap">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>

          {/* {geoJsonData && <GeoJSON   key={selectedCommunes.join(",")} data={geoJsonData} onEachFeature={onEachFeature} style={defaultStyle} />} */}
          {geoJsonData && <GeoJSON key={Date.now()} data={geoJsonData} onEachFeature={onEachFeature} style={defaultStyle} />
}
          {geoJsonData && <CommuneLabels geoJsonData={geoJsonData} />}


        </LayersControl>
      </MapContainer>




<ToastContainer />
    </>
  );
};




const ButtonContainerStyle = {
  display: 'flex',
  alignItems: 'center', // Aligner les éléments enfants verticalement au centre
  justifyContent: 'space-between', // Espacement entre les éléments enfants
  width: '75%', // Utiliser toute la largeur disponible
};

const ColumnStyle = {
  display: 'flex',
  flexDirection: 'column', // Organise les éléments enfants en colonne
  gap: '10px', // Espace entre chaque bouton dans la colonne
};

// Style en ligne sans `!important`
const textStyle = {
  marginLeft: '20px', // Augmente l'espacement pour donner plus de respiration
  fontSize: '18px', // Légèrement plus grand pour améliorer la lisibilité
  fontWeight: '500', // Mi-gras pour un contraste amélioré sans être trop imposant
  color: '#333', // Garde la couleur foncée pour une bonne lisibilité
  display: 'flex',
  alignItems: 'center', // Assure que le texte est aligné verticalement avec le switch
};

const registeredBuildingStyle = {
  fillColor: "#ff7800",
  color: "#000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8,
};


const mapContainerStyle = {
  height: '500px',
  width: '80%',
};


export default MyMap;




