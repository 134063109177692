// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './screen/LoginPage';
import UserDashboard from './screen/UserDashboard';
import { PrivateRoute } from './components/PrivateRoute';
import NavBar from './components/NavBar'; // Importez le composant NavBar
import { AuthProvider } from './auth/AuthContext';
import { AdminRoute } from './components/AdminRoute';
import AdminDashboard from './screen/AdminDashboard';
import CreateTripPage from './screen/CreateTripPage'; // Importez le nouveau composant
import Adresse from './screen/adresse';
import CategoryManager from './screen/create_category';
import CalendarComponent from './components/create_calendar/CalendarComponent'; 
import CollectiviteList from './screen/CollectiviteManager';
import CollectorsMap from './screen/collectors'
import GuideDuTri from './screen/GuideDuTri';
import CollectiviteMap from './screen/CollectiviteMap';

function App() {
  return (
    <AuthProvider>
    <Router>
      <NavBar />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        
        <Route path="/UserDashboard" element={<PrivateRoute><UserDashboard /></PrivateRoute>} />
        <Route path="/" element={<PrivateRoute><CollectiviteList /></PrivateRoute>} />
        <Route path="/collector" element={<PrivateRoute><CollectorsMap /></PrivateRoute>} />
        <Route path="/guidedutri" element={<PrivateRoute><GuideDuTri /></PrivateRoute>} />


        <Route path="/create_calendar" element={<PrivateRoute><CalendarComponent isEditing={false}/></PrivateRoute>} />
        <Route path="/edit_tournee/:tourneeId" element={<PrivateRoute><CalendarComponent isEditing={true} /></PrivateRoute>} />


        <Route path="/create-trip" element={<PrivateRoute><CreateTripPage /></PrivateRoute>} />
        <Route path="/adresse" element={<PrivateRoute><Adresse /></PrivateRoute>} />

        <Route path="/create_category" element={<PrivateRoute><CategoryManager /></PrivateRoute>} />
        <Route path="/admin" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
        <Route path="/select_communes/:collectiviteId" element={<PrivateRoute><CollectiviteMap /></PrivateRoute>} />

      
      </Routes>
    </Router>
  </AuthProvider>
  );
}

export default App;
