import React, { useState } from 'react';
import './CalendarNameForm.css';
import config from '../../config';
function CalendarNameForm({ onNameSubmit , setCalendarName , calendarName  }) {
  // const [calendarName, setCalendarName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onNameSubmit(calendarName); // Cette fonction pourrait être utilisée pour mettre à jour le nom dans l'état du composant parent ou pour faire une autre action.
    setCalendarName(''); // Réinitialiser le champ après la soumission.
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="calendarName">Nom du calendrier:</label>
      <input
        type="text"
        id="calendarName"
        value={calendarName}
        onChange={(e) => setCalendarName(e.target.value)}
        placeholder="Entrez le nom du calendrier"
      />
      {/* <button type="submit">Ajouter</button> */}
    </form>
  );
}

export default CalendarNameForm;
