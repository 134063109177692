// src/components/UserDashboard.js

import React from 'react';
import config from '../config';
function AdminDashboard() {
  return (
    <div>
      <h1>Espace Admin</h1>
      {/* Ici, vous pourrez ajouter les fonctionnalités liées au calendrier de collecte */}
    </div>
  );
}

export default AdminDashboard;
