// src/components/CreateTripPage.js
import React, { useState , useEffect } from 'react';
import { useAuth } from '../auth/AuthContext'; // Assurez-vous que le chemin est correct
import TrajetsListe from '../components/trajet/TrajetsListe';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { toast } from 'react-toastify';
import MyMap from '../components/trajet/MyMap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import config from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
function CreateTripPage() {
  const { currentUser } = useAuth(); // Accédez aux informations de l'utilisateur actuel
  const [nomTrajetSelect, setNomTrajetSelect] = useState("");
  const [trip, setTrip] = useState({
    departure: '',
    destination: '',
    date: '',
    time: ''
  });

  const [nomTrajet, setNomTrajet] = useState("");
  const [selectedTrajetId, setSelectedTrajetId] = useState(null);
  const [departemenent, setdepartement] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [trajets, setTrajets] = useState([]);


  const [trajetEnregistre, setTrajetEnregistre] = useState(true);

  const [collectiviteId, setCollectiviteId] = useState('');

  useEffect(() => {
    const fetchCollectiviteID = async () => {
      const collectiviteId = await AsyncStorage.getItem('selectedCollectiviteId');
      setCollectiviteId(collectiviteId);
    };

    fetchCollectiviteID();
  }, []);



  

  const handleEnregistrerTrajet = async () => {
    try {
      const response = await axios.post(`${config.domain}/api/enregistrer_trajet`, { 
        nom: nomTrajet, 
        iduser: currentUser.iduser,
        collectivite_id : collectiviteId

        
      });
      if (response.data && response.data.id) {
        setSelectedTrajetId(response.data.id); // Stocke l'ID du trajet pour une utilisation future
        setTrajetEnregistre(true);
        console.log("Trajet enregistré avec succès, ID:", response.data.id);
        toast.success('Trajet enregistré avec succès');
  
        // Recharger la page après un court délai pour voir les changements
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Attendre 2 secondes avant de recharger pour permettre au toast de s'afficher
  
      } else {
        // Gérer l'absence de retour d'ID ou les erreurs
        console.error("Le trajet n'a pas pu être enregistré correctement.");
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du trajet:', error);
      toast.error('Erreur lors de l\'enregistrement du trajet');
    }
  };
  
  
  const enregistrerCommune = async (communeInfo, communeEntiere, trajetId) => {
    if (!trajetId) {
      console.error("Erreur : Aucun trajet n'est enregistré.");
      toast.error('Erreur : Aucun trajet n\'est enregistré.');
      return;
    }
  
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Enregistrement de la commune en cours...");
  
    try {
      await axios.post(`${config.domain}/api/enregistrer_commune`, {
        trajet_id: trajetId,
        commune_entiere: communeEntiere,
        commune: communeInfo
      });
      console.log("Commune enregistrée avec succès.");
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
      // Affiche un toast de succès
      toast.success('Commune enregistrée avec succès');
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      console.error('Erreur lors de l\'enregistrement de la commune:', error);
      // Affiche un toast d'erreur
      toast.error('Erreur lors de l\'enregistrement de la commune');
    }
  };
  

  const enregistrerRues = async (rues, trajetId) => {
    if (!trajetId) {
      console.error("Erreur : Aucun trajet n'est enregistré.");
      toast.error("Erreur : Aucun trajet n'est enregistré.");
      return;
    }
  
    console.log('Adresse envoyée : ', rues);
  
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Enregistrement des rues en cours...");
  
    try {
      await axios.post(`${config.domain}/api/enregistrer_rues`, {
        trajet_id: trajetId,
        rues: rues
      });
      console.log("Rues enregistrées avec succès.");
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
      // Affiche un toast de succès
      toast.success('Rues enregistrées avec succès.');
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      // Axios enveloppe l'erreur dans `error.response`
      const errorMessage = error.response?.data?.erreur || "Erreur inconnue lors de l'enregistrement des rues.";
      console.error(errorMessage);
      
      // Affiche un toast d'erreur
      toast.error(errorMessage);
    }
  };
  

  const handleTrajetSelection = (trajetId) => {
    setSelectedTrajetId(trajetId);
    setShowMap(true); // Affiche la carte lorsque un trajet est sélectionné
};


const test = () => {
  toast.success('Communes enregistrées avec succès');

}


const handleModifierNomTrajet = async () => {

  
  if (!selectedTrajetId) {
    return;
  }
  
  console.log(`${config.domain}/api/modifier_nom_trajet/${selectedTrajetId}`)
  try {
    const response = await axios.patch(`${config.domain}/api/modifier_nom_trajet/${selectedTrajetId}`, {
      nouveau_nom: nomTrajetSelect,
    });


    setNomTrajet('');  // Clear the input after successful update

    try {
      console.log(`${config.domain}/api/obtenir_trajets?iduser=${currentUser.iduser}`)
      const response = await axios.get(`${config.domain}/api/obtenir_trajets?iduser=${currentUser.iduser}`);
      setTrajets(response.data);
      
  } catch (error) {
      console.error('Erreur lors de la récupération des tournée:', error);

  }

  } catch (error) {
    console.error('Erreur lors de la mise à jour du nom de la tournée:', error);
  }
};

  // Le reste du composant reste inchangé...

  return (
    <div style={{ margin: 'auto', padding: '20px' }}>
      {/* <h2>Créer un nouveau trajet</h2> */}
      {/* Affichez l'ID de l'utilisateur si disponible */}
      {/* {currentUser && <p>ID de l'utilisateur : {currentUser.iduser}</p>} */}
      
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 100 }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <input
            type="text"
            value={nomTrajet}
            onChange={(e) => setNomTrajet(e.target.value)}
            placeholder="Nom de la tournée"
            style={{
              padding: '10px',
              marginRight: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '300px',
              fontSize: '16px'
            }}
          />
          <button 
            onClick={handleEnregistrerTrajet}
            style={{
              padding: '10px 20px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#4CAF50',
              color: 'white',
              fontSize: '16px',
              cursor: 'pointer',
              transition: 'background-color 0.3s'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'}
          >
            Enregistrer la tournée
          </button>
        </div>

            {/* <button onClick={() => test()}>test</button> */}
        

        <TrajetsListe onTrajetSelectionne={handleTrajetSelection} setdepartement={setdepartement} setNomTrajetSelect={setNomTrajetSelect} trajets={trajets} setTrajets={setTrajets}/>


        { showMap  && 
        <>
        <div style={{ marginTop: '20px' }}>
          <input
            type="text"
            value={nomTrajetSelect}
            onChange={(e) => setNomTrajetSelect(e.target.value)}
            placeholder="Modifier le nom de la tournée"
            style={{
              padding: '10px',
              marginRight: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '300px',
              fontSize: '16px'
            }}
          />
          <button
            onClick={handleModifierNomTrajet}
            style={{
              padding: '10px 20px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#4CAF50',
              color: 'white',
              fontSize: '16px',
              cursor: 'pointer',
              transition: 'background-color 0.3s'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'}
          >
            Modifier le nom
          </button>

          </div>
        <MyMap onEnregistrerCommune={enregistrerCommune}  onEnregistrerRue={enregistrerRues} trajetId={selectedTrajetId} departements={departemenent}  />
        </>
        }
            <div style={{marginTop : 50}}></div>
        
   
            <ToastContainer />
</div>
    </div>
  );
}

export default CreateTripPage;

