import React, { useState , useEffect } from 'react';
import './MessageLinkForm.css'; // Assurez-vous que le chemin d'accès est correct pour votre structure de projet
import config from '../../config';
const MessageLinkForm = ({ selectedDates, categoriesActives, selectedTournees , messages , setMessages }) => {
  const [selectedTournee, setSelectedTournee] = useState('toutes');
  const [selectedCategory, setSelectedCategory] = useState('principal');
  const [availableCategories, setAvailableCategories] = useState([]);

  const handleTourneeChange = (event) => {
    setSelectedTournee(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleMessageChange = (event) => {
    // Construire la clé en utilisant des préfixes 'tournee-' et 'categorie-' pour une identification claire
    const key = `tournee-${selectedTournee}-categorie-${selectedCategory}`;
    const newMessages = { ...messages, [key]: event.target.value };
    setMessages(newMessages);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Utilisez la même construction de clé lors de la soumission
    const key = `tournee-${selectedTournee}-categorie-${selectedCategory}`;
    console.log(`Message for ${key}:`, messages[key]);
  };

  const transformTextToClickableLinks = (text) => {
    const regex = /(\[([^\]]+)]\s*\(\s*(http[s]?:\/\/[^\s)]+?)\s*\))|(http[s]?:\/\/[^\s]+)/g;
    const parts = [];
    let lastIndex = 0;

    text.replace(regex, (match, p1, linkText, url, plainUrl, index) => {
      if (index > lastIndex) {
        parts.push(text.substring(lastIndex, index));
      }

      if (url) {
        parts.push(<a href={url} target="_blank" rel="noopener noreferrer" key={index}>{linkText}</a>);
      } else if (plainUrl) {
        parts.push(<a href={plainUrl} target="_blank" rel="noopener noreferrer" key={index}>{plainUrl}</a>);
      }

      lastIndex = index + match.length;
    });

    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts.length > 0 ? parts : text;
  };

  useEffect(() => {

    console.log('modif catégories active : ' , categoriesActives)
    // Créez un ensemble unique d'IDs de catégories à partir des dates sélectionnées
    const categoryIdsWithDates = new Set();
    selectedDates.forEach(dateInfo => {
      dateInfo.categories.forEach((categoryName, categoryId) => {
        console.log("modif category :  " , categoryId)
        categoryIdsWithDates.add(categoryId[0]);
      });
    });
  
    // Filtrez les catégories actives basées sur l'ensemble des IDs de catégories
    const categoriesWithDates = categoriesActives.filter(category =>
      categoryIdsWithDates.has(category.id.toString())
    );
  
    setAvailableCategories(categoriesWithDates);
  }, [categoriesActives, selectedDates]);

  // useEffect(() => {
  //   console.log('message : ' , messages)
  // }, [messages]);
  
// Assurez-vous que le message courant est correctement défini


  const currentMessageKey = `tournee-${selectedTournee}-categorie-${selectedCategory}`;
  const currentMessage = messages[currentMessageKey] || '';

  return (
    <div className="message-link-form-container">
      <form onSubmit={handleSubmit} className="message-link-form">
        <select value={selectedTournee} onChange={handleTourneeChange} className="select-tournee">
          <option value="toutes">Toutes les tournées</option>
          {selectedTournees.map((tournee) => (
            <option key={tournee.id} value={tournee.id}>{tournee.nom}</option>
          ))}
        </select>

        {/* <select value={selectedCategory} onChange={handleCategoryChange} className="select-category">
          <option value="principal">Principal</option>
          {categoriesActives.map((category) => (
            <option key={category.id} value={category.id}>{category.nom}</option>
          ))}
        </select> */}
 <select value={selectedCategory} onChange={handleCategoryChange} className="select-category">
          <option value="principal">Pas de catégories</option>
          {availableCategories.map((category) => (
            <option key={category.id} value={category.id}>{category.nom}</option>
          ))}
        </select>

        <textarea
          value={currentMessage}
          onChange={handleMessageChange}
          placeholder="Écrivez votre message ici"
          className="message-textarea"
        />

        <div className="extracted-links-display">
          {transformTextToClickableLinks(currentMessage)}
        </div>

        {/* <button type="submit" className="submit-button">Enregistrer le message</button> */}
      </form>
    </div>
  );
};

export default MessageLinkForm;
