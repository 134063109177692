// src/components/AdminRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

export function AdminRoute({ children }) {
  const { currentUser } = useAuth();


  return currentUser && currentUser.role == 'admin' ? children : <Navigate to="/login" />;
}
