

// import React, { useState, useEffect, useRef } from 'react';
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   Popup,
//   useMap,
//   useMapEvent
// } from 'react-leaflet';
// import L from 'leaflet';
// import Modal from 'react-modal';
// import config from '../config';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import './CollectorsMap.css';
// import { debounce } from 'lodash';
// import DefaultInfoForm from './DefaultInfoForm';

// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });

// Modal.setAppElement('#root');

// const CollectorsMap = () => {
//   const [collectors, setCollectors] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategories, setSelectedCategories] = useState(new Set());
//   const [newCategoryName, setNewCategoryName] = useState('');
//   const [newCategoryIcon, setNewCategoryIcon] = useState(null);
//   const [collectiviteId, setCollectiviteId] = useState('');
//   const [currentCategory, setCurrentCategory] = useState(null);
//   const [editCategoryModalVisible, setEditCategoryModalVisible] = useState(false);
//   const [newMarker, setNewMarker] = useState(null);
//   const [newCollectorData, setNewCollectorData] = useState({ name: '', description: '', category: '', location: null });
//   const [multiplePointsModalVisible, setMultiplePointsModalVisible] = useState(false);
//   const [mapModalVisible, setMapModalVisible] = useState(false);
//   const [pointsListModalVisible, setPointsListModalVisible] = useState(false);
//   const [multiPoints, setMultiPoints] = useState([]);
//   const [multiPointsDetails, setMultiPointsDetails] = useState([]);
//   const [searchAddress, setSearchAddress] = useState('');
//   const [addressSuggestions, setAddressSuggestions] = useState([]);
//   const [editModalVisible, setEditModalVisible] = useState(false);
//   const [currentCollector, setCurrentCollector] = useState(null);
//   const [activeTab, setActiveTab] = useState('categories');
//   const [searchAddress2, setSearchAddress2] = useState('');
//   const [addressSuggestions2, setAddressSuggestions2] = useState([]);
//   const [initialPosition, setInitialPosition] = useState([48.6167, -3.0833]);
//   const [mapCenter, setMapCenter] = useState(initialPosition);
//   const [mapMoveCenter, setMoveCenter] = useState([48.6167, -3.0833]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 100;
//   const [totalPages, setTotalPages] = useState(1);
//   const [highlightedCollectorId, setHighlightedCollectorId] = useState(null);
//   const [searchAddressInTable, setSearchAddressInTable] = useState('');
//   const [filteredCollectors, setFilteredCollectors] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [selectedPoint, setSelectedPoint] = useState(null);
//   const [mapPrincipal, setMapPrincipal] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectedSubCategories, setSelectedSubCategories] = useState([]);
//   const [categorySearchQuery, setCategorySearchQuery] = useState('');
//   const [subCategorySearchQuery, setSubCategorySearchQuery] = useState('');

//   const markersRef = useRef({});

//   const fetchCollectorsDebounced = debounce(async (latitude, longitude, page, search) => {
//     const categoryIds = [...selectedCategories].join(',');
//     const response = await fetch(
//       `${config.domain}/api/collectors_categories?latitude=${latitude}&longitude=${longitude}&category_ids=${categoryIds}&page=${page}&per_page=${itemsPerPage}&search=${encodeURIComponent(search)}`
//     );
//     const data = await response.json();
//     setCollectors(data.collectors || []);
//     const { total_count, per_page } = data.pagination;
//     setTotalPages(Math.ceil(total_count / per_page));
//   }, 200);

//   useEffect(() => {
//     setFilteredCollectors(collectors);
//   }, [collectors]);

//   const mapRef = useRef(null);

//   const MoveMap = () => {
//     const map = useMap();
//     setMapPrincipal(map);
//     return null;
//   };

//   const DisplayMapCenter = () => {
//     const map = useMap();
//     useEffect(() => {
//       const logCenter = () => {
//         const center = map.getCenter();
//         setMoveCenter([center.lat, center.lng]);
//       };
//       map.on('moveend', logCenter);
//       return () => {
//         map.off('moveend', logCenter);
//       };
//     }, [map]);
//     return null;
//   };

//   const fetchCollectiviteID = async () => {
//     const id = await AsyncStorage.getItem('selectedCollectiviteId');
//     setCollectiviteId(id || '');
//   };

//   useEffect(() => {
//     fetchCollectiviteID();
//   }, []);

//   const fetchCategories = async (latitude, longitude) => {
//     const generalResponse = await fetch(`${config.domain}/api/categories?latitude=${latitude}&longitude=${longitude}`);
//     const generalCategories = await generalResponse.json();
//     const collectiviteResponse = await fetch(`${config.domain}/api/categories_by_collectivite?collectivite_id=${collectiviteId}`);
//     const collectiviteCategories = await collectiviteResponse.json();
//     const allCategories = [
//       ...(Array.isArray(generalCategories) ? generalCategories : []),
//       ...(Array.isArray(collectiviteCategories) ? collectiviteCategories : []),
//     ];
//     const categoriesMap = new Map();
//     allCategories.forEach(cat => {
//       categoriesMap.set(cat.id, cat);
//     });
//     const uniqueCategories = Array.from(categoriesMap.values());
//     setCategories(uniqueCategories);
//   };

//   const fetchCollectors = async (latitude, longitude, page = 1, search = '') => {
//     const categoryIds = [...selectedCategories].join(',');
//     const response = await fetch(
//       `${config.domain}/api/collectors_categories?latitude=${latitude}&longitude=${longitude}&category_ids=${categoryIds}&page=${page}&per_page=${itemsPerPage}&search=${encodeURIComponent(search)}`
//     );
//     const data = await response.json();
//     setCollectors(data.collectors || []);
//     const { total_count, per_page } = data.pagination;
//     setTotalPages(Math.ceil(total_count / per_page));
//   };

//   const handleSearchAddressInTable = (query) => {
//     setSearchQuery(query);
//     fetchCollectors(mapCenter[0], mapCenter[1], currentPage, query);
//   };

//   useEffect(() => {
//     fetchCategories(mapMoveCenter[0], mapMoveCenter[1]);
//     fetchCollectors(mapMoveCenter[0], mapMoveCenter[1], currentPage);
//   }, [selectedCategories, collectiviteId]);

//   useEffect(() => {
//     fetchCollectors(mapCenter[0], mapCenter[1], currentPage, searchQuery);
//   }, [mapCenter, collectiviteId]);

//   const handleCategoryChange = (categoryId) => {
//     setSelectedCategories((prev) => {
//       const newSet = new Set(prev);
//       if (newSet.has(categoryId)) {
//         newSet.delete(categoryId);
//       } else {
//         newSet.add(categoryId);
//       }
//       return newSet;
//     });
//     setCurrentPage(1);
//   };

//   const handleSubCategoryClick = (categoryId) => {
//     if (selectedSubCategories.includes(categoryId)) {
//       setSelectedSubCategories(selectedSubCategories.filter(id => id !== categoryId));
//     } else {
//       setSelectedSubCategories([...selectedSubCategories, categoryId]);
//     }
//   };

//   const handleNewCategoryIconChange = (e) => {
//     setNewCategoryIcon(e.target.files[0]);
//   };

//   const addCategory = async () => {
//     const url = `${config.domain}/api/add_category`;
//     const data = {
//       name: newCategoryName,
//       collectivite_id: collectiviteId,
//       parent_ids: selectedSubCategories
//     };

//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(data)
//     });

//     if (response.ok) {
//       const newCategory = await response.json();
//       setCategories((prev) => [...prev, newCategory]);
//       setNewCategoryName('');
//       setSelectedSubCategories([]);
//     } else {
//       const errorResponse = await response.text();
//       console.error('Failed to add category', response.status, errorResponse);
//     }
//   };

//   const updateCategory = async (category) => {
//     const formData = new FormData();
//     formData.append('name', category.name);
//     formData.append('collectivite_id', collectiviteId);
//     if (category.icon) {
//       formData.append('icon', category.icon);
//     }
//     if (category.image) {
//       formData.append('image', category.image);
//     }
//     if (category.subcategories && category.subcategories.length > 0) {
//       category.subcategories.forEach(subCatId => {
//         formData.append('subcategories[]', subCatId);
//       });
//     }

//     const url = `${config.domain}/api/update_category/${category.id}`;
//     const response = await fetch(url, {
//       method: 'PUT',
//       body: formData,
//     });

//     if (response.ok) {
//       const updatedCategory = await response.json();
//       setCategories((prev) => prev.map((cat) => (cat.id === updatedCategory.id ? updatedCategory : cat)));
//       setEditCategoryModalVisible(false);
//     } else {
//       console.error('Failed to update category');
//     }
//   };

//   const deleteCategory = async (categoryId) => {
//     const url = `${config.domain}/api/delete_category_carto_hoali/${categoryId}`;
//     const response = await fetch(url, {
//       method: 'DELETE',
//       headers: { 'Content-Type': 'application/json' },
//     });

//     if (response.ok) {
//       setCategories((prev) => prev.filter((cat) => cat.id !== categoryId));
//       alert('Catégorie supprimée avec succès');
//     } else {
//       console.error('Failed to delete category');
//       alert('Erreur lors de la suppression de la catégorie');
//     }
//   };

//   const handleEditCategory = (category) => {
//     setCurrentCategory({
//       ...category,
//       icon: null,
//       subcategories: category.subcategories ? category.subcategories.map(sub => sub.id) : []
//     });
//     setEditCategoryModalVisible(true);
//   };

//   const handleUpdateCategory = () => {
//     if (currentCategory) {
//       updateCategory(currentCategory);
//     }
//   };

//   const handleCategoryIconChange2 = (e) => {
//     setCurrentCategory((prev) => ({ ...prev, image: e.target.files[0] }));
//   };

//   const handleCategoryIconChange = (e) => {
//     setCurrentCategory((prev) => ({ ...prev, icon: e.target.files[0] }));
//   };

//   const addMultipleCollectors = async () => {
//     if (!multiPointsDetails.length) {
//       alert('Veuillez ajouter au moins un point.');
//       return;
//     }

//     const collectorsToAdd = multiPointsDetails.map((detail) => {
//       const addressParts = detail.address ? detail.address.split(' ') : [];
//       return {
//         categories: detail.categories || [],
//         name: detail.name || '',
//         description: detail.description || '',
//         location: detail.location || { lat: 0, lng: 0 },
//         address: detail.address || '',
//         collectivite_id: parseInt(collectiviteId, 10),
//         adr_num: addressParts[0] || '',
//         adr_street: addressParts.slice(1, -3).join(' ') || '',
//         adr_city: addressParts.slice(-3, -2)[0] || '',
//         adr_zip_code: parseInt(addressParts.slice(-2, -1)[0], 10) || 0,
//         adr_country: addressParts.slice(-1)[0] || '',
//       };
//     });

//     const url = `${config.domain}/api/add_multiple_collectors`;
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(collectorsToAdd),
//     });

//     if (response.ok) {
//       const result = await response.json();
//       setCollectors((prev) => [...prev, ...result.collectors]);
//       setMultiplePointsModalVisible(false);
//       setPointsListModalVisible(false);
//       setMultiPoints([]);
//       setMultiPointsDetails([]);
//     } else {
//       console.error('Failed to add multiple collectors');
//     }
//   };

//   const DraggableMarker = () => {
//     const [position, setPosition] = useState(newMarker);
//     const markerRef = useRef(null);

//     const eventHandlers = {
//       dragend() {
//         const marker = markerRef.current;
//         if (marker != null) {
//           setPosition(marker.getLatLng());
//           setNewCollectorData((prev) => ({ ...prev, location: marker.getLatLng() }));
//         }
//       },
//     };

//     return position === null ? null : (
//       <Marker draggable={true} eventHandlers={eventHandlers} position={position} ref={markerRef}>
//         <Popup>Glissez ce marqueur ou cliquez sur la carte pour ajouter un nouveau point.</Popup>
//       </Marker>
//     );
//   };

//   const MultipleDraggableMarkers = () => {
//     const [positions, setPositions] = useState(multiPoints);
//     const markerRefs = useRef(Array.from({ length: positions.length }, () => React.createRef()));

//     const addMarker = (e) => {
//       const newMarker = e.latlng;
//       setPositions((prev) => [...prev, newMarker]);
//       markerRefs.current = [...markerRefs.current, React.createRef()];
//     };

//     const eventHandlers = (idx) => ({
//       dragend() {
//         const marker = markerRefs.current[idx].current;
//         if (marker != null) {
//           const newPos = marker.getLatLng();
//           setPositions((prev) => prev.map((pos, i) => (i === idx ? newPos : pos)));
//         }
//       },
//     });

//     useMapEvent('click', addMarker);

//     useEffect(() => {
//       setMultiPoints(positions);
//     }, [positions]);

//     return (
//       <>
//         {positions.map((pos, idx) => (
//           <Marker
//             key={idx}
//             draggable
//             eventHandlers={eventHandlers(idx)}
//             position={pos}
//             ref={markerRefs.current[idx]}>
//             <Popup>Glissez ce marqueur ou cliquez sur la carte pour ajouter un point.</Popup>
//           </Marker>
//         ))}
//       </>
//     );
//   };

//   const handleMultiplePoints = () => {
//     setMultiplePointsModalVisible(true);
//   };

//   const handleAddManualPoint = async () => {
//     const address = await getReverseGeocodedAddress(0, 0);
//     setMultiPoints((prev) => [...prev, { lat: 0, lng: 0 }]);
//     setMultiPointsDetails((prev) => [
//       ...prev,
//       { name: '', description: '', categories: [], location: { lat: 0, lng: 0 }, address }
//     ]);
//   };

//   const handleAddGpsCoords = async (idx, key, value) => {
//     const newPos = { ...multiPoints[idx], [key]: parseFloat(value) };
//     const address = await getReverseGeocodedAddress(newPos.lat, newPos.lng);
//     setMultiPoints((prev) => prev.map((pos, i) => (i === idx ? newPos : pos)));
//     setMultiPointsDetails((prev) => prev.map((detail, i) => (i === idx ? { ...detail, location: newPos, address } : detail)));
//   };

//   const handleSearchAddress = async (address) => {
//     setSearchAddress(address);
//     if (address.length < 3) return;

//     const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(address)}&limit=5`);
//     const data = await response.json();
//     setAddressSuggestions(data.features || []);
//   };

//   const handleSelectAddress = async (feature) => {
//     const [lng, lat] = feature.geometry.coordinates;
//     const address = feature.properties.label;
//     const newPoint = { lat, lng };
//     setMultiPoints((prev) => [...prev, newPoint]);
//     setMultiPointsDetails((prev) => [
//       ...prev,
//       { name: '', description: '', categories: [], location: newPoint, address }
//     ]);
//     setSelectedPoint(newPoint);
//     setSearchAddress('');
//     setAddressSuggestions([]);
//   };

//   const handleRemovePoint = (idx) => {
//     setMultiPoints((prev) => prev.filter((_, i) => i !== idx));
//     setMultiPointsDetails((prev) => prev.filter((_, i) => i !== idx));
//   };

//   const handleOpenMapModal = () => {
//     setMapModalVisible(true);
//     setMultiplePointsModalVisible(false);
//   };

//   const handleCloseMapModal = () => {
//     setMapModalVisible(false);
//     handleFinalizePoints();
//   };

//   const handleOpenPointsListModal = () => {
//     setPointsListModalVisible(true);
//     setMultiplePointsModalVisible(false);
//   };

//   const handleClosePointsListModal = () => {
//     setPointsListModalVisible(false);
//     setMultiplePointsModalVisible(true);
//   };

//   const handleAddPointDetail = (idx, key, value) => {
//     setMultiPointsDetails((prev) => prev.map((detail, i) => (i === idx ? { ...detail, [key]: value } : detail)));
//   };

//   const handleCategoryChangeForPoint = (idx, categoryId) => {
//     setMultiPointsDetails((prev) => {
//       const updatedDetails = prev.map((detail, i) => {
//         if (i === idx) {
//           const categories = detail.categories || [];
//           const newCategories = categories.includes(categoryId)
//             ? categories.filter((id) => id !== categoryId)
//             : [...categories, categoryId];
//           return { ...detail, categories: newCategories };
//         }
//         return detail;
//       });
//       return updatedDetails;
//     });
//   };

//   const getReverseGeocodedAddress = async (lat, lng) => {
//     const response = await fetch(`https://api-adresse.data.gouv.fr/reverse/?lat=${lat}&lon=${lng}`);
//     const data = await response.json();
//     return data.features.length > 0 ? data.features[0].properties.label : 'Adresse inconnue';
//   };

//   const handleFinalizePoints = async () => {
//     const details = await Promise.all(
//       multiPoints.map(async (pos) => ({
//         name: '',
//         description: '',
//         categories: [],
//         location: pos,
//         address: await getReverseGeocodedAddress(pos.lat, pos.lng),
//       }))
//     );
//     setMultiPointsDetails(details);
//     setPointsListModalVisible(true);
//   };

//   const deleteCollector = async (collectorId) => {
//     const url = `${config.domain}/api/delete_collector/${collectorId}`;
//     const response = await fetch(url, {
//       method: 'DELETE',
//       headers: { 'Content-Type': 'application/json' },
//     });

//     if (response.ok) {
//       setCollectors((prev) => prev.filter((col) => col.id !== collectorId));
//       alert('Point supprimé avec succès');
//     } else {
//       console.error('Failed to delete collector');
//       alert('Erreur lors de la suppression du point');
//     }
//   };

//   const updateCollector = async (collector) => {
//     const url = `${config.domain}/api/update_collector/${collector.id}`;
//     const response = await fetch(url, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(collector),
//     });

//     if (response.ok) {
//       const message = await response.json();
//       alert(message.message);
//     } else {
//       console.error('Failed to update collector');
//       alert('Erreur lors de la mise à jour du point');
//     }
//   };

//   const handleEditCollector = async (collector) => {
//     const collectorDetails = await fetchCollectorDetails(collector.id);
//     if (collectorDetails) {
//       setCurrentCollector({
//         ...collectorDetails,
//         categories: collectorDetails.categories.map(c => c.category_id)
//       });
//       setEditModalVisible(true);
//     }
//   };

//   const handleRemoveCollector = async (collectorId) => {
//     await deleteCollector(collectorId);
//   };

//   const handleUpdateCollector = () => {
//     if (currentCollector) {
//       const updatedCollector = {
//         ...currentCollector,
//         categories: currentCollector.categories || []
//       };

//       updateCollector(updatedCollector);
//       setEditModalVisible(false);
//     }
//   };

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   const handleSearchAddress_principal = async (address) => {
//     setSearchAddress2(address);
//     if (address.length < 3) return;

//     const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(address)}&limit=5`);
//     const data = await response.json();
//     setAddressSuggestions2(data.features || []);
//   };

//   const handleSelectAddress_principal = async (feature) => {
//     const [lng, lat] = feature.geometry.coordinates;
//     setMapCenter([lat, lng]);
//     mapPrincipal.setView([lat, lng]);
//     fetchCategories(lat, lng);
//     fetchCollectors(lat, lng, currentPage);
//     setAddressSuggestions2([]);
//   };

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//     fetchCollectorsDebounced(mapMoveCenter[0], mapMoveCenter[1], pageNumber, searchQuery);
//   };

//   const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//     const pages = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pages.push(
//         <button
//           key={i}
//           className={`pagination-button ${i === currentPage ? 'active' : ''}`}
//           onClick={() => onPageChange(i)}
//         >
//           {i}
//         </button>
//       );
//     }
//     return <div className='pagination'>{pages}</div>;
//   };

//   const selectCollector = (collectorId) => {
//     setHighlightedCollectorId(collectorId);
//     if (markersRef.current[collectorId]) {
//       markersRef.current[collectorId].openPopup();
//     }
//   };

//   const MarkerWithHighlight = ({ collector }) => {
//     const markerRef = useRef(null);
//     useEffect(() => {
//       markersRef.current[collector.id] = markerRef.current;
//     }, [collector.id]);

//     return (
//       <Marker
//         ref={markerRef}
//         position={[collector.location_x, collector.location_y]}
//         eventHandlers={{
//           click: () => {
//             selectCollector(collector.id);
//           }
//         }}
//       >
//         <Popup>
//           <strong>{collector.category_name}</strong>
//           <p>{collector.description}</p>
//           <button onClick={() => handleEditCollector(collector)}>Modifier</button>
//           <button onClick={() => handleRemoveCollector(collector.id)}>Supprimer</button>
//         </Popup>
//       </Marker>
//     );
//   };

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setInitialPosition([latitude, longitude]);
//         setMapCenter([latitude, longitude]);
//       },
//       (error) => {
//         console.error('Error getting user location:', error);
//       },
//       { enableHighAccuracy: true }
//     );
//   }, []);

//   const reloadData = async () => {
//     setIsLoading(true);
//     setSelectedCategories(new Set());
//     fetchCategories(mapMoveCenter[0], mapMoveCenter[1]);
//     fetchCollectors(mapMoveCenter[0], mapMoveCenter[1], 1);
//     setIsLoading(false);
//   };

//   const handleSubCategoryToggle = (categoryId) => {
//     const currentIndex = currentCategory.subcategories.indexOf(categoryId);
//     const newSubcategories = [...currentCategory.subcategories];

//     if (currentIndex === -1) {
//       newSubcategories.push(categoryId);
//     } else {
//       newSubcategories.splice(currentIndex, 1);
//     }

//     setCurrentCategory({ ...currentCategory, subcategories: newSubcategories });
//   };

//   const filteredCategories = categories.filter(category =>
//     category.name.toLowerCase().includes(categorySearchQuery.toLowerCase())
//   );

//   const filteredSubCategories = categories.filter(category =>
//     category.name.toLowerCase().includes(subCategorySearchQuery.toLowerCase())
//   );

//   const handleCategoryChangeForCurrentCollector = (categoryId) => {
//     setCurrentCollector((prev) => {
//       const categories = prev.categories || [];
//       const newCategories = categories.includes(categoryId)
//         ? categories.filter((id) => id !== categoryId)
//         : [...categories, categoryId];
//       return { ...prev, categories: newCategories };
//     });
//   };

//   const fetchCollectorDetails = async (collectorId) => {
//     const url = `${config.domain}/api/collector/${collectorId}`;
//     const response = await fetch(url);
//     if (response.ok) {
//       const collectorDetails = await response.json();
//       return collectorDetails;
//     } else {
//       console.error('Failed to fetch collector details');
//       return null;
//     }
//   };

//   return (
//     <div>
//       <div className='address-search-bar'>
//         <input
//           type='text'
//           value={searchAddress2}
//           onChange={(e) => handleSearchAddress_principal(e.target.value)}
//           placeholder='Rechercher une commune ou une adresse'
//         />
//         <ul className='address-suggestions'>
//           {addressSuggestions2.map((feature, idx) => (
//             <li key={idx} onClick={() => handleSelectAddress_principal(feature)}>
//               {feature.properties.label}
//             </li>
//           ))}
//         </ul>
//       </div>
//       <MapContainer
//         center={mapCenter}
//         zoom={13}
//         style={{ height: '600px', width: '100%' }}
//         ref={mapRef}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <MoveMap />
//         <DisplayMapCenter />
//         {collectors.map(collector => (
//           <MarkerWithHighlight
//             key={collector.id}
//             collector={collector}
//           />
//         ))}
//         <DraggableMarker />

//         <div className="map-button-container">
//           <button
//             onClick={reloadData}
//             className="reload-data-button"
//             aria-label="Recharger les données de la carte"
//             disabled={isLoading}
//           >
//             {isLoading ? 'Chargement...' : 'Charger les données'}
//           </button>
//         </div>
//       </MapContainer>

//       <div className='add-points-buttons'>
//         <button onClick={handleMultiplePoints} className='add-multiple-points-button'>
//           Ajouter plusieurs points
//         </button>
//       </div>

//       <div className='tabs-container'>
//         <div className='tabs'>
//           <button
//             className={activeTab === 'categories' ? 'tab active' : 'tab'}
//             onClick={() => handleTabChange('categories')}
//           >
//             Catégories
//           </button>
//           <button
//             className={activeTab === 'collectors' ? 'tab active' : 'tab'}
//             onClick={() => handleTabChange('collectors')}
//           >
//             Points de Collecte
//           </button>
//         </div>
//         <div className='tab-content'>
//           {activeTab === 'categories' ? (
//             <div className='categories-column'>
//               <div className='category-search'>
//                 <input
//                   type='text'
//                   value={categorySearchQuery}
//                   onChange={(e) => setCategorySearchQuery(e.target.value)}
//                   placeholder='Rechercher une catégorie'
//                 />
//               </div>
//               <div className='categories-container'>
//                 {filteredCategories.map((category) => (
//                   <div key={category.id} className={`category-item ${selectedCategories.has(category.id) ? 'selected' : ''}`}>
//                     <button className='category-button' onClick={() => handleCategoryChange(category.id)}>
//                       {category.icon ? (
//                         <img
//                           src={`${config.domain}/uploads/icons/${category.icon}`}
//                           alt={category.name}
//                           className='category-icon'
//                         />
//                       ) : (
//                         <span style={{ color: 'black' }}>{category.name}</span>
//                       )}
//                     </button>
//                     {category.subcategories.length > 0 && (
//                       <div className='subcategories'>
//                         {category.subcategories.map((sub) => (
//                           <div key={sub.id} className='subcategory'>
//                             {sub.name}
//                           </div>
//                         ))}
//                       </div>
//                     )}
//                     <div className='category-actions'>
//                       <button onClick={() => handleEditCategory(category)}>Modifier</button>
//                       <button onClick={() => deleteCategory(category.id)}>Supprimer</button>
//                     </div>
//                   </div>
//                 ))}
//               </div>

//               <div className='add-category-form'>
//                 <h3>Informations de la Catégorie</h3>
//                 <label htmlFor="categoryName">
//                   Nom de la catégorie:
//                   <input
//                     id="categoryName"
//                     type='text'
//                     value={newCategoryName}
//                     onChange={(e) => setNewCategoryName(e.target.value)}
//                     placeholder='Entrez le nom de la catégorie'
//                   />
//                 </label>

//                 <h3>Image de la Catégorie</h3>
//                 <p>Ajoutez une image représentative pour la catégorie :</p>
//                 <input
//                   type='file'
//                   accept='.png,.jpg,.jpeg,.svg'
//                   onChange={handleNewCategoryIconChange}
//                 />

//                 <button onClick={addCategory} className='add-category-button'>
//                   + Ajouter une catégorie
//                 </button>
//               </div>
//             </div>
//           ) : (
//             <div>
//               <div className='table-search'>
//                 <input
//                   type='text'
//                   value={searchQuery}
//                   onChange={(e) => handleSearchAddressInTable(e.target.value)}
//                   placeholder='Rechercher par nom ou adresse'
//                 />
//               </div>

//               <table className='collectors-table'>
//                 <thead>
//                   <tr>
//                     <th>Nom</th>
//                     <th>Catégories</th>
//                     <th>Description</th>
//                     <th>Adresse</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {collectors.map((collector) => (
//                     <tr
//                       key={collector.id}
//                       className={highlightedCollectorId === collector.id ? 'highlighted' : ''}
//                       onClick={() => selectCollector(collector.id)}
//                     >
//                       <td>{collector.name || '-'}</td>
//                       <td>
//                         {collector.categories && collector.categories.map(category => (
//                           <div key={category.category_id}>{category.category_name}</div>
//                         ))}
//                       </td>
//                       <td>{collector.description || '-'}</td>
//                       <td>{collector.address}</td>
//                       <td>
//                         <button onClick={(e) => { e.stopPropagation(); handleEditCollector(collector); }}>Modifier</button>
//                         <button onClick={(e) => { e.stopPropagation(); handleRemoveCollector(collector.id); }}>Supprimer</button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               <Pagination
//                 currentPage={currentPage}
//                 totalPages={totalPages}
//                 onPageChange={handlePageChange}
//               />
//             </div>
//           )}
//         </div>
//       </div>

//       <Modal
//         isOpen={multiplePointsModalVisible}
//         onRequestClose={() => setMultiplePointsModalVisible(false)}
//         contentLabel='Ajouter plusieurs points de collecte'
//         className='Modal'
//         overlayClassName='Overlay'
//       >
//         <h2>Ajouter plusieurs points de collecte</h2>
//         <div className='modal-actions'>
//           <button onClick={handleOpenMapModal}>Placer les points sur la carte</button>
//           <button onClick={handleAddManualPoint}>Ajouter manuellement</button>
//         </div>
//         <div className='multi-points-list'>
//           {multiPointsDetails.map((point, idx) => (
//             <div key={idx} className='modal-section'>
//               <h3>Point #{idx + 1}</h3>
//               <label>
//                 Adresse:
//                 <input type='text' value={point.address} readOnly />
//               </label>
//               <label>
//                 Latitude:
//                 <input
//                   type='number'
//                   value={point.location.lat}
//                   onChange={(e) => handleAddGpsCoords(idx, 'lat', e.target.value)}
//                 />
//               </label>
//               <label>
//                 Longitude:
//                 <input
//                   type='number'
//                   value={point.location.lng}
//                   onChange={(e) => handleAddGpsCoords(idx, 'lng', e.target.value)}
//                 />
//               </label>
//               <button className='remove-point-button' onClick={() => handleRemovePoint(idx)}>
//                 Supprimer
//               </button>
//             </div>
//           ))}
//         </div>
//         <div className='modal-footer'>
//           <button onClick={handleOpenPointsListModal}>Suivant</button>
//           <button onClick={() => setMultiplePointsModalVisible(false)}>Annuler</button>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={mapModalVisible}
//         onRequestClose={handleCloseMapModal}
//         contentLabel='Sélectionner un emplacement sur la carte'
//         className='MapModal'
//         overlayClassName='Overlay'
//       >
//         <h2>Sélectionner un emplacement sur la carte</h2>
//         <div className='address-search'>
//           <input
//             type='text'
//             value={searchAddress}
//             onChange={(e) => handleSearchAddress(e.target.value)}
//             placeholder='Rechercher une adresse'
//           />
//           <button onClick={() => handleSearchAddress(searchAddress)}>Valider l'adresse</button>
//           <ul className='address-suggestions'>
//             {addressSuggestions.map((feature, idx) => (
//               <li key={idx} onClick={() => handleSelectAddress(feature)}>
//                 {feature.properties.label}
//               </li>
//             ))}
//           </ul>
//         </div>
//         <MapContainer center={selectedPoint || [48.6167, -3.0833]} zoom={13} style={{ height: '500px', width: '100%' }}>
//           <MoveMap position={selectedPoint} />
//           <TileLayer
//             url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           />
//           <MultipleDraggableMarkers />
//         </MapContainer>
//         <div className='map-modal-footer'>
//           <button onClick={handleCloseMapModal}>Valider les points</button>
//           <button onClick={() => setMapModalVisible(false)}>Annuler</button>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={pointsListModalVisible}
//         onRequestClose={handleClosePointsListModal}
//         contentLabel='Ajouter les détails des points de collecte'
//         className='Modal'
//         overlayClassName='Overlay'
//       >
//         <h2>Ajouter les détails des points de collecte</h2>
//         <div className='multi-points-details-list'>
//           {multiPointsDetails.map((point, idx) => (
//             <div key={idx} className='modal-section'>
//               <h3>Point #{idx + 1}</h3>
//               <label>
//                 Adresse:
//                 <input type='text' value={point.address} readOnly />
//               </label>
//               <label>
//                 Catégories:
//                 <div className='category-list'>
//                   {categories.map((category) => (
//                     <div
//                       key={category.id}
//                       className={`category-item ${point.categories && point.categories.includes(category.id) ? 'selected' : ''}`}
//                       onClick={() => handleCategoryChangeForPoint(idx, category.id)}
//                     >
//                       {category.name}
//                     </div>
//                   ))}
//                 </div>
//               </label>
//               <label>
//                 Nom:
//                 <input
//                   type='text'
//                   value={point.name}
//                   onChange={(e) => handleAddPointDetail(idx, 'name', e.target.value)}
//                   placeholder='Nom du point de collecte'
//                 />
//               </label>
//               <label>
//                 Description:
//                 <textarea
//                   value={point.description}
//                   onChange={(e) => handleAddPointDetail(idx, 'description', e.target.value)}
//                   placeholder='Description du point de collecte'
//                 />
//               </label>
//             </div>
//           ))}
//         </div>
//         <div className='modal-footer'>
//           <button onClick={addMultipleCollectors}>Valider</button>
//           <button onClick={handleClosePointsListModal}>Retour</button>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={editModalVisible}
//         onRequestClose={() => setEditModalVisible(false)}
//         contentLabel='Modifier le point de collecte'
//         className='Modal'
//         overlayClassName='Overlay'
//       >
//         <h2>Modifier le point de collecte</h2>
//         {currentCollector && (
//           <div className='modal-section'>
//             <label>
//               Catégories:
//               <div className='category-list'>
//                 {categories.map((category) => (
//                   <div
//                     key={category.id}
//                     className={`category-item ${currentCollector.categories && currentCollector.categories.includes(category.id) ? 'selected' : ''}`}
//                     onClick={() => handleCategoryChangeForCurrentCollector(category.id)}
//                   >
//                     {category.name}
//                   </div>
//                 ))}
//               </div>
//             </label>
//             <label>
//               Nom:
//               <input
//                 type='text'
//                 value={currentCollector.name}
//                 onChange={(e) =>
//                   setCurrentCollector({
//                     ...currentCollector,
//                     name: e.target.value,
//                   })
//                 }
//                 placeholder='Nom du point de collecte'
//               />
//             </label>
//             <label>
//               Description:
//               <textarea
//                 value={currentCollector.description}
//                 onChange={(e) =>
//                   setCurrentCollector({
//                     ...currentCollector,
//                     description: e.target.value,
//                   })
//                 }
//                 placeholder='Description du point de collecte'
//               />
//             </label>
//           </div>
//         )}
//         <div className='modal-footer'>
//           <button onClick={handleUpdateCollector}>Valider</button>
//           <button onClick={() => setEditModalVisible(false)}>Annuler</button>
//         </div>
//       </Modal>

//       <Modal
//         isOpen={editCategoryModalVisible}
//         onRequestClose={() => setEditCategoryModalVisible(false)}
//         contentLabel='Modifier la catégorie'
//         className='Modal'
//         overlayClassName='Overlay'
//       >
//         <h2>Modifier la catégorie</h2>
//         {currentCategory && (
//           <div className='modal-section'>
//             <label>
//               Nom de la catégorie:
//               <input
//                 type='text'
//                 value={currentCategory.name}
//                 onChange={(e) =>
//                   setCurrentCategory({
//                     ...currentCategory,
//                     name: e.target.value,
//                   })
//                 }
//               />
//             </label>
//             <label>
//               Icône de la catégorie (PNG/JPEG/SVG):
//               <input
//                 type='file'
//                 accept='.png,.jpg,.jpeg,.svg'
//                 onChange={handleCategoryIconChange}
//               />
//             </label>
//             <label>
//               Image de la catégorie (PNG/JPEG/SVG):
//               <input
//                 type='file'
//                 accept='.png,.jpg,.jpeg,.svg'
//                 onChange={handleCategoryIconChange2}
//               />
//             </label>
            
//           </div>
//         )}
//         <div className='modal-footer'>
//           <button onClick={handleUpdateCategory}>Valider</button>
//           <button onClick={() => setEditCategoryModalVisible(false)}>Annuler</button>
//         </div>
//       </Modal>
     
//     </div>
//   );
// };

// export default CollectorsMap;

import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvent
} from 'react-leaflet';
import L from 'leaflet';
import Modal from 'react-modal';
import config from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import './CollectorsMap.css';
import { debounce } from 'lodash';
import DefaultInfoForm from './DefaultInfoForm';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Modal.setAppElement('#root');

const CollectorsMap = () => {
  const [collectors, setCollectors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryIcon, setNewCategoryIcon] = useState(null);
  const [collectiviteId, setCollectiviteId] = useState('');
  const [currentCategory, setCurrentCategory] = useState(null);
  const [editCategoryModalVisible, setEditCategoryModalVisible] = useState(false);
  const [newMarker, setNewMarker] = useState(null);
  const [newCollectorData, setNewCollectorData] = useState({ name: '', description: '', category: '', location: null });
  const [multiplePointsModalVisible, setMultiplePointsModalVisible] = useState(false);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [pointsListModalVisible, setPointsListModalVisible] = useState(false);
  const [multiPoints, setMultiPoints] = useState([]);
  const [multiPointsDetails, setMultiPointsDetails] = useState([]);
  const [searchAddress, setSearchAddress] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentCollector, setCurrentCollector] = useState(null);
  const [activeTab, setActiveTab] = useState('categories');
  const [searchAddress2, setSearchAddress2] = useState('');
  const [addressSuggestions2, setAddressSuggestions2] = useState([]);
  const [initialPosition, setInitialPosition] = useState([48.6167, -3.0833]);
  const [mapCenter, setMapCenter] = useState(initialPosition);
  const [mapMoveCenter, setMoveCenter] = useState([48.6167, -3.0833]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const [totalPages, setTotalPages] = useState(1);
  const [highlightedCollectorId, setHighlightedCollectorId] = useState(null);
  const [searchAddressInTable, setSearchAddressInTable] = useState('');
  const [filteredCollectors, setFilteredCollectors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [mapPrincipal, setMapPrincipal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [categorySearchQuery, setCategorySearchQuery] = useState('');
  const [subCategorySearchQuery, setSubCategorySearchQuery] = useState('');
  const [defaultInfo, setDefaultInfo] = useState({ defaultDescription: '', defaultProducts: [] });

  const markersRef = useRef({});

  const fetchCollectorsDebounced = debounce(async (latitude, longitude, page, search) => {
    const categoryIds = [...selectedCategories].join(',');
    const response = await fetch(
      `${config.domain}/api/collectors_categories?latitude=${latitude}&longitude=${longitude}&category_ids=${categoryIds}&page=${page}&per_page=${itemsPerPage}&search=${encodeURIComponent(search)}`
    );
    const data = await response.json();
    setCollectors(data.collectors || []);
    const { total_count, per_page } = data.pagination;
    setTotalPages(Math.ceil(total_count / per_page));
  }, 200);

  useEffect(() => {
    setFilteredCollectors(collectors);
  }, [collectors]);

  const mapRef = useRef(null);

  const MoveMap = () => {
    const map = useMap();
    setMapPrincipal(map);
    return null;
  };

  const DisplayMapCenter = () => {
    const map = useMap();
    useEffect(() => {
      const logCenter = () => {
        const center = map.getCenter();
        setMoveCenter([center.lat, center.lng]);
      };
      map.on('moveend', logCenter);
      return () => {
        map.off('moveend', logCenter);
      };
    }, [map]);
    return null;
  };

  const fetchCollectiviteID = async () => {
    const id = await AsyncStorage.getItem('selectedCollectiviteId');
    setCollectiviteId(id || '');
  };

  useEffect(() => {
    fetchCollectiviteID();
  }, []);

  const fetchCategories = async (latitude, longitude) => {
    console.log(`${config.domain}/api/categories_by_collectivite?collectivite_id=${collectiviteId}`)
    const generalResponse = await fetch(`${config.domain}/api/categories?latitude=${latitude}&longitude=${longitude}`);
    const generalCategories = await generalResponse.json();
    const collectiviteResponse = await fetch(`${config.domain}/api/categories_by_collectivite?collectivite_id=${collectiviteId}`);
    const collectiviteCategories = await collectiviteResponse.json();
    const allCategories = [
      ...(Array.isArray(generalCategories) ? generalCategories : []),
      ...(Array.isArray(collectiviteCategories) ? collectiviteCategories : []),
    ];
    const categoriesMap = new Map();
    allCategories.forEach(cat => {
      categoriesMap.set(cat.id, cat);
    });
    const uniqueCategories = Array.from(categoriesMap.values());
    setCategories(uniqueCategories);
  };

  const fetchCollectors = async (latitude, longitude, page = 1, search = '') => {
    const categoryIds = [...selectedCategories].join(',');
    const response = await fetch(
      `${config.domain}/api/collectors_categories?latitude=${latitude}&longitude=${longitude}&category_ids=${categoryIds}&page=${page}&per_page=${itemsPerPage}&search=${encodeURIComponent(search)}`
    );
    const data = await response.json();
    setCollectors(data.collectors || []);
    const { total_count, per_page } = data.pagination;
    setTotalPages(Math.ceil(total_count / per_page));
  };

  const handleSearchAddressInTable = (query) => {
    setSearchQuery(query);
    fetchCollectors(mapCenter[0], mapCenter[1], currentPage, query);
  };

  useEffect(() => {
    fetchCategories(mapMoveCenter[0], mapMoveCenter[1]);
    fetchCollectors(mapMoveCenter[0], mapMoveCenter[1], currentPage);
  }, [selectedCategories, collectiviteId]);

  useEffect(() => {
    fetchCollectors(mapCenter[0], mapCenter[1], currentPage, searchQuery);
  }, [mapCenter, collectiviteId]);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(categoryId)) {
        newSet.delete(categoryId);
      } else {
        newSet.add(categoryId);
      }
      return newSet;
    });
    setCurrentPage(1);
  };

  const handleSubCategoryClick = (categoryId) => {
    if (selectedSubCategories.includes(categoryId)) {
      setSelectedSubCategories(selectedSubCategories.filter(id => id !== categoryId));
    } else {
      setSelectedSubCategories([...selectedSubCategories, categoryId]);
    }
  };

  const handleNewCategoryIconChange = (e) => {
    setNewCategoryIcon(e.target.files[0]);
  };

  const addCategory = async () => {
    const url = `${config.domain}/api/add_category`;
    const data = {
      name: newCategoryName,
      collectivite_id: collectiviteId,
      parent_ids: selectedSubCategories
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      // const newCategory = await response.json();
      // setCategories((prev) => [...prev, newCategory]);
      // setNewCategoryName('');
      // setSelectedSubCategories([]);
      window.location.reload();
    } else {
      const errorResponse = await response.text();
      console.error('Failed to add category', response.status, errorResponse);
    }
  };

  // const addCategory = async () => {

    
  //   const url = `${config.domain}/api/add_category`;
  //   const data = {
  //     name: newCategoryName,
  //     collectivite_id: collectiviteId,
  //     parent_ids: selectedSubCategories
  //   };
  
  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(data)
  //     });
  
  //     if (response.ok) {
  //       const newCategory = await response.json();
  //       setCategories((prev) => [...prev, newCategory]);
  //       setNewCategoryName('');
  //       setSelectedSubCategories([]);
  //     } else {
  //       const errorResponse = await response.text();
  //       console.error('Failed to add category', response.status, errorResponse);
  //     }
  //   } catch (error) {
  //     console.error('Error during fetch', error);
  //   }
  // };
  
  const updateCategory = async (category) => {
    alert('test')
    const formData = new FormData();
    formData.append('name', category.name);
    formData.append('description', category.description);
    formData.append('color_1', category.color_1);
    formData.append('color_2', category.color_2);
    formData.append('default_description', defaultInfo.defaultDescription);
    if (category.icon) {
      formData.append('icon', category.icon);
    }
    if (category.image) {
      formData.append('image', category.image);
    }
    if (category.subcategories && category.subcategories.length > 0) {
      category.subcategories.forEach(subCatId => {
        formData.append('subcategories[]', subCatId);
      });
    }
  
    // Append default products
    defaultInfo.defaultProducts.forEach(product => {
      formData.append('default_products[]', product); // Sending product name
    });
  
    // Log the form data for debugging
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
  
    const url = `${config.domain}/api/update_category/${category.id}`;
    const response = await fetch(url, {
      method: 'PUT',
      body: formData,
    });
  
    if (response.ok) {
      const updatedCategory = await response.json();
      setCategories((prev) => prev.map((cat) => (cat.id === updatedCategory.id ? updatedCategory : cat)));
      setEditCategoryModalVisible(false);
    } else {
      const errorText = await response.text();
      console.error('Failed to update category', response.status, errorText);
    }
  };
  



  const deleteCategory = async (categoryId) => {
    const url = `${config.domain}/api/delete_category_carto_hoali/${categoryId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.ok) {
      setCategories((prev) => prev.filter((cat) => cat.id !== categoryId));
      alert('Catégorie supprimée avec succès');
    } else {
      console.error('Failed to delete category');
      alert('Erreur lors de la suppression de la catégorie');
    }
  };

  const handleEditCategory = (category) => {
    setCurrentCategory({
      ...category,
      icon: null,
      subcategories: category.subcategories ? category.subcategories.map(sub => sub.id) : []
    });
    setDefaultInfo({
      defaultDescription: category.default_description || '',
      defaultProducts: category.default_products || []
    });
    setEditCategoryModalVisible(true);
  };

  const handleUpdateCategory = () => {
    if (currentCategory) {
      updateCategory(currentCategory);
    }
  };

  const handleCategoryIconChange2 = (e) => {
    setCurrentCategory((prev) => ({ ...prev, image: e.target.files[0] }));
  };

  const handleCategoryIconChange = (e) => {
    setCurrentCategory((prev) => ({ ...prev, icon: e.target.files[0] }));
  };

  const addMultipleCollectors = async () => {
    if (!multiPointsDetails.length) {
      alert('Veuillez ajouter au moins un point.');
      return;
    }

    const collectorsToAdd = multiPointsDetails.map((detail) => {
      const addressParts = detail.address ? detail.address.split(' ') : [];
      return {
        categories: detail.categories || [],
        name: detail.name || '',
        description: detail.description || '',
        location: detail.location || { lat: 0, lng: 0 },
        address: detail.address || '',
        collectivite_id: parseInt(collectiviteId, 10),
        adr_num: addressParts[0] || '',
        adr_street: addressParts.slice(1, -3).join(' ') || '',
        adr_city: addressParts.slice(-3, -2)[0] || '',
        adr_zip_code: parseInt(addressParts.slice(-2, -1)[0], 10) || 0,
        adr_country: addressParts.slice(-1)[0] || '',
      };
    });

    const url = `${config.domain}/api/add_multiple_collectors`;
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(collectorsToAdd),
    });

    if (response.ok) {
      const result = await response.json();
      setCollectors((prev) => [...prev, ...result.collectors]);
      setMultiplePointsModalVisible(false);
      setPointsListModalVisible(false);
      setMultiPoints([]);
      setMultiPointsDetails([]);
    } else {
      console.error('Failed to add multiple collectors');
    }
  };

  const DraggableMarker = () => {
    const [position, setPosition] = useState(newMarker);
    const markerRef = useRef(null);

    const eventHandlers = {
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          setNewCollectorData((prev) => ({ ...prev, location: marker.getLatLng() }));
        }
      },
    };

    return position === null ? null : (
      <Marker draggable={true} eventHandlers={eventHandlers} position={position} ref={markerRef}>
        <Popup>Glissez ce marqueur ou cliquez sur la carte pour ajouter un nouveau point.</Popup>
      </Marker>
    );
  };

  const MultipleDraggableMarkers = () => {
    const [positions, setPositions] = useState(multiPoints);
    const markerRefs = useRef(Array.from({ length: positions.length }, () => React.createRef()));

    const addMarker = (e) => {
      const newMarker = e.latlng;
      setPositions((prev) => [...prev, newMarker]);
      markerRefs.current = [...markerRefs.current, React.createRef()];
    };

    const eventHandlers = (idx) => ({
      dragend() {
        const marker = markerRefs.current[idx].current;
        if (marker != null) {
          const newPos = marker.getLatLng();
          setPositions((prev) => prev.map((pos, i) => (i === idx ? newPos : pos)));
        }
      },
    });

    useMapEvent('click', addMarker);

    useEffect(() => {
      setMultiPoints(positions);
    }, [positions]);

    return (
      <>
        {positions.map((pos, idx) => (
          <Marker
            key={idx}
            draggable
            eventHandlers={eventHandlers(idx)}
            position={pos}
            ref={markerRefs.current[idx]}>
            <Popup>Glissez ce marqueur ou cliquez sur la carte pour ajouter un point.</Popup>
          </Marker>
        ))}
      </>
    );
  };

  const handleMultiplePoints = () => {
    setMultiplePointsModalVisible(true);
  };

  const handleAddManualPoint = async () => {
    const address = await getReverseGeocodedAddress(0, 0);
    setMultiPoints((prev) => [...prev, { lat: 0, lng: 0 }]);
    setMultiPointsDetails((prev) => [
      ...prev,
      { name: '', description: '', categories: [], location: { lat: 0, lng: 0 }, address }
    ]);
  };

  const handleAddGpsCoords = async (idx, key, value) => {
    const newPos = { ...multiPoints[idx], [key]: parseFloat(value) };
    const address = await getReverseGeocodedAddress(newPos.lat, newPos.lng);
    setMultiPoints((prev) => prev.map((pos, i) => (i === idx ? newPos : pos)));
    setMultiPointsDetails((prev) => prev.map((detail, i) => (i === idx ? { ...detail, location: newPos, address } : detail)));
  };

  const handleSearchAddress = async (address) => {
    setSearchAddress(address);
    if (address.length < 3) return;

    const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(address)}&limit=5`);
    const data = await response.json();
    setAddressSuggestions(data.features || []);
  };

  const handleSelectAddress = async (feature) => {
    const [lng, lat] = feature.geometry.coordinates;
    const address = feature.properties.label;
    const newPoint = { lat, lng };
    setMultiPoints((prev) => [...prev, newPoint]);
    setMultiPointsDetails((prev) => [
      ...prev,
      { name: '', description: '', categories: [], location: newPoint, address }
    ]);
    setSelectedPoint(newPoint);
    setSearchAddress('');
    setAddressSuggestions([]);
  };

  const handleRemovePoint = (idx) => {
    setMultiPoints((prev) => prev.filter((_, i) => i !== idx));
    setMultiPointsDetails((prev) => prev.filter((_, i) => i !== idx));
  };

  const handleOpenMapModal = () => {
    setMapModalVisible(true);
    setMultiplePointsModalVisible(false);
  };

  const handleCloseMapModal = () => {
    setMapModalVisible(false);
    handleFinalizePoints();
  };

  const handleOpenPointsListModal = () => {
    setPointsListModalVisible(true);
    setMultiplePointsModalVisible(false);
  };

  const handleClosePointsListModal = () => {
    setPointsListModalVisible(false);
    setMultiplePointsModalVisible(true);
  };

  const handleAddPointDetail = (idx, key, value) => {
    setMultiPointsDetails((prev) => prev.map((detail, i) => (i === idx ? { ...detail, [key]: value } : detail)));
  };

  const handleCategoryChangeForPoint = (idx, categoryId) => {
    setMultiPointsDetails((prev) => {
      const updatedDetails = prev.map((detail, i) => {
        if (i === idx) {
          const categories = detail.categories || [];
          const newCategories = categories.includes(categoryId)
            ? categories.filter((id) => id !== categoryId)
            : [...categories, categoryId];
          return { ...detail, categories: newCategories };
        }
        return detail;
      });
      return updatedDetails;
    });
  };

  const getReverseGeocodedAddress = async (lat, lng) => {
    const response = await fetch(`https://api-adresse.data.gouv.fr/reverse/?lat=${lat}&lon=${lng}`);
    const data = await response.json();
    return data.features.length > 0 ? data.features[0].properties.label : 'Adresse inconnue';
  };

  const handleFinalizePoints = async () => {
    const details = await Promise.all(
      multiPoints.map(async (pos) => ({
        name: '',
        description: '',
        categories: [],
        location: pos,
        address: await getReverseGeocodedAddress(pos.lat, pos.lng),
      }))
    );
    setMultiPointsDetails(details);
    setPointsListModalVisible(true);
  };

  const deleteCollector = async (collectorId) => {
    const url = `${config.domain}/api/delete_collector/${collectorId}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.ok) {
      setCollectors((prev) => prev.filter((col) => col.id !== collectorId));
      alert('Point supprimé avec succès');
    } else {
      console.error('Failed to delete collector');
      alert('Erreur lors de la suppression du point');
    }
  };

  const updateCollector = async (collector) => {
    const url = `${config.domain}/api/update_collector/${collector.id}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(collector),
    });

    if (response.ok) {
      const message = await response.json();
      alert(message.message);
    } else {
      console.error('Failed to update collector');
      alert('Erreur lors de la mise à jour du point');
    }
  };

  const handleEditCollector = async (collector) => {
    const collectorDetails = await fetchCollectorDetails(collector.id);
    if (collectorDetails) {
      setCurrentCollector({
        ...collectorDetails,
        categories: collectorDetails.categories.map(c => c.category_id)
      });
      setEditModalVisible(true);
    }
  };

  const handleRemoveCollector = async (collectorId) => {
    await deleteCollector(collectorId);
  };

  const handleUpdateCollector = () => {
    if (currentCollector) {
      const updatedCollector = {
        ...currentCollector,
        categories: currentCollector.categories || []
      };

      updateCollector(updatedCollector);
      setEditModalVisible(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearchAddress_principal = async (address) => {
    setSearchAddress2(address);
    if (address.length < 3) return;

    const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(address)}&limit=5`);
    const data = await response.json();
    setAddressSuggestions2(data.features || []);
  };

  const handleSelectAddress_principal = async (feature) => {
    const [lng, lat] = feature.geometry.coordinates;
    setMapCenter([lat, lng]);
    mapPrincipal.setView([lat, lng]);
    fetchCategories(lat, lng);
    fetchCollectors(lat, lng, currentPage);
    setAddressSuggestions2([]);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchCollectorsDebounced(mapMoveCenter[0], mapMoveCenter[1], pageNumber, searchQuery);
  };

  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`pagination-button ${i === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>
      );
    }
    return <div className='pagination'>{pages}</div>;
  };

  const selectCollector = (collectorId) => {
    setHighlightedCollectorId(collectorId);
    if (markersRef.current[collectorId]) {
      markersRef.current[collectorId].openPopup();
    }
  };

  const MarkerWithHighlight = ({ collector }) => {
    const markerRef = useRef(null);
    useEffect(() => {
      markersRef.current[collector.id] = markerRef.current;
    }, [collector.id]);

    return (
      <Marker
        ref={markerRef}
        position={[collector.location_x, collector.location_y]}
        eventHandlers={{
          click: () => {
            selectCollector(collector.id);
          }
        }}
      >
        <Popup>
          <strong>{collector.category_name}</strong>
          <p>{collector.description}</p>
          <button onClick={() => handleEditCollector(collector)}>Modifier</button>
          <button onClick={() => handleRemoveCollector(collector.id)}>Supprimer</button>
        </Popup>
      </Marker>
    );
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setInitialPosition([latitude, longitude]);
        setMapCenter([latitude, longitude]);
      },
      (error) => {
        console.error('Error getting user location:', error);
      },
      { enableHighAccuracy: true }
    );
  }, []);

  const reloadData = async () => {
    setIsLoading(true);
    setSelectedCategories(new Set());
    fetchCategories(mapMoveCenter[0], mapMoveCenter[1]);
    fetchCollectors(mapMoveCenter[0], mapMoveCenter[1], 1);
    setIsLoading(false);
  };

  const handleSubCategoryToggle = (categoryId) => {
    const currentIndex = currentCategory.subcategories.indexOf(categoryId);
    const newSubcategories = [...currentCategory.subcategories];

    if (currentIndex === -1) {
      newSubcategories.push(categoryId);
    } else {
      newSubcategories.splice(currentIndex, 1);
    }

    setCurrentCategory({ ...currentCategory, subcategories: newSubcategories });
  };

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(categorySearchQuery.toLowerCase())
  );

  const filteredSubCategories = categories.filter(category =>
    category.name.toLowerCase().includes(subCategorySearchQuery.toLowerCase())
  );

  const handleCategoryChangeForCurrentCollector = (categoryId) => {
    setCurrentCollector((prev) => {
      const categories = prev.categories || [];
      const newCategories = categories.includes(categoryId)
        ? categories.filter((id) => id !== categoryId)
        : [...categories, categoryId];
      return { ...prev, categories: newCategories };
    });
  };

  const fetchCollectorDetails = async (collectorId) => {
    const url = `${config.domain}/api/collector/${collectorId}`;
    const response = await fetch(url);
    if (response.ok) {
      const collectorDetails = await response.json();
      return collectorDetails;
    } else {
      console.error('Failed to fetch collector details');
      return null;
    }
  };

  return (
    <div>
      <div className='address-search-bar'>
        <input
          type='text'
          value={searchAddress2}
          onChange={(e) => handleSearchAddress_principal(e.target.value)}
          placeholder='Rechercher une commune ou une adresse'
        />
        <ul className='address-suggestions'>
          {addressSuggestions2.map((feature, idx) => (
            <li key={idx} onClick={() => handleSelectAddress_principal(feature)}>
              {feature.properties.label}
            </li>
          ))}
        </ul>
      </div>
      <MapContainer
        center={mapCenter}
        zoom={13}
        style={{ height: '600px', width: '100%' }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MoveMap />
        <DisplayMapCenter />
        {collectors.map(collector => (
          <MarkerWithHighlight
            key={collector.id}
            collector={collector}
          />
        ))}
        <DraggableMarker />

        <div className="map-button-container">
          <button
            onClick={reloadData}
            className="reload-data-button"
            aria-label="Recharger les données de la carte"
            disabled={isLoading}
          >
            {isLoading ? 'Chargement...' : 'Charger les données'}
          </button>
        </div>
      </MapContainer>

      <div className='add-points-buttons'>
        <button onClick={handleMultiplePoints} className='add-multiple-points-button'>
          Ajouter plusieurs points
        </button>
      </div>

      <div className='tabs-container'>
        <div className='tabs'>
          <button
            className={activeTab === 'categories' ? 'tab active' : 'tab'}
            onClick={() => handleTabChange('categories')}
          >
            Catégories
          </button>
          <button
            className={activeTab === 'collectors' ? 'tab active' : 'tab'}
            onClick={() => handleTabChange('collectors')}
          >
            Points de Collecte
          </button>
        </div>
        <div className='tab-content'>
          {activeTab === 'categories' ? (
            <div className='categories-column'>
              <div className='category-search'>
                <input
                  type='text'
                  value={categorySearchQuery}
                  onChange={(e) => setCategorySearchQuery(e.target.value)}
                  placeholder='Rechercher une catégorie'
                />
              </div>
              <div className='categories-container'>
                {filteredCategories.map((category) => (
                  <div key={category.id} className={`category-item ${selectedCategories.has(category.id) ? 'selected' : ''}`}>
                    <button className='category-button' onClick={() => handleCategoryChange(category.id)}>
                      {category.icon ? (
                        <img
                          src={`${config.domain}/uploads/icons/${category.icon}`}
                          alt={category.name}
                          className='category-icon'
                        />
                      ) : (
                        <span style={{ color: 'black' }}>{category.name}</span>
                      )}
                    </button>
                    {category.subcategories.length > 0 && (
                      <div className='subcategories'>
                        {category.subcategories.map((sub) => (
                          <div key={sub.id} className='subcategory'>
                            {sub.name}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className='category-actions'>
                      <button onClick={() => handleEditCategory(category)}>Modifier</button>
                      <button onClick={() => deleteCategory(category.id)}>Supprimer</button>
                    </div>
                  </div>
                ))}
              </div>

              <div className='add-category-form'>
                <h3>Informations de la Catégorie</h3>
                <label htmlFor="categoryName">
                  Nom de la catégorie:
                  <input
                    id="categoryName"
                    type='text'
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder='Entrez le nom de la catégorie'
                  />
                </label>

                <h3>Image de la Catégorie</h3>
                <p>Ajoutez une image représentative pour la catégorie :</p>
                <input
                  type='file'
                  accept='.png,.jpg,.jpeg,.svg'
                  onChange={handleNewCategoryIconChange}
                />

                <button onClick={addCategory} className='add-category-button'>
                  + Ajouter une catégorie
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className='table-search'>
                <input
                  type='text'
                  value={searchQuery}
                  onChange={(e) => handleSearchAddressInTable(e.target.value)}
                  placeholder='Rechercher par nom ou adresse'
                />
              </div>

              <table className='collectors-table'>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Catégories</th>
                    <th>Description</th>
                    <th>Adresse</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {collectors.map((collector) => (
                    <tr
                      key={collector.id}
                      className={highlightedCollectorId === collector.id ? 'highlighted' : ''}
                      onClick={() => selectCollector(collector.id)}
                    >
                      <td>{collector.name || '-'}</td>
                      <td>
                        {collector.categories && collector.categories.map(category => (
                          <div key={category.category_id}>{category.category_name}</div>
                        ))}
                      </td>
                      <td>{collector.description || '-'}</td>
                      <td>{collector.address}</td>
                      <td>
                        <button onClick={(e) => { e.stopPropagation(); handleEditCollector(collector); }}>Modifier</button>
                        <button onClick={(e) => { e.stopPropagation(); handleRemoveCollector(collector.id); }}>Supprimer</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={multiplePointsModalVisible}
        onRequestClose={() => setMultiplePointsModalVisible(false)}
        contentLabel='Ajouter plusieurs points de collecte'
        className='Modal'
        overlayClassName='Overlay'
      >
        <h2>Ajouter plusieurs points de collecte</h2>
        <div className='modal-actions'>
          <button onClick={handleOpenMapModal}>Placer les points sur la carte</button>
          <button onClick={handleAddManualPoint}>Ajouter manuellement</button>
        </div>
        <div className='multi-points-list'>
          {multiPointsDetails.map((point, idx) => (
            <div key={idx} className='modal-section'>
              <h3>Point #{idx + 1}</h3>
              <label>
                Adresse:
                <input type='text' value={point.address} readOnly />
              </label>
              <label>
                Latitude:
                <input
                  type='number'
                  value={point.location.lat}
                  onChange={(e) => handleAddGpsCoords(idx, 'lat', e.target.value)}
                />
              </label>
              <label>
                Longitude:
                <input
                  type='number'
                  value={point.location.lng}
                  onChange={(e) => handleAddGpsCoords(idx, 'lng', e.target.value)}
                />
              </label>
              <button className='remove-point-button' onClick={() => handleRemovePoint(idx)}>
                Supprimer
              </button>
            </div>
          ))}
        </div>
        <div className='modal-footer'>
          <button onClick={handleOpenPointsListModal}>Suivant</button>
          <button onClick={() => setMultiplePointsModalVisible(false)}>Annuler</button>
        </div>
      </Modal>

      <Modal
        isOpen={mapModalVisible}
        onRequestClose={handleCloseMapModal}
        contentLabel='Sélectionner un emplacement sur la carte'
        className='MapModal'
        overlayClassName='Overlay'
      >
        <h2>Sélectionner un emplacement sur la carte</h2>
        <div className='address-search'>
          <input
            type='text'
            value={searchAddress}
            onChange={(e) => handleSearchAddress(e.target.value)}
            placeholder='Rechercher une adresse'
          />
          <button onClick={() => handleSearchAddress(searchAddress)}>Valider l'adresse</button>
          <ul className='address-suggestions'>
            {addressSuggestions.map((feature, idx) => (
              <li key={idx} onClick={() => handleSelectAddress(feature)}>
                {feature.properties.label}
              </li>
            ))}
          </ul>
        </div>
        <MapContainer center={selectedPoint || [48.6167, -3.0833]} zoom={13} style={{ height: '500px', width: '100%' }}>
          <MoveMap position={selectedPoint} />
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MultipleDraggableMarkers />
        </MapContainer>
        <div className='map-modal-footer'>
          <button onClick={handleCloseMapModal}>Valider les points</button>
          <button onClick={() => setMapModalVisible(false)}>Annuler</button>
        </div>
      </Modal>

      <Modal
        isOpen={pointsListModalVisible}
        onRequestClose={handleClosePointsListModal}
        contentLabel='Ajouter les détails des points de collecte'
        className='Modal'
        overlayClassName='Overlay'
      >
        <h2>Ajouter les détails des points de collecte</h2>
        <div className='multi-points-details-list'>
          {multiPointsDetails.map((point, idx) => (
            <div key={idx} className='modal-section'>
              <h3>Point #{idx + 1}</h3>
              <label>
                Adresse:
                <input type='text' value={point.address} readOnly />
              </label>
              <label>
                Catégories:
                <div className='category-list'>
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      className={`category-item ${point.categories && point.categories.includes(category.id) ? 'selected' : ''}`}
                      onClick={() => handleCategoryChangeForPoint(idx, category.id)}
                    >
                      {category.name}
                    </div>
                  ))}
                </div>
              </label>
              <label>
                Nom:
                <input
                  type='text'
                  value={point.name}
                  onChange={(e) => handleAddPointDetail(idx, 'name', e.target.value)}
                  placeholder='Nom du point de collecte'
                />
              </label>
              <label>
                Description:
                <textarea
                  value={point.description}
                  onChange={(e) => handleAddPointDetail(idx, 'description', e.target.value)}
                  placeholder='Description du point de collecte'
                />
              </label>
            </div>
          ))}
        </div>
        <div className='modal-footer'>
          <button onClick={addMultipleCollectors}>Valider</button>
          <button onClick={handleClosePointsListModal}>Retour</button>
        </div>
      </Modal>

      <Modal
        isOpen={editModalVisible}
        onRequestClose={() => setEditModalVisible(false)}
        contentLabel='Modifier le point de collecte'
        className='Modal'
        overlayClassName='Overlay'
      >
        <h2>Modifier le point de collecte</h2>
        {currentCollector && (
          <div className='modal-section'>
            <label>
              Catégories:
              <div className='category-list'>
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className={`category-item ${currentCollector.categories && currentCollector.categories.includes(category.id) ? 'selected' : ''}`}
                    onClick={() => handleCategoryChangeForCurrentCollector(category.id)}
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            </label>
            <label>
              Nom:
              <input
                type='text'
                value={currentCollector.name}
                onChange={(e) =>
                  setCurrentCollector({
                    ...currentCollector,
                    name: e.target.value,
                  })
                }
                placeholder='Nom du point de collecte'
              />
            </label>
            <label>
              Description:
              <textarea
                value={currentCollector.description}
                onChange={(e) =>
                  setCurrentCollector({
                    ...currentCollector,
                    description: e.target.value,
                  })
                }
                placeholder='Description du point de collecte'
              />
            </label>
          </div>
        )}
        <div className='modal-footer'>
          <button onClick={handleUpdateCollector}>Valider</button>
          <button onClick={() => setEditModalVisible(false)}>Annuler</button>
        </div>
      </Modal>

      <Modal
        isOpen={editCategoryModalVisible}
        onRequestClose={() => setEditCategoryModalVisible(false)}
        contentLabel='Modifier la catégorie'
        className='Modal'
        overlayClassName='Overlay'
      >
        <h2>Modifier la catégorie</h2>
        {currentCategory && (
          <div className='modal-section'>
            <label>
              Nom de la catégorie:
              <input
                type='text'
                value={currentCategory.name}
                onChange={(e) =>
                  setCurrentCategory({
                    ...currentCategory,
                    name: e.target.value,
                  })
                }
              />
            </label>
            <label>
              Icône de la catégorie (PNG/JPEG/SVG):
              <input
                type='file'
                accept='.png,.jpg,.jpeg,.svg'
                onChange={handleCategoryIconChange}
              />
            </label>
            <label>
              Image de la catégorie (PNG/JPEG/SVG):
              <input
                type='file'
                accept='.png,.jpg,.jpeg,.svg'
                onChange={handleCategoryIconChange2}
              />
            </label>
            <DefaultInfoForm
              category={currentCategory}
              onDefaultInfoChange={setDefaultInfo}
            />
          </div>
        )}
        <div className='modal-footer'>
          <button onClick={handleUpdateCategory}>Valider</button>
          <button onClick={() => setEditCategoryModalVisible(false)}>Annuler</button>
        </div>
      </Modal>
    </div>
  );
};

export default CollectorsMap;

