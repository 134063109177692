
import styled from 'styled-components';

export const Button = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
`;

export const ButtonContainer = styled.div`
  margin: 20px 0;
  text-align: center;
`;

export const activeButtonStyle = {
  backgroundColor: '#4CAF50', // Couleur de fond pour le bouton actif
  color: 'white'
};

export const inactiveButtonStyle = {
  backgroundColor: '#f0f0f0', // Couleur de fond pour le bouton inactif
  color: 'black'
};

export const disabledButtonStyle = {
  ...inactiveButtonStyle,
  opacity: 0.5 // Réduire l'opacité pour les boutons désactivés
};

export const SecondMapContainer = styled.div`
  margin-top: 20px;
  // padding: 15px;
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  width : 50%;
  margin-bottom : 100px
`;

export const SecondMapTitle = styled.h3`
  text-align: center;
  margin-bottom: 10px;
`;