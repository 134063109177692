import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';
const AddressSearch = ({ onSearch }) => {
    const [address, setAddress] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleSearch = async () => {
        if (!address) return; // Éviter une recherche vide
        try {
            const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(address)}&limit=5`);
            if (response.data.features.length > 0) {
                const location = response.data.features[0].geometry.coordinates.reverse();
                onSearch(location); // Passer les coordonnées [lat, lon] au parent
                setSuggestions([]); // Effacer les suggestions après la sélection
            } else {
                alert('Adresse non trouvée');
            }
        } catch (error) {
            console.error('Erreur lors de la recherche d\'adresse:', error);
            alert('Erreur lors de la recherche');
        }
    };

    const handleChange = async (e) => {
        setAddress(e.target.value);
        if (e.target.value.length > 2) { // Lancer la recherche à partir de 3 caractères
            try {
                const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(e.target.value)}&limit=5`);
                setSuggestions(response.data.features.map(feature => ({
                    label: feature.properties.label,
                    coordinates: feature.geometry.coordinates.reverse()
                })));
            } catch (error) {
                console.error('Erreur lors de la récupération des suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSelectSuggestion = (suggestion) => {
        setAddress(suggestion.label);
        onSearch(suggestion.coordinates);
        setSuggestions([]);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <div style={{ position: 'relative', width: '400px' }}>
                <input
                    type="text"
                    value={address}
                    onChange={handleChange}
                    placeholder="Entrez une rue..."
                    style={{ width: '100%', padding: '10px', fontSize: '16px' }}
                />
                <button onClick={handleSearch} style={{ padding: '10px 16px', marginLeft: '10px' }}>Chercher</button>
                {suggestions.length > 0 && (
                    <ul style={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                        zIndex: 10000,
                        border: '1px solid #ccc',
                        backgroundColor: '#fff',
                        listStyle: 'none',
                        padding: 0,
                        margin: 0
                    }}>
                        {suggestions.map(suggestion => (
                            <li key={suggestion.label} onClick={() => handleSelectSuggestion(suggestion)}
                                style={{
                                    padding: '8px 10px',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #ddd'
                                }}>
                                {suggestion.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default AddressSearch;
