import React from 'react';
import './DepartementSelector.css';
import config from '../../config';

const DepartementSelector2 = ({ onDepartementsChange }) => {
  const departements = {
    "22": "Côtes d'Armor",
    "29": "Finistère",
    "56": "Morbihan",
    "35": "Ille et Vilaine",
    "44": "Loire-Atlantique"
  };

  const handleChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    onDepartementsChange(selectedOptions);
  };

  return (
    <select multiple={true} onChange={handleChange} className="departement-selector">
      {Object.entries(departements).map(([code, name]) => (
        <option key={code} value={code}>{name}</option>
      ))}
    </select>
  );
};

export default DepartementSelector2;
