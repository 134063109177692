import React, { useState } from 'react';
import config from '../../config';
function DepartementSelector({ onDepartementsChange }) {
  const [inputValue, setInputValue] = useState('');
  const [departements, setDepartements] = useState([]);

  const handleAddDepartement = () => {
    if (inputValue && !departements.includes(inputValue)) {
      const newDepartements = [...departements, inputValue];
      setDepartements(newDepartements);
      onDepartementsChange(newDepartements); // Mise à jour des départements dans le composant parent
      setInputValue('');
    }
  };

  const handleRemoveDepartement = (departement) => {
    const filteredDepartements = departements.filter(d => d !== departement);
    setDepartements(filteredDepartements);
    onDepartementsChange(filteredDepartements); // Mise à jour des départements dans le composant parent
  };

  // Styles en ligne
  const styles = {
    container: {
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      maxWidth: '400px',
      margin: '20px auto',
      textAlign: 'center',
    },
    input: {
      padding: '10px',
      marginRight: '5px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    button: {
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#007BFF',
      color: 'white',
      cursor: 'pointer',
    },
    list: {
      listStyleType: 'none',
      padding: 0,
    },
    listItem: {
      padding: '5px',
      margin: '5px 0',
      borderRadius: '5px',
      backgroundColor: '#f0f0f0',
    },
    removeButton: {
      marginLeft: '10px',
      backgroundColor: '#dc3545',
      border: 'none',
      borderRadius: '5px',
      color: 'white',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.container}>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Numéro du département"
        style={styles.input}
      />
      <button onClick={handleAddDepartement} style={styles.button}>Ajouter</button>
      <ul style={styles.list}>
        {departements.map(departement => (
          <li key={departement} style={styles.listItem}>
            {departement} <button onClick={() => handleRemoveDepartement(departement)} style={styles.removeButton}>Retirer</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DepartementSelector;
