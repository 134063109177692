import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthContext'; 
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Container, Grid , Checkbox  } from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../config';
function UserDashboard() {
  const [tournees, setTournees] = useState([]);
  const [collectiviteId, setCollectiviteId] = useState('');

  useEffect(() => {
    const fetchCollectiviteID = async () => {
      const collectiviteId = await AsyncStorage.getItem('selectedCollectiviteId');
      setCollectiviteId(collectiviteId);
    };

    fetchCollectiviteID();
  }, []);


  const [selectedTournees, setSelectedTournees] = useState(new Set());
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleCreateCalendarClick = () => {
    navigate('/create_calendar'); // Naviguer vers la création de calendrier
  };

  const handleEditTourneeClick = (tourneeId) => {
    navigate(`/edit_tournee/${tourneeId}`); // Naviguer vers la page d'édition de la tournée spécifique
  };



  

  const handleDeleteTourneeClick = (tourneeId) => {
    // Confirmation avant suppression
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette tournée ?")) {
      fetch(`${config.domain}/api/delete_tournee/${tourneeId}`, {
        method: 'DELETE',
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de la suppression de la tournée');
        }
        return response.json();
      })
      .then(() => {
        alert('Tournée supprimée avec succès');
        // Mettre à jour la liste des tournées
        setTournees(tournees.filter(tournee => tournee.id !== tourneeId));
      })
      .catch(error => console.error('Erreur:', error));
    }
  };

  const handleCheckboxChange = (event, id) => {
    const newSet = new Set(selectedTournees);
    if (event.target.checked) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }
    setSelectedTournees(newSet);
  };


  const handleAssociateClick = () => {
    // Ici, vous pouvez envoyer les ID des tournées sélectionnées à votre backend
    // pour les associer à une collectivité, par exemple
    const tournee_ids = Array.from(selectedTournees);
    
    // Vérifier si des tournées sont sélectionnées
    if (tournee_ids.length === 0) {
      alert("Veuillez sélectionner au moins une tournée à associer.");
      return;
    }

    console.log(`${config.domain}/api/collectivites/${collectiviteId}/tournees`)
  console.log(tournee_ids)
    // Ici, vous pouvez envoyer les ID des tournées sélectionnées à votre backend
    // pour les associer à une collectivité, par exemple
    fetch(`${config.domain}/api/collectivites/${collectiviteId}/tournees`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tournee_ids,
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erreur lors de l\'association des tournées.');
      }
      alert('Tournées associées avec succès à la collectivité.');
      setSelectedTournees(new Set()); // Réinitialiser les tournées sélectionnées après l'association
    })
    .catch(error => {
      console.error('Erreur lors de l\'association des tournées :', error);
      alert('Une erreur est survenue lors de l\'association des tournées.');
    });
  };

  
  useEffect(() => {
    const userId = currentUser.iduser; 
    if(collectiviteId){
      console.log(`${config.domain}/api/get_user_tournees/${userId}?collectivite_id=${collectiviteId}`)

      fetch(`${config.domain}/api/get_user_tournees/${userId}?collectivite_id=${collectiviteId}`)
      .then(response => response.json())
      .then(data => setTournees(data))
      .catch(error => console.error('Erreur lors de la récupération des tournées:', error));

    }
  }, [currentUser.iduser, collectiviteId]);

  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateCalendarClick}
        style={{ margin: '20px 0' }}
      >
        Créer mon calendrier
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAssociateClick}
        style={{ marginBottom: '20px' }}
      >
        Associer les calendriers sélectionnés
      </Button>
      <Grid container spacing={2}>
        {tournees.map((tournee) => (
          <Grid item xs={12} sm={6} md={4} key={tournee.id}>
            <Card>
              <CardContent>
              <Checkbox
                  checked={selectedTournees.has(tournee.id)}
                  onChange={e => handleCheckboxChange(e, tournee.id)}
                  inputProps={{ 'aria-label': 'Select tournee' }}
                />
                <Typography variant="h5" component="h2">
                  {tournee.name}
                </Typography>
                {/* Bouton Modifier */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleEditTourneeClick(tournee.id)}
                  style={{ marginTop: '10px', marginRight: '10px' }}
                >
                  Modifier
                </Button>
                {/* Bouton Supprimer */}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleDeleteTourneeClick(tournee.id)}
                  style={{ marginTop: '10px' }}
                >
                  Supprimer
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default UserDashboard;
