import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Assurez-vous d'inclure le CSS pour react-calendar
import './CalendarComponent.css'

import config from '../../config';

import Modal from 'react-modal';

// Assurez-vous que l'élément racine de votre application est défini pour React-Modal
Modal.setAppElement('#root');

function calculateYearRange(selectedDates) {
  let minYear = Infinity;
  let maxYear = -Infinity;

  selectedDates.forEach((_, dateStr) => {
    const year = new Date(dateStr).getFullYear();
    minYear = Math.min(minYear, year);
    maxYear = Math.max(maxYear, year);
  });

  // Retourne la plage et le nombre d'années
  return {
    minYear,
    maxYear,
    range: maxYear - minYear + 1 // +1 pour inclure les deux années dans le compte
  };
}


function calculateEasterDate(year) {
  const f = Math.floor,
    // Formule de calcul de la date de Pâques (algorithme de Meeus/Jones/Butcher)
    G = year % 19,
    C = f(year / 100),
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);

  return { month, day };
}

function calculateSpecialDays(year) {
  const easter = calculateEasterDate(year),
    easterDate = new Date(year, easter.month - 1, easter.day),
    specialDays = {
      // Jours fixes
      '01-01': { description: 'Jour de l’an' },
      '05-01': { description: 'Fête du travail' },
      '05-08': { description: '8 mai 1945' },
      '07-14': { description: 'Fête nationale' },
      '08-15': { description: 'Assomption' },
      '11-01': { description: 'La Toussaint' },
      '11-11': { description: 'Armistice' },
      '12-25': { description: 'Noël' },
    };

  // Lundi de Pâques (le lendemain du dimanche de Pâques)
  const easterMonday = new Date(easterDate.getTime() + 24 * 60 * 60 * 1000);
  specialDays[`${(easterMonday.getMonth() + 1).toString().padStart(2, '0')}-${easterMonday.getDate().toString().padStart(2, '0')}`] = { description: 'Lundi de Pâques' };


  // Ascension (39 jours après Pâques)
  const ascension = new Date(easterDate.getTime() + 39 * 24 * 60 * 60 * 1000);
  specialDays[`${(ascension.getMonth() + 1).toString().padStart(2, '0')}-${ascension.getDate().toString().padStart(2, '0')}`] = { description: 'Jeudi de l’Ascension' };




  // Lundi de Pentecôte (50 jours après Pâques)
  const pentecostMonday = new Date(easterDate.getTime() + 50 * 24 * 60 * 60 * 1000);
  specialDays[`${(pentecostMonday.getMonth() + 1).toString().padStart(2, '0')}-${pentecostMonday.getDate().toString().padStart(2, '0')}`] = { description: 'Lundi de Pentecôte' };


  return specialDays;
}


const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

function getDayOfWeek(date) {
  return daysOfWeek[new Date(date).getDay()];
}


const recurrenceOptions = {
  NONE: 'Libre',
  WEEKLY: 'Hebdomadaire',
  BIWEEKLY: 'Deux semaines',
  MONTHLY: 'Mensuel'
};

















function CalendarCreateComponent({ categoriesActives, selectedDates, setSelectedDates }) {
  // const [selectedDates, setSelectedDates] = useState(new Set());


  const [recurrence, setRecurrence] = useState(recurrenceOptions.NONE);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [specialDays, setSpecialDays] = useState(calculateSpecialDays(currentYear));
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dateClickInfo, setDateClickInfo] = useState({
    dates: [], // Liste des dates sélectionnées
    lastAction: null, // 'added' pour ajouté, 'removed' pour retiré, null au début
  });
  const [clickActions, setClickActions] = useState([]);

  useEffect(() => {
    console.log("dateclick : ", clickActions)
  }, [clickActions])

  useEffect(() => {
    console.log("selectedCategory: ", selectedCategory)
  }, [selectedCategory])



  const handleYearChange = (event) => {
    const year = event.target.value;
    if (year && year.length === 4) {
      setCurrentYear(Number(year));
    }
  };


  const handleRecurrenceChange = (event) => {

    setRecurrence(event.target.value);
    // Réinitialisez ou ajustez les dates sélectionnées selon la récurrence
  };











  const toggleDate2 = (dateStr, recurrence, categorie, action) => {
    console.log("dateStr 2 : ", dateStr);
    // Tente de récupérer l'info de la date, sinon crée un nouvel objet
    let dateInfo = selectedDates.get(dateStr) || { categories: new Map() };
    let isAdding = action !== "removed"; // Supposons que moved signifie suppression dans ce contexte

    console.log("action : ", action)

    if (action === "removed") {

      // Si l'action est "moved", supprimer la date complètement
      selectedDates.delete(dateStr);
    } else if (dateInfo.categories.has(categorie)) {
      // Si la catégorie existe déjà, la supprimer (si l'action n'est pas "moved")
      dateInfo.categories.delete(categorie);
      isAdding = false; // On passe en mode suppression
    } else {
      // Ajouter la catégorie avec sa récurrence (si l'action n'est pas "moved")
      dateInfo.categories.set(categorie, recurrence);
    }

    // Si après modification, il n'y a plus de catégories, supprimer la date
    if (dateInfo.categories.size === 0) {
      selectedDates.delete(dateStr);
    } else if (action !== "removed") { // Ne pas ajouter à nouveau si l'action était "moved"

      selectedDates.set(dateStr, dateInfo);
    }

    // Gère la récurrence si nécessaire et si l'action n'est pas "moved"
    if (recurrence !== recurrenceOptions.NONE && recurrence !== "NONE" && action !== "removed") {
      handleRecurrenceToggle(new Date(dateStr), selectedDates, recurrence, categorie, isAdding);
    }

    // Mise à jour de l'état avec les dates sélectionnées
    setSelectedDates(new Map(selectedDates));

    // Enregistre l'action de l'utilisateur, ajustez selon besoin
    // updateDateclick(dateStr, categorie, recurrence, isAdding);
  };



  // const toggleDate = (dateStr) => {
  //   if (!selectedCategory) {
  //     alert('Veuillez sélectionner une catégorie.');
  //     return;
  //   }

  //   console.log("dateStr : ", dateStr)
  //   // Tente de récupérer l'info de la date, sinon crée un nouvel objet
  //   let dateInfo = selectedDates.get(dateStr) || { categories: new Map() };
  //   let isAdding = true; // Assume qu'on ajoute une catégorie par défaut

  //   if (dateInfo.categories.has(selectedCategory)) {
  //     // Si la catégorie existe, la supprimer
  //     dateInfo.categories.delete(selectedCategory);
  //     isAdding = false; // On passe en mode suppression
  //   } else {
  //     // Sinon, ajoute la catégorie avec sa récurrence
  //     dateInfo.categories.set(selectedCategory, recurrence);
  //   }

  //   if (dateInfo.categories.size === 0) {
  //     selectedDates.delete(dateStr);
  //   } else {
  //     selectedDates.set(dateStr, dateInfo);
  //   }

  //   // Gère la récurrence si nécessaire
  //   if (recurrence !== recurrenceOptions.NONE && recurrence !== "NONE") {
  //     handleRecurrenceToggle(new Date(dateStr), selectedDates, recurrence, selectedCategory, isAdding);
  //   }

  //   // Mise à jour de l'état
  //   setSelectedDates(new Map(selectedDates));

  //   // Enregistre l'action de l'utilisateur
  //   updateDateclick(dateStr, selectedCategory, recurrence, isAdding);
  // };

  const toggleDate = (dateStr) => {
    if (!selectedCategory) {
      alert('Veuillez sélectionner une catégorie.');
      return;
    }
  
    console.log("dateStr : ", dateStr);
    // Récupère l'info de la date ou crée un nouvel objet si non existant
    let dateInfo = selectedDates.get(dateStr) || { categories: new Map() };
  
    // Assure que categories est toujours une Map
    if (!(dateInfo.categories instanceof Map)) {
      dateInfo.categories = new Map();
    }
  
    let isAdding = true; // Suppose qu'on ajoute une catégorie par défaut
  
    if (dateInfo.categories.has(selectedCategory)) {
      // Si la catégorie existe, la supprimer
      dateInfo.categories.delete(selectedCategory);
      isAdding = false; // On passe en mode suppression
    } else {
      // Sinon, ajoute la catégorie avec sa récurrence
      dateInfo.categories.set(selectedCategory, recurrence);
    }
  
    if (dateInfo.categories.size === 0) {
      selectedDates.delete(dateStr);
    } else {
      selectedDates.set(dateStr, dateInfo);
    }
  
    // Gère la récurrence si nécessaire
    if (recurrence !== recurrenceOptions.NONE && recurrence !== "NONE") {
      handleRecurrenceToggle(new Date(dateStr), selectedDates, recurrence, selectedCategory, isAdding);
    }
  
    // Mise à jour de l'état
    setSelectedDates(new Map(selectedDates));
  
    // Enregistre l'action de l'utilisateur
    updateDateclick(dateStr, selectedCategory, recurrence, isAdding);
  };
  

  const updateDateclick = (dateStr, category, recurrence, isAdding) => {
    if (isAdding) {
      // Ajouter l'entrée
      setDateClickInfo(prevInfo => ({
        ...prevInfo,
        dates: [...prevInfo.dates, { date: dateStr, category, recurrence }]
      }));
      setClickActions(prevActions => [...prevActions, { date: dateStr, category, recurrence, action: 'added' }]);
    } else {
      // Trouver et supprimer l'entrée dans dateClickInfo.dates
      const index = dateClickInfo.dates.findIndex(entry => entry.date === dateStr && entry.category === category);
      if (index !== -1) {
        let updatedDates = [...dateClickInfo.dates];
        updatedDates.splice(index, 1);
        setDateClickInfo(prevInfo => ({
          ...prevInfo,
          dates: updatedDates
        }));
      }

      // Ajouter une action 'removed' ou supprimer l'action 'added' correspondante
      setClickActions(prevActions => {
        const addActionIndex = prevActions.findIndex(action => action.date === dateStr && action.category === category && action.action === 'added');
        if (addActionIndex !== -1) {
          // Supprimer l'action 'added' si elle existe déjà pour cette date et catégorie
          let updatedActions = [...prevActions];
          updatedActions.splice(addActionIndex, 1);
          return updatedActions;
        } else {
          // Sinon, ajouter une action 'removed'
          return [...prevActions, { date: dateStr, category, recurrence, action: 'removed' }];
        }
      });
    }
  };










  function handleRecurrenceToggle(startDate, datesMap, recurrence, selectedCategory, isAdding) {
    // Traitement spécifique pour la récurrence mensuelle.
    if (recurrence === "MONTHLY") {
      handleMonthlyRecurrence(startDate, datesMap, selectedCategory, isAdding);
      return;
    }

    // Calcul de l'incrément de jours pour les modes hebdomadaire et bihebdomadaire.
    let incrementDays = recurrence === "WEEKLY" ? 7 : (recurrence === "BIWEEKLY" ? 14 : 0);

    // Création de la date de début en UTC pour éviter les problèmes de fuseau horaire.
    let currentDate = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()));
    const endDate = new Date(Date.UTC(startDate.getUTCFullYear(), 11, 31)); // Fin de l'année courante.

    while (currentDate <= endDate) {
      // Formatage de la date actuelle pour l'utiliser comme clé dans datesMap.
      let dateStr = currentDate.toISOString().split('T')[0];

      let dateInfo = datesMap.get(dateStr) || { categories: new Map() };
      // let categoriesForDate = dateInfo.categories;
      let categoriesForDate = dateInfo.categories;
if (!(categoriesForDate instanceof Map)) {
    categoriesForDate = new Map();
}


      // Décision d'ajouter ou de supprimer la catégorie basée sur isAdding.
      if (isAdding) {
        categoriesForDate.set(selectedCategory, recurrence);
      } else {
        categoriesForDate.delete(selectedCategory);
        // Si aucune catégorie ne reste, supprime la date de la map.
        if (categoriesForDate.size === 0) {
          datesMap.delete(dateStr);
        }
      }

      // Mise à jour de datesMap seulement si des catégories restent.
      if (categoriesForDate.size > 0) {
        dateInfo.categories = categoriesForDate;
        datesMap.set(dateStr, dateInfo);
      }

      // Incrémente la date actuelle pour la prochaine récurrence.
      currentDate.setUTCDate(currentDate.getUTCDate() + incrementDays);
    }
  }




  function handleMonthlyRecurrence(startDate, datesMap, selectedCategory, isAdding) {
    for (let month = 0; month < 12; month++) {
      // Utilise la fonction getNthWeekdayOfMonth pour trouver la date correspondante chaque mois.
      let date = getNthWeekdayOfMonth(startDate.getUTCFullYear(), month, startDate.getUTCDay(), getWeekOfMonth(startDate));
      if (date) {
        const dateStr = date.toISOString().split('T')[0];

        let dateInfo = datesMap.get(dateStr) || { categories: new Map() };
        // let categoriesForDate = dateInfo.categories;
        let categoriesForDate = dateInfo.categories;
if (!(categoriesForDate instanceof Map)) {
    categoriesForDate = new Map();
}


        // Applique la même logique d'ajout ou de suppression que dans handleRecurrenceToggle.
        if (isAdding) {
          categoriesForDate.set(selectedCategory, "MONTHLY");
        } else {
          categoriesForDate.delete(selectedCategory);
          if (categoriesForDate.size === 0) {
            datesMap.delete(dateStr);
          }
        }

        // Mise à jour de datesMap seulement si des catégories restent.
        if (categoriesForDate.size > 0) {
          dateInfo.categories = categoriesForDate;
          datesMap.set(dateStr, dateInfo);
        }
      }
    }
  }



  function getNthWeekdayOfMonth(year, month, dayOfWeek, n) {
    let firstDayOfMonth = new Date(Date.UTC(year, month, 1));
    let day = firstDayOfMonth.getUTCDay();
    let date = 1 + ((7 + dayOfWeek - day) % 7);
    firstDayOfMonth.setUTCDate(date);

    // Move to the nth occurrence
    firstDayOfMonth.setUTCDate(firstDayOfMonth.getUTCDate() + (n - 1) * 7);
    if (firstDayOfMonth.getUTCMonth() === month) {
      return firstDayOfMonth;
    }

    return null; // The nth occurrence does not exist in this month
  }

  function getWeekOfMonth(date) {
    let firstDayOfMonth = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
    let firstWeekday = firstDayOfMonth.getUTCDay();

    let offsetDate = date.getUTCDate() + firstWeekday - 1; // Offset by the first day of the month being 1
    let index = 1; // Start at the first week

    // Add to index for each full week
    while (offsetDate >= 7) {
      offsetDate -= 7;
      index++;
    }

    return index;
  }





  const Month = ({ name, number, days, year, specialDays }) => {


    // ... le reste de votre composant Month

    return (
      <div className="month-container">
        <h2>{name}</h2>
        <div className="days-grid">
          {Array.from({ length: days }, (_, i) => {
            const dayNumber = i + 1;
            const dateStr = `${year}-${number.toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`;
            const dateKey = `${number.toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`;
            const dayOfWeek = getDayOfWeek(dateStr);
            const isWeekend = dayOfWeek === 'Samedi' || dayOfWeek === 'Dimanche';
            const daySpecial = specialDays[dateKey];

            return (
              <div
                key={dateStr}
                // className={`day ${isWeekend ? 'weekend' : ''} ${daySpecial ? 'special' : ''}`}
                className={`day ${isWeekend ? 'weekend' : ''} ${daySpecial ? 'special' : ''} ${selectedDates.has(dateStr) ? 'selected' : ''}`}
                onClick={() => {

                  toggleDate(dateStr)
                }}
              >
                <span className="day-of-week">{dayOfWeek.substring(0, 1)}</span>
                <span className="day-number">{dayNumber}</span>
                {/* {selectedDates.get(dateStr) && (
      <div className="categories">
        {Array.from(selectedDates.get(dateStr)).map((categoryId) => (
          <div key={categoryId} className="category">
            {categoriesActives[categoryId].nom}
          </div>
        ))}
      </div>
    )} */}

                {selectedDates.get(dateStr) && (
                  <div className="categories">
                  {Array.from(selectedDates.get(dateStr).categories).map(([categoryId, categoryName]) => {
                    // Trouver la catégorie correspondante par ID
                    const category = categoriesActives.find(c => c.id == categoryId);
                    return (
                      <div key={categoryId} className="category">
                       
                        {category ? category.nom : 'Catégorie inconnue'}
                      </div>
                    );
                  })}
                </div>
                
                )}


                {daySpecial && <div className="special-description">{daySpecial.description}</div>}

              </div>
            );
          })}
        </div>
      </div>
    );


  };

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }


  const months = useMemo(() => {
    const isLeap = isLeapYear(currentYear);
    return [
      { name: 'JANVIER', number: 1, days: 31 },
      { name: 'FÉVRIER', number: 2, days: isLeap ? 29 : 28 },
      { name: 'MARS', number: 3, days: 31 },
      { name: 'AVRIL', number: 4, days: 30 },
      { name: 'MAI', number: 5, days: 31 },
      { name: 'JUIN', number: 6, days: 30 },
      { name: 'JUILLET', number: 7, days: 31 },
      { name: 'AOÛT', number: 8, days: 31 },
      { name: 'SEPTEMBRE', number: 9, days: 30 },
      { name: 'OCTOBRE', number: 10, days: 31 },
      { name: 'NOVEMBRE', number: 11, days: 30 },
      { name: 'DÉCEMBRE', number: 12, days: 31 },
    ];


  }, [currentYear]);


  useEffect(() => {
    setSpecialDays(calculateSpecialDays(currentYear));
  }, [currentYear]);





  function calculateOccurrence(date) {
    // Récupérer le jour du mois
    const dayOfMonth = date.getDate();

    // Calculer l'occurrence du jour dans le mois
    const occurrence = Math.ceil(dayOfMonth / 7);

    return occurrence;
  }
  function planForNextYear() {
    const nextYear = currentYear + 1;



    console.log("annee : ", nextYear)

    // Supprimer toutes les dates de l'année suivante
    selectedDates.forEach((value, key) => {
      const date = new Date(key);
      if (date.getFullYear() === nextYear) {
        selectedDates.delete(key);
      }
    });

    clickActions.forEach(action => {
      // if (action.action === 'added') {
      const date = new Date(action.date);
      const occurrence = calculateOccurrence(date);
      const dayOfWeek = date.getDay();

      // Créer une nouvelle date pour l'année suivante au premier jour du mois
      const newDate = new Date(nextYear, date.getMonth(), 1);

      // Trouver le premier jour correspondant dans le mois pour l'année suivante
      let dayOfFirst = newDate.getDay();
      let daysToAdd = (7 + dayOfWeek - dayOfFirst) % 7;
      newDate.setDate(1 + daysToAdd + (occurrence - 1) * 7);

      // Vérifier si le nouveau jour est dans le bon mois
      if (newDate.getMonth() !== date.getMonth()) {
        console.error('La date calculée est hors du mois cible. Vérification requise.');
      } else {
        console.log(`Action pour ${action.date}:`);
        console.log(`Categories pour ${action.categorie}:`);
        console.log(`- Jour de la semaine: ${dayOfWeek} (0=dimanche, 1=lundi, ..., 6=samedi)`);
        console.log(`- Occurrence dans le mois: ${occurrence}`);
        // Formater la nouvelle date dans le format "YYYY-MM-DD"
        const formattedNewDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}`;
        console.log(`Nouvelle date pour l'année suivante: ${formattedNewDate}`);

        toggleDate2(formattedNewDate, action.recurrence, action.category, action.action)
      }
      // }

    });

    setCurrentYear(nextYear);
  }












  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleDeleteConfirmation = (type) => {
    setIsModalOpen(true);
    setModalContent(type); // 'all' pour toutes les dates, 'year' pour les dates de l'année
  };

  const confirmDeletion = () => {
    if (modalContent === 'all') {
      // Logique de suppression de toutes les dates
      setSelectedDates(new Map());
    } else if (modalContent === 'year') {
      // Logique de suppression des dates de l'année courante
      const filteredDates = new Map([...selectedDates].filter(([dateStr, value]) => {
        const dateYear = new Date(dateStr).getFullYear();
        return dateYear !== currentYear;
      }));
      setSelectedDates(filteredDates);
    }
    setIsModalOpen(false);
  };

  const yearRangeInfo = calculateYearRange(selectedDates);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      <h1 style={{ textAlign: 'center' }}>Sélectionnez les jours de passage</h1>
      <div style={{ marginBottom: '20px' }}>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={{
            padding: '10px',
            marginRight: '10px', // Pour séparer les contrôles
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '1rem',
            marginBottom: '20px',
          }}
        >
          <option value={null}>Sélectionnez une catégorie</option>
          {Object.values(categoriesActives).map((categorie) => (
            <option key={categorie.id} value={categorie.id}>{categorie.nom}</option>
          ))}
        </select>
        <label style={{ marginRight: '10px' }}>Année :</label>
        <input
          type="number"
          value={currentYear}
          onChange={handleYearChange}
          style={{
            padding: '10px',
            marginRight: '10px', // Pour séparer les contrôles
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '1rem'
          }}
        />
        <label style={{ marginRight: '10px' }}>Répétition :</label>
        <select
          value={recurrence}
          onChange={handleRecurrenceChange}
          style={{
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '1rem',
            cursor: 'pointer',
            marginRight: 10
          }}>
          {Object.entries(recurrenceOptions).map(([key, value]) => (
            <option key={key} value={key}>{value}</option>
          ))}
        </select>

        <button style={{ marginRight: '10px' }} onClick={planForNextYear}>Planifier pour l'année suivante</button>
        {/* <button style={{ marginRight: '10px' }} onClick={clearAllDates}>Supprimer toutes les dates</button>
        <button  style={{ marginRight: '10px' }} onClick={clearCurrentYearDates}>Supprimer les dates de cette année</button> */}
        <button style={{ marginRight: '10px' }} onClick={() => handleDeleteConfirmation('all')}>Supprimer toutes les dates</button>
        <button style={{ marginRight: '10px' }} onClick={() => handleDeleteConfirmation('year')}>Supprimer les dates de cette année</button>
        {yearRangeInfo.range > 1 && (
          <div style={{ textAlign: 'center', marginBottom: '20px', marginTop: '20px', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            Le calendrier couvre {yearRangeInfo.range} années de {yearRangeInfo.minYear} à {yearRangeInfo.maxYear}.
          </div>
        )}

      </div>


      <div className="calendar-container">
        {months.map((month) => (

          // <Month key={month.number} {...month} year={currentYear} specialDays={specialDays} />
          <Month key={month.number} {...month} year={currentYear} specialDays={specialDays} categoriesActives={categoriesActives} />


        ))}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles} // Appliquer les styles personnalisés
        contentLabel="Confirmation de suppression"
      >
        <h2>Confirmation</h2>
        <p>Êtes-vous sûr de vouloir supprimer {modalContent === 'all' ? 'toutes les dates' : 'les dates de cette année'} ?</p>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button onClick={confirmDeletion} style={{ marginRight: '10px' }}>Confirmer</button>
          <button onClick={() => setIsModalOpen(false)}>Annuler</button>
        </div>
      </Modal>
    </div>
  );


}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px', // Largeur du modal
    height: '200px', // Hauteur du modal, ajustez selon le contenu
    overflow: 'auto' // Ajoute un scroll si le contenu dépasse la hauteur
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)' // Assombrir légèrement l'arrière-plan
  }
};
export default CalendarCreateComponent;


