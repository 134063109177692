// // src/components/CollectiviteMap.js
// import React, { useEffect, useState, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import { MapContainer, TileLayer, GeoJSON, LayersControl } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import Autosuggest from 'react-autosuggest';
// import config from '../config';
// import './CollectiviteMap.css';

// const CollectiviteMap = () => {
//   const { collectiviteId } = useParams(); // Récupérez l'ID de la collectivité à partir des paramètres
//   const [geojsonData, setGeojsonData] = useState(null);
//   const [departements, setDepartements] = useState(['22', '29', '56', '35', '44']);
//   const [selectedCommunes, setSelectedCommunes] = useState(new Map());
//   const [currentDepartement, setCurrentDepartement] = useState('22');
//   const [communesSuggestions, setCommunesSuggestions] = useState([]);
//   const [filteredSuggestions, setFilteredSuggestions] = useState([]);
//   const [searchValue, setSearchValue] = useState('');
//   const mapRef = useRef(null);
//   const geoJsonLayerRef = useRef(null);

//   const fetchCommunes = async (departements) => {
//     try {
//       console.log('test fetchCommunes')
//       console.log(departements)
//       const response = await fetch(`${config.domain}/api/get_communes`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({departements}),
//       });
//       const result = await response.json();
//       const geojson = JSON.parse(result);
//       setGeojsonData(geojson);

//       setCommunesSuggestions(geojson.features.map((feature) => ({
//         name: feature.properties['Nom Officiel Commune'],
//         codeInsee: feature.properties['Code Officiel Commune'],
//         numeroDepartement: feature.properties['Code Officiel Département'],
//         nomDepartement: feature.properties['Nom Officiel Département'],
//         bounds: L.geoJSON(feature).getBounds(),
//       })));
//     } catch (error) {
//       console.error('Error fetching GeoJSON data:', error);
//     }
//   };

//   const fetchSelectedCommunes = async () => {
//     try {
//       const response = await fetch(`${config.domain}/api/collectivite/${collectiviteId}/communes`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       if (response.ok) {
//         const result = await response.json();
//         const communes = result.communes;
//         const newSelectedCommunes = new Map();
//         communes.forEach((commune) => {
//           newSelectedCommunes.set(commune.nom, commune);
//         });
//         setSelectedCommunes(newSelectedCommunes);
//       } else {
//         console.error('Error fetching selected communes');
//       }
//     } catch (error) {
//       console.error('Error fetching selected communes:', error);
//     }
//   };

//   const highlightFeature = (layer) => {
//     layer.setStyle({
//       weight: 5,
//       color: '#666',
//       dashArray: '',
//       fillOpacity: 0.7,
//     });
//   };

//   const normalFeature = (layer) => {
//     layer.setStyle({
//       weight: 1,
//       color: '#3388ff',
//       dashArray: '3',
//       fillOpacity: 0.2,
//     });
//   };

//   const toggleSelection = (commune, layer) => {
//     setSelectedCommunes((prev) => {
//       const updated = new Map(prev);
//       if (updated.has(commune.nom)) {
//         normalFeature(layer);
//         updated.delete(commune.nom);
//       } else {
//         highlightFeature(layer);
//         updated.set(commune.nom, {
//           nom: commune.nom,
//           code_insee: commune.codeInsee,
//           code_postal: commune.codePostal,
//           numero_departement: commune.numeroDepartement,
//           nom_departement: commune.nomDepartement,
//         });
//       }
//       return updated;
//     });
//   };

//   const selectCommuneByName = (name) => {
//     if (geoJsonLayerRef.current) {
//       geoJsonLayerRef.current.eachLayer((layer) => {
//         const properties = layer.feature.properties;
//         if (properties['Nom Officiel Commune'] === name) {
//           const commune = {
//             nom: properties['Nom Officiel Commune'],
//             codeInsee: properties['Code Officiel Commune'],
//             numeroDepartement: properties['Code Officiel Département'],
//             nomDepartement: properties['Nom Officiel Département'],
//           };
//           toggleSelection(commune, layer);
//         }
//       });
//     }
//   };

//   const styleFeature = (feature) => {
//     const communeName = feature.properties['Nom Officiel Commune'];
//     if (selectedCommunes.has(communeName)) {
//       return {
//         weight: 5,
//         color: '#666',
//         dashArray: '',
//         fillOpacity: 0.7,
//       };
//     } else {
//       return {
//         weight: 1,
//         color: '#3388ff',
//         dashArray: '3',
//         fillOpacity: 0.2,
//       };
//     }
//   };

//   const onEachFeature = (feature, layer) => {
//     const commune = {
//       nom: feature.properties['Nom Officiel Commune'],
//       codeInsee: feature.properties['Code Officiel Commune'],
//       numeroDepartement: feature.properties['Code Officiel Département'],
//       nomDepartement: feature.properties['Nom Officiel Département'],
//     };
//     layer.bindPopup(commune.nom);

//     layer.on('click', () => {
//       toggleSelection(commune, layer);
//     });

//     layer.on('add', () => {
//       const center = layer.getBounds().getCenter();
//       const label = L.divIcon({
//         className: 'commune-label',
//         html: `<span>${commune.nom}</span>`,
//       });
//       L.marker(center, { icon: label, interactive: false }).addTo(layer._map);
//     });
//   };

//   const onSuggestionsFetchRequested = ({ value }) => {
//     const inputValue = value.trim().toLowerCase();
//     const inputLength = inputValue.length;

//     const suggestions = inputLength === 0 ? [] : communesSuggestions.filter(
//       (commune) => commune.name.toLowerCase().includes(inputValue)
//     );

//     setFilteredSuggestions(suggestions);
//   };

//   const onSuggestionsClearRequested = () => {
//     setFilteredSuggestions([]);
//   };

//   const getSuggestionValue = (suggestion) => suggestion.name;

//   const renderSuggestion = (suggestion) => (
//     <div>{suggestion.name}</div>
//   );

//   const onSuggestionSelected = (event, { suggestion }) => {
//     setSearchValue(suggestion.name);
//     if (mapRef.current) {
//       mapRef.current.flyToBounds(suggestion.bounds);
//     }
//     selectCommuneByName(suggestion.name);
//   };

//   const sendSelectedCommunes = async () => {
//     const selected = [...selectedCommunes.values()];
//     try {
//       const response = await fetch(`${config.domain}/api/collectivites/send_selected_communes`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           collectivite_id: collectiviteId,
//           communes: selected,
//         }),
//       });
//       if (response.ok) {
//         alert('Communes envoyées avec succès !');
//       } else {
//         alert('Erreur lors de l\'envoi des communes.');
//       }
//     } catch (error) {
//       console.error('Error sending selected communes:', error);
//       alert('Erreur lors de l\'envoi des communes.');
//     }
//   };

//   useEffect(() => {
//     fetchCommunes([currentDepartement]);
//     fetchSelectedCommunes();
//   }, [currentDepartement, collectiviteId]);

//   return (
//     <div>
//       <h2>Carte des Communes pour la Collectivité {collectiviteId}</h2>
//       <div>
//         <label htmlFor="departementSelect">Sélectionner un département : </label>
//         <select
//           id="departementSelect"
//           value={currentDepartement}
//           onChange={(e) => setCurrentDepartement(e.target.value)}
//         >
//           {departements.map((dep) => (
//             <option key={dep} value={dep}>
//               {dep}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div>
//         <label htmlFor="communeSearch">Rechercher une commune :</label>
//         <Autosuggest
//           suggestions={filteredSuggestions}
//           onSuggestionsFetchRequested={onSuggestionsFetchRequested}
//           onSuggestionsClearRequested={onSuggestionsClearRequested}
//           getSuggestionValue={getSuggestionValue}
//           renderSuggestion={renderSuggestion}
//           inputProps={{
//             placeholder: 'Commune...',
//             value: searchValue,
//             onChange: (e, { newValue }) => setSearchValue(newValue),
//           }}
//           onSuggestionSelected={onSuggestionSelected}
//         />
//       </div>

//       <MapContainer
//         center={[48.5149, -3.1786]} // Coordonnées initiales de la carte
//         zoom={9}
//         style={{ height: '500px', width: '100%' }}
//         ref={(map) => {
//           mapRef.current = map;
//         }}
//       >
//         <LayersControl position="topright">
//           <LayersControl.BaseLayer checked name="Carte Standard">
//             <TileLayer
//               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//               attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//             />
//           </LayersControl.BaseLayer>

//           {geojsonData && (
//             <GeoJSON
//               data={geojsonData}
//               onEachFeature={onEachFeature}
//               style={styleFeature}
//               ref={(geoJsonLayer) => {
//                 geoJsonLayerRef.current = geoJsonLayer;
//               }}
//             />
//           )}
//         </LayersControl>
//       </MapContainer>

//       <div>
//         <h3>Communes sélectionnées:</h3>
//         <ul>
//           {[...selectedCommunes.values()].map((commune) => (
//             <li key={commune.nom}>{commune.nom}</li>
//           ))}
//         </ul>
//         <button onClick={sendSelectedCommunes} style={{ marginTop: '10px', padding: '10px 15px', cursor: 'pointer' }}>
//           Envoyer les Communes
//         </button>
//       </div>

//       <style>{`
//         .commune-label {
//           color: red;
//           font-weight: bold;
//           text-shadow: 0px 0px 5px white;
//           font-size: 14px;
//         }

//         .react-autosuggest__container {
//           position: relative;
//           z-index: 2000; /* Élevez le z-index du conteneur parent */
//         }

//         .react-autosuggest__input {
//           width: 100%;
//           height: 40px;
//           padding: 10px;
//           font-size: 16px;
//           border: 1px solid #aaa;
//           border-radius: 4px;
//         }

//         .react-autosuggest__suggestions-container {
//           display: none;
//           position: absolute;
//           top: 45px;
//           width: 100%;
//           border: 1px solid #aaa;
//           background-color: white;
//           z-index: 20; /* Assurez-vous que les suggestions apparaissent au-dessus de la carte */
//         }

//         .react-autosuggest__suggestions-container--open {
//           display: block;
//         }

//         .react-autosuggest__suggestions-list {
//           margin: 0;
//           padding: 0;
//           list-style-type: none;
//         }

//         .react-autosuggest__suggestion {
//           padding: 10px;
//           border-bottom: 1px solid #aaa;
//           cursor: pointer;
//         }

//         .react-autosuggest__suggestion--highlighted {
//           background-color: #ddd;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default CollectiviteMap;


import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, GeoJSON, LayersControl, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Autosuggest from 'react-autosuggest';
import config from '../config';
import './CollectiviteMap.css';

const CollectiviteMap = () => {
  const { collectiviteId } = useParams();
  const [geojsonData, setGeojsonData] = useState(null);
  const [departements, setDepartements] = useState(['22', '29', '56', '35', '44']);
  const [selectedCommunes, setSelectedCommunes] = useState(new Map());
  const [currentDepartement, setCurrentDepartement] = useState('22');
  const [communesSuggestions, setCommunesSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const mapRef = useRef(null);
  const geoJsonLayerRef = useRef(null);
  const communeLabelsLayerRef = useRef(null); // Ref pour le LayerGroup des labels

  const fetchCommunes = async (departements) => {
    try {
      const response = await fetch(`${config.domain}/api/get_communes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ departements }),
      });
      const result = await response.json();
      const geojson = JSON.parse(result);
      setGeojsonData(geojson);

      setCommunesSuggestions(geojson.features.map((feature) => ({
        name: feature.properties['Nom Officiel Commune'],
        codeInsee: feature.properties['Code Officiel Commune'],
        numeroDepartement: feature.properties['Code Officiel Département'],
        nomDepartement: feature.properties['Nom Officiel Département'],
        bounds: L.geoJSON(feature).getBounds(),
      })));
    } catch (error) {
      console.error('Error fetching GeoJSON data:', error);
    }
  };

  const fetchSelectedCommunes = async () => {
    try {
      const response = await fetch(`${config.domain}/api/collectivite/${collectiviteId}/communes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const result = await response.json();
        const communes = result.communes;
        const newSelectedCommunes = new Map();
        communes.forEach((commune) => {
          newSelectedCommunes.set(commune.nom, commune);
        });
        setSelectedCommunes(newSelectedCommunes);
      } else {
        console.error('Error fetching selected communes');
      }
    } catch (error) {
      console.error('Error fetching selected communes:', error);
    }
  };

  const highlightFeature = (layer) => {
    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7,
    });
  };

  const normalFeature = (layer) => {
    layer.setStyle({
      weight: 1,
      color: '#3388ff',
      dashArray: '3',
      fillOpacity: 0.2,
    });
  };

  const toggleSelection = (commune, layer) => {
    setSelectedCommunes((prev) => {
      const updated = new Map(prev);
      if (updated.has(commune.nom)) {
        normalFeature(layer);
        updated.delete(commune.nom);
      } else {
        highlightFeature(layer);
        updated.set(commune.nom, {
          nom: commune.nom,
          code_insee: commune.codeInsee,
          code_postal: commune.codePostal,
          numero_departement: commune.numeroDepartement,
          nom_departement: commune.nomDepartement,
        });
      }
      return updated;
    });
  };

  const selectCommuneByName = (name) => {
    if (geoJsonLayerRef.current) {
      geoJsonLayerRef.current.eachLayer((layer) => {
        const properties = layer.feature.properties;
        if (properties['Nom Officiel Commune'] === name) {
          const commune = {
            nom: properties['Nom Officiel Commune'],
            codeInsee: properties['Code Officiel Commune'],
            numeroDepartement: properties['Code Officiel Département'],
            nomDepartement: properties['Nom Officiel Département'],
          };
          toggleSelection(commune, layer);
        }
      });
    }
  };

  const styleFeature = (feature) => {
    const communeName = feature.properties['Nom Officiel Commune'];
    if (selectedCommunes.has(communeName)) {
      return {
        weight: 5,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7,
      };
    } else {
      return {
        weight: 1,
        color: '#3388ff',
        dashArray: '3',
        fillOpacity: 0.2,
      };
    }
  };

  const onEachFeature = (feature, layer) => {
    const commune = {
      nom: feature.properties['Nom Officiel Commune'],
      codeInsee: feature.properties['Code Officiel Commune'],
      numeroDepartement: feature.properties['Code Officiel Département'],
      nomDepartement: feature.properties['Nom Officiel Département'],
    };
    layer.bindPopup(commune.nom);

    layer.on('click', () => {
      toggleSelection(commune, layer);
    });

    const center = layer.getBounds().getCenter();
    const label = L.divIcon({
      className: 'commune-label',
      html: `<span>${commune.nom}</span>`,
    });
    const marker = L.marker(center, { icon: label, interactive: false });
    communeLabelsLayerRef.current.addLayer(marker); // Ajoute le label au LayerGroup
  };

  const clearCommuneLabels = () => {
    if (communeLabelsLayerRef.current) {
      communeLabelsLayerRef.current.clearLayers(); // Supprime tous les labels du LayerGroup
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const suggestions = inputLength === 0 ? [] : communesSuggestions.filter(
      (commune) => commune.name.toLowerCase().includes(inputValue)
    );

    setFilteredSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setFilteredSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => (
    <div>{suggestion.name}</div>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    setSearchValue(suggestion.name);
    if (mapRef.current) {
      mapRef.current.flyToBounds(suggestion.bounds);
    }
    selectCommuneByName(suggestion.name);
  };

  const sendSelectedCommunes = async () => {
    const selected = [...selectedCommunes.values()];
    try {
      const response = await fetch(`${config.domain}/api/collectivites/send_selected_communes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          collectivite_id: collectiviteId,
          communes: selected,
        }),
      });
      if (response.ok) {
        alert('Communes envoyées avec succès !');
      } else {
        alert('Erreur lors de l\'envoi des communes.');
      }
    } catch (error) {
      console.error('Error sending selected communes:', error);
      alert('Erreur lors de l\'envoi des communes.');
    }
  };

  useEffect(() => {
    fetchCommunes([currentDepartement]);
    fetchSelectedCommunes();
  }, [currentDepartement, collectiviteId]);

  useEffect(() => {
    if (geoJsonLayerRef.current) {
      geoJsonLayerRef.current.clearLayers(); // Clear existing layers
      clearCommuneLabels(); // Clear existing labels
      if (geojsonData) {
        geoJsonLayerRef.current.addData(geojsonData); // Add new data
      }
    }
  }, [geojsonData]);

  const handleDepartementChange = (e) => {
    setCurrentDepartement(e.target.value);
    clearCommuneLabels(); // Clear labels when department changes
    fetchCommunes([e.target.value]);
  };

  return (
    <div>
      <h2>Carte des Communes pour la Collectivité {collectiviteId}</h2>
      <div>
        <label htmlFor="departementSelect">Sélectionner un département : </label>
        <select
          id="departementSelect"
          value={currentDepartement}
          onChange={handleDepartementChange}
        >
          {departements.map((dep) => (
            <option key={dep} value={dep}>
              {dep}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="communeSearch">Rechercher une commune :</label>
        <Autosuggest
          suggestions={filteredSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder: 'Commune...',
            value: searchValue,
            onChange: (e, { newValue }) => setSearchValue(newValue),
          }}
          onSuggestionSelected={onSuggestionSelected}
        />
      </div>

      <MapContainer
        center={[48.5149, -3.1786]} // Coordonnées initiales de la carte
        zoom={9}
        style={{ height: '500px', width: '100%' }}
        whenCreated={(map) => {
          mapRef.current = map;
        }}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Carte Standard">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>

          <LayersControl.Overlay checked name="Communes">
            <GeoJSON
              key={currentDepartement} // Forcer le démontage/remontage du composant
              data={geojsonData}
              onEachFeature={onEachFeature}
              style={styleFeature}
              ref={geoJsonLayerRef}
            />
          </LayersControl.Overlay>

          <LayerGroup ref={communeLabelsLayerRef} />
        </LayersControl>
      </MapContainer>

      <div>
        <h3>Communes sélectionnées:</h3>
        <ul>
          {[...selectedCommunes.values()].map((commune) => (
            <li key={commune.nom}>{commune.nom}</li>
          ))}
        </ul>
        <button onClick={sendSelectedCommunes} style={{ marginTop: '10px', padding: '10px 15px', cursor: 'pointer' }}>
          Envoyer les Communes
        </button>
      </div>

      <style>{`
        .commune-label {
          color: red;
          font-weight: bold;
          text-shadow: 0px 0px 5px white;
          font-size: 14px;
        }

        .react-autosuggest__container {
          position: relative;
          z-index: 2000; /* Élevez le z-index du conteneur parent */
        }

        .react-autosuggest__input {
          width: 100%;
          height: 40px;
          padding: 10px;
          font-size: 16px;
          border: 1px solid #aaa;
          border-radius: 4px;
        }

        .react-autosuggest__suggestions-container {
          display: none;
          position: absolute;
          top: 45px;
          width: 100%;
          border: 1px solid #aaa;
          background-color: white;
          z-index: 20; /* Assurez-vous que les suggestions apparaissent au-dessus de la carte */
        }

        .react-autosuggest__suggestions-container--open {
          display: block;
        }

        .react-autosuggest__suggestions-list {
          margin: 0;
          padding: 0;
          list-style-type: none;
        }

        .react-autosuggest__suggestion {
          padding: 10px;
          border-bottom: 1px solid #aaa;
          cursor: pointer;
        }

        .react-autosuggest__suggestion--highlighted {
          background-color: #ddd;
        }
      `}</style>
    </div>
  );
};

export default CollectiviteMap;









