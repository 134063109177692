import React, { useState, useEffect } from 'react';
import './GuideDuTri.css';
import config from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from 'react-modal';

const GuideDuTri = () => {
  const [categories, setCategories] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [recyclingInfo, setRecyclingInfo] = useState('');
  const [collectiviteId, setCollectiviteId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCollectiviteID = async () => {
    const id = await AsyncStorage.getItem('selectedCollectiviteId');
    setCollectiviteId(id || '');
  };

  useEffect(() => {
    fetchCollectiviteID();
    if (collectiviteId) {
      fetchCategories();
    }
  }, [collectiviteId]);

  // const fetchCategories = async () => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const response = await fetch(`${config.domain}/api/categories_by_collectivite_product?collectivite_id=${collectiviteId}`);
  //     const data = await response.json();
  //     if (Array.isArray(data)) {
  //       // Filtrer les catégories sans sous-catégories
  //       const filteredCategories = data.filter(category => category.subcategories.length === 0);
  //       setCategories(filteredCategories);
  //     } else {
  //       setError('Invalid data format');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching categories:', error);
  //     setError('Error fetching categories');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchCategories = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log(`${config.domain}/api/categories_by_collectivite_product?collectivite_id=${collectiviteId}`)
      const response = await fetch(`${config.domain}/api/categories_by_collectivite_product?collectivite_id=${collectiviteId}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        const filteredCategories = data.filter(category => category.subcategories.length === 0);
        setCategories(filteredCategories);
      } else {
        setError('Invalid data format');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Error fetching categories');
    } finally {
      setLoading(false);
    }
  };
  

  const openModal = (category) => {
    setCurrentCategory(category.name);
    setCurrentCategoryId(category.id);
    setRecyclingInfo(category.description || ''); // Afficher la description de la catégorie si disponible
    setCurrentProducts(category.products.map(product => ({
      id: product.id,
      name: product.name,
      description: product.description || '',
      photo: product.photo || null
    }))); // Initialiser avec les produits (incluant leurs descriptions et photos)
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentCategory('');
    setCurrentCategoryId(null);
    setCurrentProducts([]);
    setRecyclingInfo('');
  };

  const handleProductChange = (e, index, field) => {
    const newProducts = [...currentProducts];
    if (field === 'photo') {
      newProducts[index] = { ...newProducts[index], [field]: e.target.files[0] };
    } else {
      newProducts[index] = { ...newProducts[index], [field]: e.target.value };
    }
    setCurrentProducts(newProducts);
  };

  const addProductField = () => {
    setCurrentProducts([...currentProducts, { id: null, name: '', description: '', photo: null }]);
  };

  const deleteProduct = async (index, productId) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce produit ?')) {
      if (productId === null) {
        // Si le produit n'a pas encore été enregistré, supprimer simplement de l'état local
        const newProducts = currentProducts.filter((_, i) => i !== index);
        setCurrentProducts(newProducts);
        alert('Produit supprimé localement');
      } else {
        // Si le produit a un ID, faire l'appel au backend pour le supprimer
        try {
          const response = await fetch(`${config.domain}/api/delete_product/${productId}`, {
            method: 'DELETE',
          });
          if (response.ok) {
            const newProducts = currentProducts.filter((_, i) => i !== index);
            setCurrentProducts(newProducts);
            alert('Produit supprimé avec succès');
          } else {
            alert('Erreur lors de la suppression du produit');
          }
        } catch (error) {
          console.error('Error deleting product:', error);
          alert('Erreur lors de la suppression du produit');
        }
      }
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append('trash_type_id', currentCategoryId);
  //   formData.append('description', recyclingInfo); // Ajouter la description de la catégorie au payload
  //   formData.append('products', JSON.stringify(currentProducts.map(({ id, name, description }) => ({ id, name, description }))));

  //   currentProducts.forEach((product, index) => {
  //     if (product.photo instanceof File) {
  //       formData.append('photos', product.photo);
  //     }
  //   });

  //   try {
  //     const response = await fetch(`${config.domain}/api/add_products_to_trashtype`, {
  //       method: 'POST',
  //       body: formData,
  //     });
  //     if (response.ok) {
  //       alert('Informations enregistrées avec succès');
  //       closeModal();
  //       fetchCategories(); // Rafraîchir les catégories après l'enregistrement
  //     } else {
  //       alert('Erreur lors de l\'enregistrement des informations');
  //     }
  //   } catch (error) {
  //     console.error('Error saving recycling information:', error);
  //     alert('Erreur lors de l\'enregistrement des informations');
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('trash_type_id', currentCategoryId);
    formData.append('collectivite_id', collectiviteId);  // Ajouter l'ID de la collectivité
    formData.append('description', recyclingInfo);
    formData.append('products', JSON.stringify(currentProducts.map(({ id, name, description }) => ({ id, name, description }))));
  
    currentProducts.forEach((product, index) => {
      if (product.photo instanceof File) {
        formData.append('photos', product.photo);
      }
    });
  
    try {
      const response = await fetch(`${config.domain}/api/add_products_to_trashtype`, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        alert('Informations enregistrées avec succès');
        closeModal();
        fetchCategories();
      } else {
        alert('Erreur lors de l\'enregistrement des informations');
      }
    } catch (error) {
      console.error('Error saving recycling information:', error);
      alert('Erreur lors de l\'enregistrement des informations');
    }
  };
  


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="guide-du-tri">
      <h1>Guide du Tri</h1>
      <p>
        Bienvenue sur la page de gestion du Guide du Tri. Ici, vous pouvez voir les différentes catégories de collecte 
        et ajouter des informations ainsi que des produits qui font partie de chaque catégorie. Pour commencer, cliquez 
        sur une catégorie ci-dessous pour ajouter ou modifier des informations de recyclage et des produits associés.
      </p>
      <div className="categories">
        {categories.map((category) => (
          <div key={category.id} className="card" onClick={() => openModal(category)}>
            <h3>{category.name}</h3>
          </div>
        ))}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Recycling Information">
        <div className="modal-content">
          <h2>{currentCategory}</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Informations sur le recyclage:
              <textarea value={recyclingInfo} onChange={(e) => setRecyclingInfo(e.target.value)} />
            </label>
            <h3>Produits associés</h3>
            <div className="product-grid">
              {currentProducts.map((product, index) => (
                <div key={index} className="product-card">
                  {product.photo && (
                    <img
                      src={product.photo instanceof File ? URL.createObjectURL(product.photo) : product.photo}
                      alt={`Photo of ${product.name}`}
                    />
                  )}
                  <input
                    type="text"
                    value={product.name}
                    onChange={(e) => handleProductChange(e, index, 'name')}
                    placeholder="Nom du produit"
                  />
                  <textarea
                    value={product.description}
                    onChange={(e) => handleProductChange(e, index, 'description')}
                    placeholder="Description du produit"
                  />
                  <input
                    type="file"
                    onChange={(e) => handleProductChange(e, index, 'photo')}
                    placeholder="Photo du produit"
                  />
                  <button type="button" onClick={() => deleteProduct(index, product.id)}>Supprimer</button>
                </div>
              ))}
            </div>
            <button type="button" onClick={addProductField}>Ajouter un produit</button>
            <button type="submit">Enregistrer</button>
          </form>
          <button onClick={closeModal}>Fermer</button>
        </div>
      </Modal>
    </div>
  );
};

export default GuideDuTri;
