import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid, Container, CardActionArea , Checkbox , Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useAuth } from '../../auth/AuthContext'; // Assurez-vous que le chemin est correct

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../../config';
function TrajetsListe({ onTrajetSelectionne , setdepartement , setNomTrajetSelect , trajets , setTrajets}) {
    // const [trajets, setTrajets] = useState([]);
    const [selectedTrajetId, setSelectedTrajetId] = useState(null); // État pour le trajet sélectionné
    const [page, setPage] = useState(1);
    const trajetsParPage = 6;
    const { currentUser } = useAuth();
    const [collectiviteId, setCollectiviteId] = useState('');
    const [selectedTrajet, setSelectedTrajet] = useState(new Set());


    useEffect(() => {
        const fetchCollectiviteID = async () => {
          const collectiviteId = await AsyncStorage.getItem('selectedCollectiviteId');
          setCollectiviteId(collectiviteId);
        };
    
        fetchCollectiviteID();
      }, []);
    

    

    useEffect(() => {

      if(collectiviteId ){
        const fetchTrajets = async () => {

            try {
                console.log(`${config.domain}/api/obtenir_trajets?iduser=${currentUser.iduser}&collectivite_id=${collectiviteId}`)
                const response = await axios.get(`${config.domain}/api/obtenir_trajets?iduser=${currentUser.iduser}&collectivite_id=${collectiviteId}`);
                setTrajets(response.data);
                
            } catch (error) {
                console.error('Erreur lors de la récupération des tournée:', error);
            }
        };

        fetchTrajets();

      }
    }, [collectiviteId]);

    const handleDeleteTrajet = async (trajetId) => {
        try {
            await axios.delete(`h${config.domain}/api/supprimer_trajet/${trajetId}`);
            // Filtrer le trajet supprimé de l'état
            const updatedTrajets = trajets.filter(trajet => trajet.id !== trajetId);
            setTrajets(updatedTrajets);

        } catch (error) {
            console.error('Erreur lors de la suppression du trajet:', error);
        }
    };
    

    const nombrePages = Math.ceil(trajets.length / trajetsParPage);
    const trajetsActuels = trajets.slice((page - 1) * trajetsParPage, page * trajetsParPage);

    // Fonction pour gérer la sélection d'un trajet
    const handleSelectTrajet = (trajetId,departement,nom) => {
        setSelectedTrajetId(trajetId);
        setNomTrajetSelect(nom)
        if (onTrajetSelectionne) onTrajetSelectionne(trajetId);

        setdepartement(departement)
    };


    const handleCheckboxChange = (event, id) => {

       
        const newSet = new Set(selectedTrajet);
        if (event.target.checked) {
          newSet.add(id);
        } else {
          newSet.delete(id);
        }
        setSelectedTrajet(newSet);
      };

      
    const handleAssociateClick = () => {
        // Ici, vous pouvez envoyer les ID des tournées sélectionnées à votre backend
        // pour les associer à une collectivité, par exemple
        const trajet_ids = Array.from(selectedTrajet);
        
        // Vérifier si des tournées sont sélectionnées
        if (trajet_ids.length === 0) {
          alert("Veuillez sélectionner au moins une tournée à associer.");
          return;
        }
    
        console.log(`${config.domain}/api/collectivites/${collectiviteId}/trajets`)
      console.log(trajet_ids)
        // Ici, vous pouvez envoyer les ID des tournées sélectionnées à votre backend
        // pour les associer à une collectivité, par exemple
        fetch(`${config.domain}api/collectivites/${collectiviteId}/trajets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            trajet_ids,
          }),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Erreur lors de l\'association des tournées.');
          }
          alert('Tournées associées avec succès à la collectivité.');
          selectedTrajet(new Set()); // Réinitialiser les tournées sélectionnées après l'association
        })
        .catch(error => {
          console.error('Erreur lors de l\'association des tournées :', error);
          alert('Une erreur est survenue lors de l\'association des tournées.');
        });
      };

    return (
        <Container style={{ marginTop: 20, marginBottom: 20, maxWidth: 'lg' }}>
             <Button
        variant="contained"
        color="secondary"
        onClick={handleAssociateClick}
        style={{ marginBottom: '20px' }}
      >
        Associer les calendriers sélectionnés
      </Button>
            <Grid container spacing={3} justify="center">
                {trajetsActuels.map(trajet => (
                    <Grid item key={trajet.id} xs={12} sm={6} md={4}>
                                    <Checkbox
                  checked={selectedTrajet.has(trajet.id)}
                  onChange={e => handleCheckboxChange(e, trajet.id)}
                  inputProps={{ 'aria-label': 'Select trajet' }}
                />
                        <Card 
                            onClick={() => handleSelectTrajet(trajet.id,trajet.departements,trajet.nom)} 
                            style={{ 
                                cursor: 'pointer', 
                                backgroundColor: selectedTrajetId === trajet.id ? '#cfe8fc' : 'white' // Change la couleur de fond si le trajet est sélectionné
                            }}
                        >
                            <CardActionArea>
                                <CardContent>
                    
                                    <Typography color="textSecondary" gutterBottom>
                                        Tournée ID: {trajet.id}
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        {trajet.nom}
                                    </Typography>
                                    {/* Plus de détails ici si nécessaire */}
                                    <IconButton onClick={(e) => {
        e.stopPropagation(); // Prévenir le déclenchement de l'événement de sélection du trajet
        handleDeleteTrajet(trajet.id);
    }}>
        <DeleteIcon /> {/* Assurez-vous que l'icône de suppression est correctement importée */}
    </IconButton>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Pagination count={nombrePages} page={page} onChange={(e, value) => setPage(value)} />
                </Grid>
            </Grid>
        </Container>
    );
}

export default TrajetsListe;
