// src/components/CreateTripPage.js
import React, { useState } from 'react';
import { useAuth } from '../auth/AuthContext'; // Assurez-vous que le chemin est correct
import TrajetsListe from '../components/trajet/TrajetsListe';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { toast } from 'react-toastify';
import MyMap from '../components/adresse/MyMap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import config from '../config';

function CreateTripPage() {
  const { currentUser } = useAuth(); // Accédez aux informations de l'utilisateur actuel
  const [trip, setTrip] = useState({
    departure: '',
    destination: '',
    date: '',
    time: ''
  });

  const [nomTrajet, setNomTrajet] = useState("");
  const [selectedTrajetId, setSelectedTrajetId] = useState(null);
  const [departemenent, setdepartement] = useState([]);
  const [showMap, setShowMap] = useState(false);


  const [trajetEnregistre, setTrajetEnregistre] = useState(true);



  

  const handleEnregistrerTrajet = async () => {
    try {
      const response = await axios.post(`${config.domain}/api/enregistrer_trajet`, { 
        nom: nomTrajet, 
        iduser: currentUser.iduser
      });
      if (response.data && response.data.id) {
        setSelectedTrajetId(response.data.id); // Stocke l'ID du trajet pour une utilisation future
        setTrajetEnregistre(true);
        console.log("Trajet enregistré avec succès, ID:", response.data.id);
        toast.success('Trajet enregistré avec succès');
  
        // Recharger la page après un court délai pour voir les changements
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Attendre 2 secondes avant de recharger pour permettre au toast de s'afficher
  
      } else {
        // Gérer l'absence de retour d'ID ou les erreurs
        console.error("Le trajet n'a pas pu être enregistré correctement.");
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du trajet:', error);
      toast.error('Erreur lors de l\'enregistrement du trajet');
    }
  };
  
  
  const enregistrerCommune = async (communeInfo, communeEntiere, trajetId) => {
    if (!trajetId) {
      console.error("Erreur : Aucun trajet n'est enregistré.");
      toast.error('Erreur : Aucun trajet n\'est enregistré.');
      return;
    }
  
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Enregistrement de la commune en cours...");
  
    try {
      await axios.post(`${config.domain}/api/enregistrer_commune`, {
        trajet_id: trajetId,
        commune_entiere: communeEntiere,
        commune: communeInfo
      });
      console.log("Commune enregistrée avec succès.");
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
      // Affiche un toast de succès
      toast.success('Commune enregistrée avec succès');
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      console.error('Erreur lors de l\'enregistrement de la commune:', error);
      // Affiche un toast d'erreur
      toast.error('Erreur lors de l\'enregistrement de la commune');
    }
  };
  

  const enregistrerRues = async (rues, trajetId) => {
    if (!trajetId) {
      console.error("Erreur : Aucun trajet n'est enregistré.");
      toast.error("Erreur : Aucun trajet n'est enregistré.");
      return;
    }
  
    console.log('Adresse envoyée : ', rues);
  
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Enregistrement des rues en cours...");
  
    try {
      await axios.post(`${config.domain}/api/enregistrer_rues`, {
        trajet_id: trajetId,
        rues: rues
      });
      console.log("Rues enregistrées avec succès.");
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
      // Affiche un toast de succès
      toast.success('Rues enregistrées avec succès.');
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      // Axios enveloppe l'erreur dans `error.response`
      const errorMessage = error.response?.data?.erreur || "Erreur inconnue lors de l'enregistrement des rues.";
      console.error(errorMessage);
      
      // Affiche un toast d'erreur
      toast.error(errorMessage);
    }
  };
  

  const handleTrajetSelection = (trajetId) => {
    setSelectedTrajetId(trajetId);
    setShowMap(true); // Affiche la carte lorsque un trajet est sélectionné
};


const test = () => {
  toast.success('Communes enregistrées avec succès');

}

  // Le reste du composant reste inchangé...

  return (
    <div style={{ margin: 'auto', padding: '20px' }}>
      {/* <h2>Créer un nouveau trajet</h2> */}
      {/* Affichez l'ID de l'utilisateur si disponible */}
      {/* {currentUser && <p>ID de l'utilisateur : {currentUser.iduser}</p>} */}
      


            {/* <button onClick={() => test()}>test</button> */}
        

         <MyMap onEnregistrerCommune={enregistrerCommune}  onEnregistrerRue={enregistrerRues} trajetId={selectedTrajetId} departements={departemenent}  />
            <div style={{marginTop : 50}}></div>
        
   
            <ToastContainer />
</div>
    
  );
}

export default CreateTripPage;

