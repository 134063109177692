

import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, GeoJSON, useMap, Marker, Popup } from 'react-leaflet';
import { useMapEvents, Circle } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import 'leaflet-textpath';
import DepartementSelector from './DepartementSelector'; // Ajustez le chemin selon l'organisation de vos fichiers
import DepartementSelector2 from './DepartementSelector2';
import AddressSearch from './AddressSearch';
import AdressesList from './AdressesList';

import './buttonswitch.css'
import CommuneLabels from './CommuneLabels'

import { Button,  activeButtonStyle, inactiveButtonStyle, SecondMapContainer, SecondMapTitle } from './stylesMap'
import { useAuth } from '../../auth/AuthContext';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import config from '../../config';
const MyMapEventHandler = () => {
  useMapEvents({
    mousedown: (e) => {
      if (e.originalEvent.button === 2) { // Bouton droit de la souris
        e.target.dragging.disable();
        e.target.scrollWheelZoom.disable();
      }
    },
    mouseup: (e) => {
      if (e.originalEvent.button === 2) { // Bouton droit de la souris
        e.target.dragging.enable();
        e.target.scrollWheelZoom.enable();
      }
    },
    contextmenu: (e) => {
      e.originalEvent.preventDefault(); // Prévenir l'ouverture du menu contextuel du navigateur
    }
  });

  return null; // Ce composant n'affiche rien lui-même
};


const SelectionCircle = ({ selectionSize, onSelectionProcess }) => {
  const [circlePosition, setCirclePosition] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const map = useMapEvents({
    mousedown: (e) => {
      // Bouton gauche de la souris
      if (e.originalEvent.button === 2) {
        setIsMouseDown(true);
        setCirclePosition(e.latlng);
        onSelectionProcess(e.latlng, selectionSize); // Commence la sélection dès que le bouton est enfoncé
      }
    },
    mouseup: (e) => {
      if (e.originalEvent.button === 2) {
        setIsMouseDown(false);
        setCirclePosition(null);
        // Ici, vous pouvez appeler une fonction de rappel si vous souhaitez traiter quelque chose à la fin de la sélection
      }
    },
    mousemove: (e) => {
      if (isMouseDown) {
        setCirclePosition(e.latlng);
        onSelectionProcess(e.latlng, selectionSize); // Mise à jour dynamique de la sélection
      }
    }
  });

  return circlePosition && isMouseDown ? (
    <Circle center={circlePosition} radius={selectionSize} />
  ) : null;
};


const InfoCard = () => {
  return (
    <div style={{
      padding: '20px',
      margin: '20px',
      backgroundColor: '#f2f2f2', // Couleur de fond conviviale
      borderRadius: '10px', // Bords arrondis pour un look doux
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Ombre portée pour un effet 3D subtil
      color: '#333', // Couleur de texte pour un contraste doux mais lisible
      fontSize: '16px', // Taille de police appropriée pour la lisibilité
      lineHeight: '1.5', // Espacement des lignes pour une lecture facile
    }}>
      <h2>Instructions de sélection d'adresses</h2>
      <p>Pour sélectionner des bâtiments :</p>
      <ul>
        <li>Mettez-vous en mode <strong>sélection</strong>.</li>
        <li>Maintenez le bouton droit de votre souris enfoncé tout en parcourant les bâtiments.</li>
        <li>Réglez le rayon de sélection à l'aide du curseur situé sur le côté de la carte.</li>
      </ul>
      <p>Pour désélectionner des bâtiments :</p>
      <ul>
        <li>Placez-vous en mode <strong>désélection</strong>.</li>
        <li>Procédez comme pour la sélection, en maintenant le clic droit.</li>
      </ul>
      <p>Pour enregistrer votre sélection d'adresses, cliquez sur le bouton <strong>Enregistrer les adresses</strong>.</p>
    </div>
  );
};

const CommuneInfoCard = () => {
  return (
    <div style={{
      padding: '20px',
      margin: '20px',
      backgroundColor: '#f9f9f9', // Couleur de fond légère
      borderRadius: '10px', // Bords arrondis
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Ombre subtile
      color: '#333', // Couleur de texte foncé pour contraste
      fontSize: '16px', // Taille de police pour bonne lisibilité
      lineHeight: '1.5', // Espacement des lignes
    }}>
      <h2>Instructions de Sélection des Communes</h2>
      <p>Il existe deux modes de sélection pour les communes :</p>
      <ul>
        <li><strong>Mode Sélection de la Commune Entière</strong>: La commune sélectionnée s'affichera en <span style={{ color: 'red' }}>rouge</span>.</li>
        <li><strong>Mode Sélection d'Adresses dans la Commune</strong>: Les adresses sélectionnées s'afficheront en <span style={{ color: 'blue' }}>bleu</span>.</li>
      </ul>
      <p>Pour sélectionner une commune :</p>
      <ul>
        <li>Assurez-vous d'être en <strong>mode Sélection</strong>.</li>
        <li>Cliquez sur la commune désirée pour la sélectionner.</li>
      </ul>
      <p>Pour désélectionner une commune :</p>
      <ul>
        <li>Positionnez-vous sur le <strong>mode Désélection</strong>.</li>
        <li>Cliquez sur la commune pour la désélectionner.</li>
      </ul>
      <p>Pour enregistrer votre sélection de communes, cliquez sur le bouton <strong>Enregistrer les Communes</strong>.</p>
    </div>
  );
};


const MyMap = ({ onEnregistrerCommune, onEnregistrerRue, trajetId , departements }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [selectedCommunes, setSelectedCommunes] = useState([]);
  const [selectedCommuneRue, setSelectedCommuneRue] = useState({});
  const { currentUser } = useAuth();
  const [selectedBuildingIds, setSelectedBuildingIds] = useState(new Set());
  const [selectedDepartements, setSelectedDepartements] = useState([]);
  const mapRef = useRef(null); // Référence à l'instance de la carte
  const geoJsonLayerRef = useRef(null); // Pour stocker le calque GeoJSON actuel




  const [selectedCommunesAvecRues, setSelectedCommunesAvecRues] = useState([]);
  const [selectedRues, setSelectedRues] = useState([]);




  const secondMapRef = useRef(null);

  // const [ruesData, setRuesData] = useState([]);
  const [ruesData, setRuesData] = useState({ features: [] });

  const [boolcliqueCommune, setBoolcliqueCommune] = useState(false);
  const [selectionMode, setSelectionMode] = useState('select');
  const [selectionModeCommune, setSelectionModeCommune] = useState('select');
  const currentModeRefCommune = useRef(selectionModeCommune);
  const [selectionSize, setSelectionSize] = useState(20);
  const [insee, setInsee] = useState(0);


  useEffect(() => {
    currentModeRefCommune.current = selectionModeCommune;
  }, [selectionModeCommune]);
  const [showStreets, setShowStreets] = useState(false);

// useEffect(() => {
//   console.log('selectedBuildingIds : ' , selectedBuildingIds)
// },[selectedBuildingIds])

  const handleEnregistrerCommuneEtRues = async () => {
    if (!trajetId) {
      toast.error("Veuillez sélectionner un trajet avant d'enregistrer.");
      return;
    }
  
    // Enregistrement de la commune
    await envoyerSelectionCommunes(trajetId);
  
    // // Enregistrement des rues
    // await  onEnregistrerRue(selectedRues, trajetId) 
  };


  const [adressesByRue, setAdressesByRue] = useState([]);
  const [filteredAdresses, setFilteredAdresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {

    const fetchAdresses = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${config.domain}/api/adresses_par_commune?code_insee=${insee}`);
            setAdressesByRue(response.data);
            setFilteredAdresses(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des adresses:', error);
        }
        setIsLoading(false);
    };

    fetchAdresses();
}, [insee]);

  useEffect(() => {
    if (trajetId) {
      const fetchDetailsTrajet = async () => {
        try {
          // Remplacez 'http://localhost:5005' par l'URL de base de votre API si différente
          const response = await axios.get(`${config.domain}/api/obtenir_details_trajet/${trajetId}`);
          const detailsTrajet = response.data;

          // Mettre à jour l'état avec les données récupérées
          setSelectedCommunes(detailsTrajet.communes);
          setSelectedRues(detailsTrajet.rues.map(rue => ({
            id_adresse: rue.id_adresse,
            nom_voie: rue.nom_voie,
            numero: rue.numero,
            codeinsee: rue.codeinsee,
            code_postal : rue.code_postal
          })));

          // Si vous avez besoin d'afficher les communes et les rues sur la carte, ajustez ici
          // Par exemple, mettre à jour geoJsonData pour les communes
          // et utiliser setRuesData pour les rues si nécessaire

        } catch (error) {
          console.error('Erreur lors de la récupération des détails du trajet:', error);
          // Traiter l'erreur, par exemple, en affichant un message à l'utilisateur
        }
      };

      fetchDetailsTrajet();
    }
  }, [trajetId]); // Cet effet s'exécute lorsque trajetId change





  const UpdateMapCenter = ({ lat, lon }) => {
    const map = useMap();



    useEffect(() => {
      if (boolcliqueCommune) {
        if (lat && lon) {

          map.setView([lat, lon], map.getZoom());
        }
      }

      setBoolcliqueCommune(false)
    }, [lat, lon, map]);

    return null;
  };





  const communeLayers = useRef(new Map());



  const [showSecondMap, setShowSecondMap] = useState(false); // Pour afficher la seconde carte


  const Mode = {
    SELECTION: "SELECTION",
    CHARGEMENT: "CHARGEMENT",
    SELECTION_RUES: "SELECTION_RUES",
    SELECTION_AVEC_RUES: "SELECTION_AVEC_RUES" // Nouveau mode
  };

  const [currentMode, setCurrentMode] = useState(Mode.SELECTION)
  const currentModeRef = useRef(currentMode);
  useEffect(() => {
    currentModeRef.current = currentMode;
  }, [currentMode]);






  useEffect(() => {
    const fetchGeoData = async () => {

      try {
        const response = await axios.post(`${config.domain}/api/get_communes`, {
          departements: departements,

        });

    
        
        setGeoJsonData(JSON.parse(response.data));
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchGeoData();
  }, [trajetId]);

  useEffect(() => {
    const fetchGeoData = async () => {
      try {
      

        const response = await axios.post(`${config.domain}/api/get_communes`, {
          departements: selectedDepartements,
        });
  
  
        setGeoJsonData(JSON.parse(response.data));
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
  
    if (selectedDepartements.length > 0) {
      fetchGeoData();
    }
  }, [selectedDepartements]);

  





  const fetchRuesForCommune = async (communeInsee) => {
    try {
      const url = `${config.domain}/api/obtenir_rues?trajet_id=${trajetId}&codeinsee=${communeInsee}`;
      const response = await axios.get(url);

      const newSelectedRues = response.data;
      const newSelectedBuildingIds = new Set(newSelectedRues.map(rue => rue.id_adresse));

      setSelectedRues(newSelectedRues);
      setSelectedBuildingIds(newSelectedBuildingIds); // Mettez à jour l'état avec les nouveaux IDs sélectionnés
    } catch (error) {
      console.error('Erreur lors de la récupération des rues:', error);
    }
  };


  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties['Nom Officiel Commune']) {
      const communeName = feature.properties['Nom Officiel Commune'];
      const communeInsee = feature.properties['Code Officiel Commune'];
      communeLayers.current.set(communeName, layer);



      layer.on('click', () => {

        setInsee(communeInsee);

        if (currentModeRef.current === Mode.SELECTION) {
          const commune = { name: communeName, insee: communeInsee, commune_entiere: true };
          // console.log("Commune Name:", communeName, "Commune INSEE:", communeInsee);

          const isSelected = selectedCommunes.some(item => item.name === communeName);




          if (currentModeRefCommune.current === 'select' && !isSelected) {
            // Mode Sélection : Ajouter la commune si elle n'est pas déjà sélectionnée

            const newCommune = { name: communeName, insee: communeInsee, commune_entiere: true };
            setSelectedCommunes(prevCommunes => [...prevCommunes, newCommune]);
            layer.setStyle({ fillColor: 'blue', fillOpacity: 0.7 });
          } else if (currentModeRefCommune.current === 'deselect' && isSelected) {
            // Mode Désélection : Retirer la commune si elle est sélectionnée
            setSelectedCommunes(prevCommunes => prevCommunes.filter(item => item.insee !== communeInsee));
            layer.setStyle(defaultStyle); // Appliquer le style par défaut lors de la désélection
          }




        }


        if (currentModeRef.current === Mode.SELECTION_AVEC_RUES) {
          const communeName = feature.properties['Nom Officiel Commune'];
          const communeInsee = feature.properties['Code Officiel Commune'];

          fetchRuesForCommune(communeInsee);


          const commune = { name: communeName, insee: communeInsee, commune_entiere: false };
          // console.log("Commune Name:", communeName, "Commune INSEE:", communeInsee);

          const isSelected = selectedCommunes.some(item => item.name === communeName);



          if (currentModeRefCommune.current === 'select' && !isSelected) {
            // Mode Sélection : Ajouter la commune si elle n'est pas déjà sélectionnée


            setSelectedCommunes(prevCommunes => [...prevCommunes, commune]);
            layer.setStyle({ fillColor: 'blue', fillOpacity: 0.7 });
          } else if (currentModeRefCommune.current === 'deselect' && isSelected) {
            // Mode Désélection : Retirer la commune si elle est sélectionnée
            setSelectedCommunes(prevCommunes => prevCommunes.filter(item => item.insee !== communeInsee));
            layer.setStyle(defaultStyle); // Appliquer le style par défaut lors de la désélection
          }




          const geoPoint = feature.properties['Geo Point']; // Assurez-vous que c'est la bonne propriété pour les coordonnées géographiques


          setSelectedCommunesAvecRues(prevCommunes => {
            const isSelected = prevCommunes.some(item => item.name === communeName);
            if (isSelected) {
              return prevCommunes.filter(item => item.name !== communeName);
            } else {
              return [...prevCommunes, commune];
            }
          });

          const [lat, lon] = geoPoint.split(', ');
          // Enregistre les informations de la commune


          setSelectedCommuneRue({
            nom: communeName,
            geoPoint: {
              lat: parseFloat(lat),
              lon: parseFloat(lon)
            }
          });


          setShowSecondMap(true); // Affiche la seconde carte
          setBoolcliqueCommune(true)

          fetchRuesData(communeInsee);





          setSelectedRues([])




        }






      });
    }
  };

  // useEffect(() => {
  //   console.log("Selected Communes:", selectedCommunes);
  // }, [selectedCommunes]);


  // useEffect(() => {
  //   console.log("Selected Rues:", selectedRues);
  // }, [selectedRues]);

  useEffect(() => {
    // Applique la couleur bleue aux communes sélectionnées normalement
    selectedCommunes.forEach(commune => {
      const layer = communeLayers.current.get(commune.name);
      if (layer) {
        if (commune.commune_entiere) {
          // Si commune_entiere est true, coloriser en bleu
          layer.setStyle({ fillColor: 'blue', fillOpacity: 0.7 });
        } else {
          // Si commune_entiere est false, coloriser en rouge
          layer.setStyle({ fillColor: 'red', fillOpacity: 0.7 });
        }
      }
    });


  });








  const defaultStyle = {

    fillColor: 'green',
    weight: 2,
    opacity: 1,
    color: 'white',
    fillOpacity: 0.5,

  };





  // const fetchRuesData = async (communeInsee) => {
  //   try {
  //     console.log(`${config.domain}/api/obtenir_adresses_geojson?code_insee=${communeInsee}`)
  //     // const response = await axios.get(`http://localhost:5005/api/get_adresses?code_insee=${communeInsee}`);
  //     const response = await axios.get(`${config.domain}/api/obtenir_adresses_geojson?code_insee=${communeInsee}`);
  //     // console.log(response.data)
  //     setRuesData(response.data); // Supposons que response.data contient les données des rues




  //   } catch (error) {
  //     console.error('Erreur lors de la récupération des données des rues:', error);
  //   }
  // };

  const fetchRuesData = async (communeInsee) => {
    try {
      console.log(`Fetching data for code_insee: ${communeInsee}`);
      const response = await fetch(`${config.domain}/api/obtenir_adresses_geojson?code_insee=${communeInsee}`);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let done = false;
  
      while (!done) {
        const { value, done: chunkDone } = await reader.read();
        done = chunkDone;
        if (value) {
          buffer += decoder.decode(value, { stream: true });
          let parts = buffer.split('\n');
          buffer = parts.pop(); // Dernière partie peut être incomplète, la garder dans le buffer
  
          for (let part of parts) {
            if (part) {
              try {
                const parsedChunk = JSON.parse(part);
                setRuesData(prevData => ({
                  ...prevData,
                  features: [...(prevData.features || []), ...parsedChunk.features]
                }));
                console.log("Chunk received and processed");
              } catch (e) {
                console.error('Erreur lors de l\'analyse d\'un fragment JSON:', e);
              }
            }
          }
        }
      }
      console.log("Finished receiving data");
    } catch (error) {
      console.error('Erreur lors de la récupération des données des rues:', error);
    }
  };
  
  
  
  
  










  useEffect(() => {
    // Ajouter l'écouteur d'événements au montage du composant
    const disableContextMenu = (e) => {
      e.preventDefault(); // Empêcher l'ouverture du menu contextuel du navigateur
    };

    document.addEventListener('contextmenu', disableContextMenu);

    // Retirer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);



  const handleSelectMode = () => {
    setSelectionMode('select');
  };

  const handleDeselectMode = () => {
    setSelectionMode('deselect');
  };

  const handleSelectModeCommune = () => {

    setSelectionModeCommune('select');
  };

  const handleDeselectModeCommune = () => {

    setSelectionModeCommune('deselect');
  };



  const envoyerSelectionCommunes = async (trajetId) => {
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Envoi de la sélection des communes en cours...");
  
    try {
    
      const url = `${config.domain}/api/enregistrer_selection_communes`;
      const payload = {
        trajetId: trajetId, // Assurez-vous que trajetId est correctement passé au composant
        communes: selectedCommunes, // Utilisez l'état qui contient les communes sélectionnées
      };
  
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          // Incluez d'autres en-têtes si nécessaire, par exemple un token d'authentification
        },
      });
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
    
      // Affiche un toast de succès
      toast.success('Sélection des communes envoyée avec succès !');
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      console.error('Erreur lors de l\'envoi de la sélection des communes :', error);
      // Affiche un toast d'erreur
      toast.error('Erreur lors de l\'envoi de la sélection des communes.');
    }
  };

  const handleToggleMode = () => {
    setCurrentMode(currentMode === Mode.SELECTION ? Mode.SELECTION_AVEC_RUES : Mode.SELECTION);
  };



  const onEachFeature_adresse = (feature, layer) => {
    if (feature.properties) {
      const popupContent = `
        <div>
          <h3>Informations</h3>
          <ul>
            <li>ID: ${feature.properties.id_adresse}</li>
            <li>Commune: ${feature.properties.ville || 'Non disponible'}</li>
            <li>Insee: ${feature.properties.insee || 'Non disponible'}</li>
            <li>Numéro: ${feature.properties.numero || 'Non disponible'}</li>
            <li>Rue: ${feature.properties.rue || 'Non disponible'}</li>
            <li>Adresse: ${feature.properties.adresse_complete || 'Non disponible'}</li>
            <li>latitude: ${feature.properties.latitude || 'Non disponible'}</li>
            <li>longitude: ${feature.properties.longitude || 'Non disponible'}</li>
          </ul>
        </div>
      `;

      layer.bindPopup(popupContent);

      const id = feature.properties.id_adresse;
      if (selectedBuildingIds.has(id)) {
        layer.setStyle(registeredBuildingStyle); // Applique le style sélectionné
      }






    }
  };






  const onSelectionProcess = (circlePosition, selectionSize) => {
    let updatedSelectedBuildingIds = new Set([...selectedBuildingIds]);

    // Conteneur temporaire pour les données des bâtiments sélectionnés
    let updatedSelectedRues = [];

    ruesData.features.forEach(feature => {
      const buildingPosition = L.latLng(feature.properties.latitude, feature.properties.longitude);
      const isInCircle = buildingPosition.distanceTo(circlePosition) <= selectionSize;
      const buildingInfo = {
        id_adresse: feature.properties.id_adresse,
        ville: feature.properties.ville,
        insee: feature.properties.insee,
        code_postal : feature.properties.code_postal,
        numero: feature.properties.numero,
        rue: feature.properties.rue,
        adresse_complete: feature.properties.adresse_complete,
        latitude: feature.properties.latitude,
        longitude: feature.properties.longitude

        // Ajoutez d'autres propriétés ici si nécessaire
      };

      if (isInCircle && selectionMode === 'select') {
        updatedSelectedBuildingIds.add(feature.properties.id_adresse);
        updatedSelectedRues.push(buildingInfo); // Ajoutez les données du bâtiment
      } else if (isInCircle && selectionMode === 'deselect') {
        updatedSelectedBuildingIds.delete(feature.properties.id_adresse);
        // Pas besoin d'ajouter à updatedSelectedRues ici, car nous reconstruisons cette liste entièrement
      }
    });

    // Filtrez les données des bâtiments pour ne conserver que celles sélectionnées
    updatedSelectedRues = ruesData.features
      .filter(feature => updatedSelectedBuildingIds.has(feature.properties.id_adresse))
      .map(feature => ({
        id_adresse: feature.properties.id_adresse,
        ville: feature.properties.ville,
        insee: feature.properties.insee,
        code_postal : feature.properties.code_postal,
        numero: feature.properties.numero,
        rue: feature.properties.rue,
        adresse_complete: feature.properties.adresse_complete,
        latitude: feature.properties.latitude,
        longitude: feature.properties.longitude
        // Ajoutez d'autres propriétés ici si nécessaire
      }));

    setSelectedBuildingIds(updatedSelectedBuildingIds);
    setSelectedRues(updatedSelectedRues); // Mettez à jour l'état avec les données des bâtiments sélectionnés
  };



  // const handleSelectStreet = (streetName) => {
  //   alert('test')
  //   // const url = `https://backend-plateforme-hoali.gourdefriendly.green/api/obtenir_rues_par_nom?trajet_id=${trajetId}&rue=${streetName}`;
  //   // axios.get(url).then(response => {
  //   //   const selectedFeatures = response.data;
  //   //   setSelectedRues(selectedFeatures);
  //   // }).catch(error => console.error('Erreur lors de la récupération des rues:', error));
  // };

  
  const handleSelectStreetSearch2 = (streetName , number) => {

  


     const url = `${config.domain}/api/batiments?rue=${encodeURIComponent(streetName)}&numero=${number}&code_insee=${insee}`;
  axios.get(url).then(response => {
     const selectedFeatures = response.data;

     setSelectedRues(selectedFeatures);
   }).catch(error => console.error('Erreur lors de la récupération des rues:', error));
  };



const handleAdresseSelected3 = (input, isSelected) => {
 
  let adresses;
  if (input.adresses && Array.isArray(input.adresses)) {
    adresses = input.adresses;
  } else if (Array.isArray(input)) {
    adresses = input;
  } else {
    adresses = [input];
  }

  setSelectedRues(previousRues => {
    let newRues = new Map(previousRues.map(rue => [rue.id_adresse, rue]));
    adresses.forEach(adresse => {
      const idKey = adresse.id;
      if (isSelected) {
        newRues.set(idKey, {
          id_adresse: idKey,
          ville: adresse.ville,
          insee: adresse.insee,
          code_postal: adresse.code_postal,
          numero: adresse.numero,
          rue: adresse.rue,
          adresse_complete: adresse.adresse_complete,
          latitude: adresse.latitude,
          longitude: adresse.longitude
        });
      } else {
        newRues.delete(idKey);
      }
    });
    return Array.from(newRues.values()); // Convertir Map en Array pour la mise à jour du state
  });

  setSelectedBuildingIds(prevBuildingIds => {
    const updatedBuildingIds = new Set(prevBuildingIds);
    adresses.forEach(adresse => {
      const idKey = adresse.id;
      if (isSelected) {
        updatedBuildingIds.add(idKey);
      } else {
        updatedBuildingIds.delete(idKey);
      }
    });
    return updatedBuildingIds;
  });
};


// const handleLocationSearch = (location) => {
//   // alert(location); // Mise à jour du centre de la carte
// };

const handleLocationSearch = (location) => {
  // Utiliser useMap pour accéder à l'instance de la carte
  const map = secondMapRef.current;
  if (map && location) {
    map.flyTo(location, map.getZoom()); // Centrer la carte sur la nouvelle location avec un effet d'animation
  }
};


  return (
    <>

{/* <DepartementSelector onDepartementsChange={setSelectedDepartements} /> */}
<div style={containerStyle}>

<DepartementSelector2 onDepartementsChange={setSelectedDepartements} />
<CommuneInfoCard />
      <div style={ButtonContainerStyle}>
        <div style={ColumnStyle}>
          <Button onClick={handleSelectModeCommune} style={selectionModeCommune === 'select' ? activeButtonStyle : inactiveButtonStyle}>
            Mode Sélection
          </Button>
          <Button onClick={handleDeselectModeCommune} style={selectionModeCommune === 'deselect' ? activeButtonStyle : inactiveButtonStyle}>
            Mode Désélection
          </Button>
        </div>

        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Centre le switch et aligne le texte verticalement */}
          <label className="switch">
            <input
              type="checkbox"
              checked={currentMode === Mode.SELECTION_AVEC_RUES}
              onChange={handleToggleMode}
            />
            <span className="slider round"></span>
          </label>
          <span style={textStyle}>
            {currentMode === Mode.SELECTION ? 'Sélection des communes' : 'Sélection des communes avec rues'}
          </span>
        </div>
        {/* <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => { envoyerSelectionCommunes(trajetId) }}
            style={{
              padding: '10px 20px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#4CAF50',
              color: 'white',
              fontSize: '16px',
              cursor: 'pointer',
            }}>
            Valider la sélection des communes
          </Button>
        </div> */}



      </div>








      <MapContainer
        center={[48.5603, -3.1494]}
        zoom={13}
        style={{ ...mapContainerStyle }}
        ref={mapRef}

        scrollWheelZoom={true}


      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="OpenStreetMap">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>

          {/* {geoJsonData && <GeoJSON   key={selectedCommunes.join(",")} data={geoJsonData} onEachFeature={onEachFeature} style={defaultStyle} />} */}
          {geoJsonData && <GeoJSON key={Date.now()} data={geoJsonData} onEachFeature={onEachFeature} style={defaultStyle} />
}
          {geoJsonData && <CommuneLabels geoJsonData={geoJsonData} />}


        </LayersControl>
      </MapContainer>

      </div>

   
      {showSecondMap && selectedCommuneRue && (
           <div style={containerStyle}>
        
        <>



<div style={{ marginBottom: '20px', marginTop: '20px', textAlign: 'center' }}> {/* Centre le switch et aligne le texte verticalement */}
        <label className="switch">
          <input
            type="checkbox"
            checked={showStreets}
            onChange={() => setShowStreets(prev => !prev)}
          />
          <span className="slider round"></span>
        </label>
        <span style={{ marginLeft: '10px' }}>
          {showStreets ? 'Vue des rues' : 'Vue cartographique'}
        </span>
      </div>

      {showStreets ? (
         <AdressesList codeInsee={insee} handleAdresseSelected3={handleAdresseSelected3}  selectedBuildingIds={selectedBuildingIds} setSelectedBuildingIds={setSelectedBuildingIds} adressesByRue={adressesByRue} filteredAdresses={filteredAdresses} setFilteredAdresses={setFilteredAdresses} setAdressesByRue={setAdressesByRue} isLoading={isLoading}/>

        ) : (
<>
        <InfoCard />
    
        {/* <AddressSearch  handleSelectStreetSearch2={handleSelectStreetSearch2} /> */}
          <SecondMapContainer style={{width : "100%" }}>
            <SecondMapTitle>Commune: {selectedCommuneRue.nom}</SecondMapTitle>
            <AddressSearch onSearch={handleLocationSearch} />


            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: '100%' }}>
              <div>
                <Button
                  onClick={handleSelectMode}
                  style={selectionMode === 'select' ? activeButtonStyle : inactiveButtonStyle}>
                  Mode Sélection
                </Button>
                <Button
                  onClick={handleDeselectMode}
                  style={selectionMode === 'deselect' ? activeButtonStyle : inactiveButtonStyle}>
                  Mode Désélection
                </Button>
                <div style={{ padding: '20px' }}>

                  <label htmlFor="selectionSize">Taille de Sélection :</label>
                  <input
                    id="selectionSize"
                    type="range"
                    min="5"
                    max="500"
                    value={selectionSize}
                    onChange={(e) => setSelectionSize(Number(e.target.value))}
                  />

                </div>

              </div>
             


            <MapContainer

              center={[selectedCommuneRue.geoPoint.lat, selectedCommuneRue.geoPoint.lon]}
              zoom={15}
              style={{ height: '600px', width: '100%', }}
              ref={secondMapRef}

            >
              <SelectionCircle selectionSize={selectionSize} onSelectionProcess={onSelectionProcess} />


              <UpdateMapCenter lat={selectedCommuneRue.geoPoint.lat} lon={selectedCommuneRue.geoPoint.lon} />
              0
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap">
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite">
                  <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                </LayersControl.BaseLayer>
                {/* {geoJsonData && <GeoJSON data={geoJsonData} onEachFeature={onEachFeatureCommmuneRue} style={defaultStyleCommuneRue} />}
                {geoJsonData && <CommuneLabels geoJsonData={geoJsonData} />} */}

                {ruesData && <GeoJSON key={Date.now()} data={ruesData} onEachFeature={onEachFeature_adresse} />}

                <MyMapEventHandler />


              </LayersControl>
            </MapContainer>
            {/* <Button
          onClick={() => { onEnregistrerRue(selectedRues, trajetId) }}
          style={{
            marginLeft : '10px',
            padding: '10px 20px',
            borderRadius: '4px',
            border: 'none',
            // backgroundColor: selectedRues.length > 0 ? '#4CAF50' : '#CCC',
            backgroundColor: '#4CAF50' ,

            color: 'white',
            fontSize: '16px',
            cursor: selectedRues.length > 0 ? 'pointer' : 'default',
            transition: 'background-color 0.3s',
          }}
          onMouseOver={(e) => selectedRues.length > 0 && (e.target.style.backgroundColor = '#45a049')}
          onMouseOut={(e) => selectedRues.length > 0 && (e.target.style.backgroundColor = '#4CAF50')}
  
       
        >
          Enregistrer les Adresses
        </Button> */}

                </div>
          </SecondMapContainer>
          </>
        )}

<div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <button
            onClick={() => { onEnregistrerRue(selectedRues, trajetId) }}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#45a049')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#4CAF50')}
        >
          Enregistrer les Adresses
        </button>
      </div>


            
        </>
        </div>

      )}





<button 
  onClick={handleEnregistrerCommuneEtRues}
  style={{
    padding: '20px 40px', // Augmentation du padding pour un bouton plus grand
    width: '100%', // Utiliser toute la largeur du conteneur pour le bouton
    borderRadius: '8px', // Rayon de bordure légèrement plus arrondi
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    fontSize: '20px', // Augmentation de la taille de la police pour une meilleure visibilité
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginTop: '20px'
  }}
  onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
  onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
>
  Enregistrer les Communes
</button>

    <ToastContainer />


    </>


  );
};




const ButtonContainerStyle = {
  display: 'flex',
  alignItems: 'center', // Aligner les éléments enfants verticalement au centre
  justifyContent: 'space-between', // Espacement entre les éléments enfants
  width: '75%', // Utiliser toute la largeur disponible
};

const ColumnStyle = {
  display: 'flex',
  flexDirection: 'column', // Organise les éléments enfants en colonne
  gap: '10px', // Espace entre chaque bouton dans la colonne
};

// Style en ligne sans `!important`
const textStyle = {
  marginLeft: '20px', // Augmente l'espacement pour donner plus de respiration
  fontSize: '18px', // Légèrement plus grand pour améliorer la lisibilité
  fontWeight: '500', // Mi-gras pour un contraste amélioré sans être trop imposant
  color: '#333', // Garde la couleur foncée pour une bonne lisibilité
  display: 'flex',
  alignItems: 'center', // Assure que le texte est aligné verticalement avec le switch
};

const registeredBuildingStyle = {
  fillColor: "#ff7800",
  color: "#000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8,
};


const mapContainerStyle = {
  height: '500px',
  width: '80%',
};



const containerStyle = {
  display: 'flex',

  alignItems: 'center',
  flexDirection: 'column', // Organiser les éléments en colonne pour une meilleure structure
  margin: '20px', // Marge pour détacher le bloc du reste de l'interface
  padding: '20px',
  border: '1px solid #ccc', // Bordure subtile pour définir le bloc
  borderRadius: '10px', // Coins arrondis
  backgroundColor: '#f8f9fa', // Couleur de fond légère
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Ombre pour un effet de profondeur
  width: '80%'
};

export default MyMap;




