import React, { useState , useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Importez le CSS par défaut de react-calendar
import CollecteCategorie from './CollecteCategorie';
import CalendarNameForm from './CalendarNameForm';
import SelectTournee from './SelectTournee';
import CalendarCreateComponent from './CalendarCreateComponent';
import MessageLinkForm from './MessageLinkForm';
import { useAuth } from '../../auth/AuthContext';
import { useParams } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import config from '../../config';
import AsyncStorage from '@react-native-async-storage/async-storage';

const CategoryInfoCard = () => {
  return (
    <div style={{
      padding: '20px',
      margin: '20px',
      backgroundColor: '#f9f9f9', // Couleur de fond légère
      borderRadius: '10px', // Bords arrondis
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Ombre subtile
      color: '#333', // Couleur de texte foncé pour contraste
      fontSize: '16px', // Taille de police pour bonne lisibilité
      lineHeight: '1.5', // Espacement des lignes
    }}>
      <h2>Création de Catégories Nécessaire</h2>
      <p>Si aucune catégorie n'est présente, vous devez en créer pour pouvoir classer vos éléments :</p>
      <ul>
        <li>Rendez-vous sur la page de création des catégories.</li>
        <li>Pour y aller, cliquez sur <strong>Créer mes catégories</strong> situé dans la barre de navigation.</li>
      </ul>
    </div>
  );
};

function CalendarComponent({ isEditing }) {
  const { currentUser } = useAuth();
  const { tourneeId } = useParams();
  const [afficherModal, setAfficherModal] = useState(false); // État pour contrôler l'affichage du modal

  const [calendarName, setCalendarName] = useState('');
  const [selectedTournees, setSelectedTournees] = useState([]); // Nouvel état pour stocker les tournées sélectionnées
  const [messages, setMessages] = useState({});
  const [categoriesActives, setCategoriesActives] = useState([
     { id: 1 , nom: 'BAC BLEU' },
    { id: 2 , nom: 'COLLECTE SELECTIVE' },
    { id: 3 , nom: 'BAC VERRE' },
    { id: 4 , nom: 'ORDURES MÉNAGÈRES' },
    { id: 5 , nom: 'BAC RECYCLABLE' },
    { id: 6 , nom: 'BAC VERT' },
    { id: 7 , nom: 'DÉCHETS ALIMENTAIRES ET COMPOSTABLE' },
    { id: 8 , nom: 'BAC BLEU ET SAC JAUNE'},
    { id: 9 , nom: 'COLLECTE DES ENCOMBRANTS'},
    { id: 10 , nom: 'TRIMOBILE PARIS' }
  ]);
  const [selectedDates, setSelectedDates] = useState(new Map());

  const [collectiviteId, setCollectiviteId] = useState('');

  useEffect(() => {
    const fetchCollectiviteID = async () => {
      const collectiviteId = await AsyncStorage.getItem('selectedCollectiviteId');
      setCollectiviteId(collectiviteId);
    };

    fetchCollectiviteID();
  }, [])

  const handleNameSubmit = (name) => {
    setCalendarName(name);
    // Ici, vous pouvez également faire des opérations supplémentaires comme envoyer le nom à un serveur
  };

  // Cette fonction sera appelée lorsque l'utilisateur sélectionne/déselectionne des tournées
  const onTourneesSelectionnees = (tourneesIds) => {
    setSelectedTournees(tourneesIds);
    console.log('Tournées sélectionnées:', tourneesIds);
    // Vous pouvez ici effectuer d'autres actions avec les IDs des tournées sélectionnées
  };

  useEffect(() => {
    console.log("selectedDates : ", selectedDates)
  }, [selectedDates]); // Ajoutez toutes les dépendances pertinentes ici

  useEffect(() => {
    console.log("categoriesActives : ", categoriesActives)
  }, [categoriesActives]); // Ajoutez toutes les dépendances pertinentes ici

  
  useEffect(() => {
    if(collectiviteId){
      console.log(`${config.domain}/api/get_categories?id_collectivite=${collectiviteId}`)
    fetch(`${config.domain}/api/get_categories?id_collectivite=${collectiviteId}`)
      .then(response => response.json())
      .then(data => {
        const categoriesFormatees = data.map(categorie => ({
          id: categorie.id,
          nom: categorie.nom.toUpperCase() // Mettre en majuscule si nécessaire
        }));
        setCategoriesActives(categoriesFormatees);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des catégories:', error);
      });
    }
  }, [collectiviteId]); 

  //  // Fonction pour envoyer les données à votre backend
  //  const envoyerDonnees = async () => {

  //   const url = 'http://localhost:5005/api/create_calendar'; // Remplacez par l'URL de votre endpoint
  //   const payload = {
  //     iduser : currentUser.iduser,
  //     calendarName,
  //     selectedTournees,
  //     selectedDates: Array.from(selectedDates).map(([date, details]) => ({
  //       date,
  //       categories: Array.from(details.categories)
  //     })),
  //     messages,
  //   };
  
  //   // Log pour vérifier la structure de payload avant envoi
  //   console.log('Payload avant envoi:', payload);
  
  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(payload),
  //     });
    
  //     const responseData = await response.json(); // Essayez de lire la réponse JSON en premier
    
  //     if (!response.ok) {
  //       throw new Error(responseData.message || 'Erreur inconnue'); // Utilisez le message de la réponse s'il est disponible
  //     }
    
  //     console.log('Données envoyées avec succès:', responseData);
       
  //   } catch (error) {
  //     console.error('Erreur lors de l\'envoi des données:', error.message);
  //     console.error('Erreur lors de l\'envoi des données:', error.error);  // Utilisez error.message pour obtenir le message d'erreur
  //   }
  // };

  const envoyerDonnees = async () => {
    const url = `${config.domain}/api/create_calendar`;
    const payload = {
      iduser: currentUser.iduser,
      calendarName,
      selectedTournees,
      selectedDates: Array.from(selectedDates).map(([date, details]) => ({
        date,
        categories: Array.from(details.categories)
      })),
      messages,
    };
  
    console.log('Payload avant envoi:', payload);
  
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Envoi des données en cours...");
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json(); // Tente de lire la réponse JSON
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Erreur inconnue');
      }
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
      // Affiche un toast de succès
      console.log('Données envoyées avec succès:', responseData);
      toast.success('Données envoyées avec succès !');
  
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      console.error('Erreur lors de l\'envoi des données:', error.message);
      // Affiche un toast d'erreur avec le message d'erreur
      toast.error(`Erreur lors de l'envoi des données: ${error.message}`);
    }
  };

  const envoyerDonneesModif = async () => {
    const url = `${config.domain}/api/modify_calendar/${tourneeId}`;
    const payload = {
      iduser: currentUser.iduser,
      calendarName,
      selectedTournees,
      selectedDates: Array.from(selectedDates).map(([date, details]) => ({
        date,
        categories: Array.from(details.categories)
      })),
      messages,
    };
  
    console.log('Payload avant envoi:', payload);
  
    // Affiche un toast de chargement et enregistre son ID
    const toastId = toast.loading("Modification des données en cours...");
  
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json(); // Tente de lire la réponse JSON
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Erreur inconnue');
      }
  
      // Ferme le toast de chargement
      toast.dismiss(toastId);
  
      // Affiche un toast de succès
      console.log('Données modifiées avec succès:', responseData);
      toast.success('Données modifiées avec succès !');
  
    } catch (error) {
      // Ferme le toast de chargement en cas d'erreur
      toast.dismiss(toastId);
  
      console.error('Erreur lors de la modification des données:', error.message);
      // Affiche un toast d'erreur avec le message d'erreur
      toast.error(`Erreur lors de la modification des données: ${error.message}`);
    }
  };
  

   // Fonction pour ouvrir le modal
   const ouvrirModal = () => setAfficherModal(true);

   // Fonction pour fermer le modal
   const fermerModal = () => setAfficherModal(false);
 
   // Fonction pour gérer la confirmation du modal
   const confirmerCreation = () => {

    if (isEditing ) {

      envoyerDonneesModif(); // Remplacez par votre fonction d'envoi de données
     fermerModal();

    }else{
      
      envoyerDonnees(); // Remplacez par votre fonction d'envoi de données
      fermerModal();

    }
     
   };


   // edit 

   useEffect(() => {
    if (isEditing && tourneeId) {
      // Construisez l'URL avec l'ID de la tournée
      const url = `${config.domain}/api/get_calendar_info/${tourneeId}`;
  
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erreur lors de la récupération des données de la tournée');
          }
          return response.json();
        })
        .then(data => {
          // Ici, vous devez mettre à jour l'état de votre composant avec les données récupérées
          console.log(data); // Log les données pour le debug
          setCalendarName(data.calendarName);
          // Pour selectedTournees, cela dépend de la structure de vos données.
          // Vous devez peut-être ajuster ce code pour correspondre à la structure attendue.
          setSelectedTournees(data.selectedTournees.map(tournee => tournee.id));
          // Traiter les dates sélectionnées
          const newSelectedDates = new Map();
          data.selectedDates.forEach(dateInfo => {
            newSelectedDates.set(dateInfo.date, { categories: new Set(dateInfo.categories) });
        
          });
          setSelectedDates(newSelectedDates);
          // Traiter les messages
          setMessages(data.messages);

          setSelectedTournees(data.selectedTournees)

          

         
        })
        .catch(error => {
          console.error('Erreur:', error);
        });
    }
  }, [isEditing, tourneeId]); // Les dépendances du useEffect
  

  return (
    <>
      <center>{isEditing ? <h2>Édition de la tournée {tourneeId}</h2> : <h2>Création d'une nouvelle tournée</h2>}</center>

      <center><h1>{calendarName || 'Votre Calendrier'}</h1></center>
      <CalendarNameForm onNameSubmit={handleNameSubmit} setCalendarName={setCalendarName} calendarName={calendarName} />
      {/* <CollecteCategorie setCategoriesActives={setCategoriesActives} categoriesActives={categoriesActives} /> */}
      {/* <SelectTournee onTourneesSelectionnees={onTourneesSelectionnees} /> */}
      <SelectTournee onTourneesSelectionnees={onTourneesSelectionnees} selectedTournees={selectedTournees} />
      <CategoryInfoCard />
      <CalendarCreateComponent categoriesActives={categoriesActives} selectedDates={selectedDates} setSelectedDates={setSelectedDates}/>
      <MessageLinkForm  selectedDates={selectedDates} categoriesActives={categoriesActives} selectedTournees={selectedTournees} messages = {messages} setMessages={setMessages}/>
      {isEditing ?  <center><button onClick={ouvrirModal} style={{ fontSize: '20px', padding: '10px 20px', marginTop: '20px' , marginBottom : "50px" }}>Modifier ma tournée</button></center>  :  <center><button onClick={ouvrirModal} style={{ fontSize: '20px', padding: '10px 20px', marginTop: '20px' , marginBottom : "50px" }}>Créer ma tournée</button></center>}


      {afficherModal && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '5px',
          }}>
            <h2>Confirmation</h2>
            {isEditing ? <p>Êtes-vous sûr de vouloir modifier cette tournée ?</p> : <p>Êtes-vous sûr de vouloir créer cette tournée ?</p> }
            <button onClick={confirmerCreation} style={{ marginRight: '10px' }}>Confirmer</button>
            <button onClick={fermerModal}>Annuler</button>
          </div>
        </div>
      )}

<ToastContainer />

    </>
  );
}

export default CalendarComponent;
