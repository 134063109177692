import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../config';
function NavBar() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [collectiviteName, setCollectiviteName] = useState('');

  useEffect(() => {
    const fetchCollectiviteName = async () => {
      const name = await AsyncStorage.getItem('selectedCollectiviteName');
      setCollectiviteName(name);
    };

    fetchCollectiviteName();
  }, []);

  const handleLogout = async () => {
    
    await AsyncStorage.removeItem('selectedCollectiviteName'); // Supprimer le nom lors de la déconnexion
    await AsyncStorage.removeItem('selectedCollectiviteId');   // Supprimer l'ID lors de la déconnexion
    await logout();
    navigate('/login');
    window.location.reload()
  };

  const navStyle = {
    padding: '10px 20px',
    background: '#007bff',
    color: '#ffffff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  };

  const ulStyle = {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  };

  const liStyle = {
    margin: '0 10px',
  };

  const buttonStyle = {
    padding: '8px 15px',
    background: 'transparent',
    color: 'white',
    border: '1px solid white',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background 0.3s, color 0.3s',
    marginLeft : 10
  };

  return (
    <nav style={navStyle}>
      <ul style={ulStyle}>
        {currentUser && (
          <>
            <li style={liStyle}>
              <span style={{ ...buttonStyle, background: 'none', border: 'none', cursor: 'default' }}>
                {collectiviteName || 'Aucune collectivité sélectionnée'}
              </span>
            </li>
            {currentUser.role === 'admin' && (
              <li style={liStyle}>
                <button onClick={() => navigate('/admin')} style={buttonStyle}>Admin</button>
              </li>
            )}
            <li style={liStyle}>
              <button onClick={handleLogout} style={{...buttonStyle, marginLeft: 'auto'}}>Déconnexion</button>
            </li>
            <li style={liStyle}>
              <button onClick={() => navigate('/')} style={buttonStyle}>Mes Collectivités</button>
              {collectiviteName && (
                <>
                  <button onClick={() => navigate('/UserDashboard')} style={buttonStyle}>Mes calendriers</button>
                  <button onClick={() => navigate('/create-trip')} style={buttonStyle}>Tournées</button>
                  <button onClick={() => navigate('/create_category')} style={buttonStyle}>Organisation des Catégories pour les Calendriers</button>
                  <button onClick={() => navigate('/collector')} style={buttonStyle}>Gestion des Points de Collecte</button>
                  <button onClick={() => navigate('/guidedutri')} style={buttonStyle}>Guide du tri</button>
                  {/* <button onClick={() => navigate('/Adresse')} style={buttonStyle}>Adresse</button> */}
                </>
              )}
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default NavBar;
