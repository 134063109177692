
import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, GeoJSON, useMap, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import 'leaflet-textpath';
import config from '../../config';
const CommuneLabels = ({ geoJsonData }) => {
    const map = useMap();
  
  
    const polylinesRef = useRef(new Map());
  
  
  
    useEffect(() => {
      if (geoJsonData && map) {
        geoJsonData.features.forEach(feature => {
          if (feature.properties && feature.properties['Nom Officiel Commune']) {
            const communeName = feature.properties['Nom Officiel Commune'];
            const layer = L.geoJSON(feature);
            const center = layer.getBounds().getCenter();
  
            const textLength = communeName.length;
            const offset = Math.min(0.01 * textLength, 0.05); // Ajuster selon la longueur du texte
  
            const latlngs = [
              [center.lat, center.lng - offset],
              [center.lat, center.lng + offset]
            ];
  
            const textStyle = {
              repeat: false,
              center: true,
              offset: 10,
              attributes: {
                fill: 'black',
                'font-size': '16px',
                'font-weight': 'bold',
                'background-color': 'white',
                padding: '3px',
                'border-radius': '5px',
              }
            };
  
            const polyline = L.polyline(latlngs, { color: 'transparent' }).setText(communeName, textStyle);
            polyline.addTo(map);
            polylinesRef.current.set(communeName, polyline);
          }
        });
      }
  
      return () => {
        polylinesRef.current.forEach(polyline => polyline.remove());
      };
    }, [geoJsonData, map]);
  
    return null;
  };

  
  export default CommuneLabels;