// config.js

// const config = {
//     domain: 'http://localhost:5002' // Remplacez 'localhost' par votre nom de domaine actuel
//   };

const config = {
      domain: 'https://backend-plateforme-hoali.gourdefriendly.green' // Remplacez 'localhost' par votre nom de domaine actuel
  };

  // https://backend-plateforme-hoali.gourdefriendly.green
  
  export default config;
  