import React, { useState, useEffect } from 'react';

const DefaultInfoForm = ({ category, onDefaultInfoChange }) => {
  const [defaultDescription, setDefaultDescription] = useState(category.default_description || '');
  const [defaultProducts, setDefaultProducts] = useState(category.default_products.map(product => product.name) || []);
  const [newProduct, setNewProduct] = useState('');

  useEffect(() => {
    onDefaultInfoChange({ defaultDescription, defaultProducts });
  }, [defaultDescription, defaultProducts]);

  const handleDescriptionChange = (e) => {
    setDefaultDescription(e.target.value);
  };

  const handleProductChange = (e) => {
    setNewProduct(e.target.value);
  };

  const addProduct = () => {
    if (newProduct.trim() !== '') {
      setDefaultProducts([...defaultProducts, newProduct]);
      setNewProduct('');
    }
  };

  const removeProduct = (index) => {
    const updatedProducts = defaultProducts.filter((_, i) => i !== index);
    setDefaultProducts(updatedProducts);
  };

  return (
    <div className='default-info-form'>
      <h3>Informations par défaut</h3>
      <label>
        Description par défaut:
        <textarea
          value={defaultDescription}
          onChange={handleDescriptionChange}
          placeholder='Entrez une description par défaut'
        />
      </label>
      <label>
        Produits par défaut:
        <input
          type='text'
          value={newProduct}
          onChange={handleProductChange}
          placeholder='Entrez un produit par défaut'
        />
        <button onClick={addProduct}>Ajouter Produit</button>
      </label>
      <ul>
        {defaultProducts.map((product, index) => (
          <li key={index}>
            {product} <button onClick={() => removeProduct(index)}>Supprimer</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DefaultInfoForm;
