// src/components/LoginPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext'; // Assurez-vous que le chemin est correct
import config from '../config';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.domain}/users/login`, {
        email,
        mdp: password,
      });
      if (response.data.login) {
        
        login(response.data); // Enregistre les données utilisateur dans le contexte
        navigate('/'); // Redirige vers le tableau de bord
      } else {
        // Gérer l'échec de la connexion, par exemple en affichant un message d'erreur
        alert('Échec de la connexion');
      }
    } catch (error) {
      console.error('Erreur de connexion', error);
    }
  };
  
// Dans LoginPage.js, ajoutez les styles en haut du fichier
const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
    },
    input: {
      margin: '10px 0',
      padding: '10px',
    },
    button: {
      padding: '10px',
      backgroundColor: 'blue',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
    }
  };
  
  // Modifiez votre composant pour utiliser ces styles
  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required style={styles.input} />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe" required style={styles.input} />
        <button type="submit" style={styles.button}>Connexion</button>
      </form>
    </div>
  );
  
}


export default LoginPage;
