// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Card, CardContent, Typography, Button, Grid, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { useNavigate } from 'react-router-dom';
// import config from '../config';
// function CollectiviteList() {
//     const [collectivites, setCollectivites] = useState([]);
//     const [open, setOpen] = useState(false);
//     const [editMode, setEditMode] = useState(false);
//     const [selectedId, setSelectedId] = useState(null);
//     const [nom, setNom] = useState('');
//     const [description, setDescription] = useState('');
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchCollectivites();
//     }, []);

//     const fetchCollectivites = () => {
//         axios.get(`${config.domain}/api/collectivites`)
//             .then(response => {
//                 setCollectivites(response.data);
//             })
//             .catch(error => console.error('Erreur lors de la récupération des collectivités:', error));
//     };

//     const handleClickOpen = (collectivite) => {
//         if (collectivite) {
//             setNom(collectivite.nom);
//             setDescription(collectivite.description);
//             setSelectedId(collectivite.id);
//             setEditMode(true);
//         } else {
//             setNom('');
//             setDescription('');
//             setSelectedId(null);
//             setEditMode(false);
//         }
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const handleSave = () => {
//         const url = editMode ? `${config.domain}/api/collectivites/${selectedId}` : `${config.domain}/api/collectivites`;
//         const method = editMode ? 'PUT' : 'POST';
//         axios({ method, url, data: { nom, description } })
//             .then(() => {
//                 handleClose();
//                 fetchCollectivites();
//             })
//             .catch(error => console.error('Erreur lors de la création/mise à jour de la collectivité:', error));
//     };

//     const handleSelectCollectivite = async (id, name) => {
//         try {
//             await AsyncStorage.setItem('selectedCollectiviteId', id.toString());
//             await AsyncStorage.setItem('selectedCollectiviteName', name.toString());
//             navigate('/UserDashboard');
//             window.location.reload();
//         } catch (e) {
//             console.error('Erreur lors de l\'enregistrement de l\'ID de la collectivité:', e);
//         }
//     };

//     return (
//         <Box sx={{ width: '100%', margin: '20px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//             <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => handleClickOpen(null)}
//                 sx={{ width: 'fit-content', marginBottom: '20px' }}
//             >
//                 Créer Collectivité
//             </Button>
//             <Dialog open={open} onClose={handleClose}>
//                 <DialogTitle>{editMode ? 'Modifier une collectivité' : 'Créer une nouvelle collectivité'}</DialogTitle>
//                 <DialogContent>
//                     <DialogContentText>
//                         {editMode ? 'Modifier les informations de la collectivité.' : 'Pour créer une nouvelle collectivité, veuillez saisir le nom et une description.'}
//                     </DialogContentText>
//                     <TextField
//                         autoFocus
//                         margin="dense"
//                         id="name"
//                         label="Nom de la collectivité"
//                         type="text"
//                         fullWidth
//                         variant="standard"
//                         value={nom}
//                         onChange={(e) => setNom(e.target.value)}
//                     />
//                     <TextField
//                         margin="dense"
//                         id="description"
//                         label="Description"
//                         type="text"
//                         fullWidth
//                         variant="standard"
//                         value={description}
//                         onChange={(e) => setDescription(e.target.value)}
//                     />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleClose}>Annuler</Button>
//                     <Button onClick={handleSave}>{editMode ? 'Mettre à jour' : 'Créer'}</Button>
//                 </DialogActions>
//             </Dialog>
//             <Grid container spacing={2} justifyContent="center">
//                 {collectivites.map(collectivite => (
//                     <Grid item xs={12} sm={6} md={4} key={collectivite.id}>
//                         <Card>
//                             <CardContent>
//                                 <Typography variant="h5">{collectivite.nom}</Typography>
//                                 <Button
//                                     variant="outlined"
//                                     color="primary"
//                                     onClick={() => handleClickOpen(collectivite)}
//                                     sx={{ marginRight: '10px' }}
//                                 >
//                                     Modifier
//                                 </Button>
//                                 <Button
//                                     variant="contained"
//                                     color="secondary"
//                                     onClick={() => handleSelectCollectivite(collectivite.id, collectivite.nom)}
//                                 >
//                                     Choisir cette collectivité
//                                 </Button>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                 ))}
//             </Grid>
//         </Box>
//     );
// }

// export default CollectiviteList;


// src/screen/CollectiviteManager.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card, CardContent, Typography, Button, Grid, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigate } from 'react-router-dom';
import config from '../config';

function CollectiviteList() {
  const [collectivites, setCollectivites] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [nom, setNom] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCollectivites();
  }, []);

  const fetchCollectivites = () => {
    axios
      .get(`${config.domain}/api/collectivites`)
      .then((response) => {
        setCollectivites(response.data);
      })
      .catch((error) =>
        console.error('Erreur lors de la récupération des collectivités:', error)
      );
  };

  const handleClickOpen = (collectivite) => {
    if (collectivite) {
      setNom(collectivite.nom);
      setDescription(collectivite.description);
      setSelectedId(collectivite.id);
      setEditMode(true);
    } else {
      setNom('');
      setDescription('');
      setSelectedId(null);
      setEditMode(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const url = editMode
      ? `${config.domain}/api/collectivites/${selectedId}`
      : `${config.domain}/api/collectivites`;
    const method = editMode ? 'PUT' : 'POST';
    axios({ method, url, data: { nom, description } })
      .then(() => {
        handleClose();
        fetchCollectivites();
      })
      .catch((error) =>
        console.error('Erreur lors de la création/mise à jour de la collectivité:', error)
      );
  };

  const handleSelectCollectivite = async (id, name) => {
    try {
      await AsyncStorage.setItem('selectedCollectiviteId', id.toString());
      await AsyncStorage.setItem('selectedCollectiviteName', name.toString());
      navigate('/UserDashboard');
      window.location.reload();
    } catch (e) {
      console.error('Erreur lors de l\'enregistrement de l\'ID de la collectivité:', e);
    }
  };

  const handleSelectCommunes = (id) => {
    navigate(`/select_communes/${id}`);
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: '20px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClickOpen(null)}
        sx={{ width: 'fit-content', marginBottom: '20px' }}
      >
        Créer Collectivité
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editMode ? 'Modifier une collectivité' : 'Créer une nouvelle collectivité'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editMode
              ? 'Modifier les informations de la collectivité.'
              : 'Pour créer une nouvelle collectivité, veuillez saisir le nom et une description.'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nom de la collectivité"
            type="text"
            fullWidth
            variant="standard"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSave}>{editMode ? 'Mettre à jour' : 'Créer'}</Button>
        </DialogActions>
      </Dialog>
      <Grid container direction="column" spacing={2} alignItems="center">
        {collectivites.map((collectivite) => (
          <Grid item xs={12} sm={6} md={4} key={collectivite.id}>
            <Card>
              <CardContent>
                <Typography variant="h5">{collectivite.nom}</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClickOpen(collectivite)}
                  sx={{ marginRight: '10px' }}
                >
                  Modifier
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSelectCollectivite(collectivite.id, collectivite.nom)}
                  sx={{ marginRight: '10px' }}
                >
                  Choisir cette collectivité
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSelectCommunes(collectivite.id)}
                >
                  Sélectionner les Communes
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default CollectiviteList;
