import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import "./AdressesList.css";
import config from '../../config';




function AdressesList({ codeInsee, handleAdresseSelected3, selectedBuildingIds, setSelectedBuildingIds , adressesByRue , filteredAdresses , setFilteredAdresses , setAdressesByRue , isLoading }) {
    // const [adressesByRue, setAdressesByRue] = useState([]);
    // const [filteredAdresses, setFilteredAdresses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // const [isLoading, setIsLoading] = useState(false);
    const adressesPerPage = 50;



    useEffect(() => {
        const results = adressesByRue.filter(rue => 
            rue.rue.toLowerCase().includes(searchTerm.toLowerCase()) || // Recherche par nom de rue
            rue.adresses.some(adresse => adresse.adresse_complete.toLowerCase().includes(searchTerm.toLowerCase())) // Recherche par adresse complète
        );
        setFilteredAdresses(results);
    }, [searchTerm, adressesByRue]);

    const indexOfLastRue = currentPage * adressesPerPage;
    const indexOfFirstRue = indexOfLastRue - adressesPerPage;
    const currentRues = filteredAdresses.slice(indexOfFirstRue, indexOfLastRue);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="adresse-list-container">
            <input
                type="text"
                placeholder="Rechercher une adresse..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
            />
            {isLoading ? (
                <p>Chargement des adresses...</p>
            ) : (
                <>
                    <div className="streets-grid">
                        {currentRues.map((rueData, index) => (
                            <StreetSection key={index} rueData={rueData} handleAdresseSelected3={handleAdresseSelected3} selectedBuildingIds={selectedBuildingIds} setSelectedBuildingIds={setSelectedBuildingIds}/>
                        ))}
                    </div>
                    <Pagination
                        adressesPerPage={adressesPerPage}
                        totalAdresses={filteredAdresses.length}
                        paginate={paginate}
                    />
                </>
            )}
        </div>
    );
}







// function StreetSection({ rueData, handleAdresseSelected3, selectedBuildingIds, setSelectedBuildingIds }) {
//     const [isOpen, setIsOpen] = useState(false);
//     const [selectedAddresses, setSelectedAddresses] = useState(new Set());

//     useEffect(() => {
//         // Synchroniser les adresses sélectionnées avec les IDs du parent
//         const updatedSelectedAddresses = new Set(
//             rueData.adresses.filter(adresse => selectedBuildingIds.has(adresse.id)).map(adresse => adresse.id)
//         );
//         setSelectedAddresses(updatedSelectedAddresses);
//     }, [rueData.adresses, selectedBuildingIds]);

//     const allAddressesSelected = rueData.adresses.every(adresse => selectedBuildingIds.has(adresse.id));

//     const toggleOpen = () => setIsOpen(!isOpen);

//     const handleCheck = (event, adresse) => {
//         const newSelectedAddresses = new Set(selectedAddresses);
//         if (event.target.checked) {
//             newSelectedAddresses.add(adresse.id);
//             setSelectedBuildingIds(prev => new Set(prev.add(adresse.id)));
//         } else {
//             newSelectedAddresses.delete(adresse.id);
//             setSelectedBuildingIds(prev => {
//                 const newSet = new Set(prev);
//                 newSet.delete(adresse.id);
//                 return newSet;
//             });
//         }
//         setSelectedAddresses(newSelectedAddresses);
//         handleAdresseSelected3(adresse, event.target.checked);
//     };


//     const handleRueCheckbox = (event) => {
//         const isChecked = event.target.checked;
//         let newSelectedAddresses = new Set(selectedAddresses);
    
//         if (isChecked) {
//             rueData.adresses.forEach(adresse => {
//                 newSelectedAddresses.add(adresse.id);
//                 handleAdresseSelected3(adresse, true);
//             });
//             // Ajouter tous les IDs de cette rue à selectedBuildingIds
//             setSelectedBuildingIds(new Set([...selectedBuildingIds, ...newSelectedAddresses]));
//         } else {
//             rueData.adresses.forEach(adresse => {
//                 newSelectedAddresses.delete(adresse.id);
//                 handleAdresseSelected3(adresse, false);
//             });
//             // Retirer tous les IDs de cette rue de selectedBuildingIds
//             const updatedBuildingIds = new Set(selectedBuildingIds);
//             rueData.adresses.forEach(adresse => updatedBuildingIds.delete(adresse.id));
//             setSelectedBuildingIds(updatedBuildingIds);
//         }
//         setSelectedAddresses(newSelectedAddresses);
//     };

    
//     return (
//         <div className="street-section">
//             <div className="street-header">
//                 <input
//                     type="checkbox"
//                     onChange={handleRueCheckbox}
//                     checked={allAddressesSelected} // Vérifie si toutes les adresses de la rue sont sélectionnées
//                     className="street-checkbox"
//                 />
//                 <button onClick={toggleOpen} className="toggle-button">
//                     {isOpen ? '▼' : '►'}
//                 </button>
//                 <span className="street-name">{rueData.rue}</span>
//             </div>
//             {isOpen && (
//                 <ul className="addresses-list">
//                     {rueData.adresses.map((adresse) => (
//                         <li key={adresse.id} className="address-item">
//                             <input
//                                 type="checkbox"
//                                 checked={selectedAddresses.has(adresse.id)}
//                                 onChange={(e) => handleCheck(e, adresse)}
//                                 className="address-checkbox"
//                             />
//                             {adresse.numero ? `${adresse.numero} ` : ''}
//                             {adresse.rue}, {adresse.code_postal} {adresse.ville}
//                         </li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// }


function StreetSection({ rueData, handleAdresseSelected3, selectedBuildingIds, setSelectedBuildingIds }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedAddresses, setSelectedAddresses] = useState(new Set());
    const checkboxRef = useRef(null); // Référence pour le checkbox de la rue

    useEffect(() => {
        // Synchroniser les adresses sélectionnées avec les IDs du parent
        const updatedSelectedAddresses = new Set(
            rueData.adresses.filter(adresse => selectedBuildingIds.has(adresse.id)).map(adresse => adresse.id)
        );
        setSelectedAddresses(updatedSelectedAddresses);

        // Mettre à jour l'état intermédiaire du checkbox
        const allSelected = rueData.adresses.every(adresse => selectedBuildingIds.has(adresse.id));
        const noneSelected = rueData.adresses.every(adresse => !selectedBuildingIds.has(adresse.id));

        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = !allSelected && !noneSelected;
            checkboxRef.current.checked = allSelected;
        }
    }, [rueData.adresses, selectedBuildingIds]);

    const toggleOpen = () => setIsOpen(!isOpen);

    const handleCheck = (event, adresse) => {
        const newSelectedAddresses = new Set(selectedAddresses);
        if (event.target.checked) {
            newSelectedAddresses.add(adresse.id);
            setSelectedBuildingIds(prev => new Set(prev.add(adresse.id)));
        } else {
            newSelectedAddresses.delete(adresse.id);
            setSelectedBuildingIds(prev => {
                const newSet = new Set(prev);
                newSet.delete(adresse.id);
                return newSet;
            });
        }
        setSelectedAddresses(newSelectedAddresses);
        handleAdresseSelected3(adresse, event.target.checked);
    };

    const handleRueCheckbox = (event) => {
        const isChecked = event.target.checked;
        let newSelectedAddresses = new Set(selectedAddresses);
    
        if (isChecked) {
            rueData.adresses.forEach(adresse => {
                newSelectedAddresses.add(adresse.id);
                handleAdresseSelected3(adresse, true);
            });
            setSelectedBuildingIds(new Set([...selectedBuildingIds, ...newSelectedAddresses]));
        } else {
            rueData.adresses.forEach(adresse => {
                newSelectedAddresses.delete(adresse.id);
                handleAdresseSelected3(adresse, false);
            });
            const updatedBuildingIds = new Set(selectedBuildingIds);
            rueData.adresses.forEach(adresse => updatedBuildingIds.delete(adresse.id));
            setSelectedBuildingIds(updatedBuildingIds);
        }
        setSelectedAddresses(newSelectedAddresses);
    };

    return (
        <div className="street-section">
            <div className="street-header">
                <input
                    type="checkbox"
                    ref={checkboxRef}
                    onChange={handleRueCheckbox}
                    className="street-checkbox"
                />
                <button onClick={toggleOpen} className="toggle-button">
                    {isOpen ? '▼' : '►'}
                </button>
                <span className="street-name">{rueData.rue}</span>
            </div>
            {isOpen && (
                <ul className="addresses-list">
                    {rueData.adresses.map((adresse) => (
                        <li key={adresse.id} className="address-item">
                            <input
                                type="checkbox"
                                checked={selectedAddresses.has(adresse.id)}
                                onChange={(e) => handleCheck(e, adresse)}
                                className="address-checkbox"
                            />
                            {adresse.numero ? `${adresse.numero} ` : ''}
                            {adresse.rue}, {adresse.code_postal} {adresse.ville}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

  
function Pagination({ adressesPerPage, totalAdresses, paginate }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalAdresses / adressesPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="pagination-container">
            <ul className='pagination'>
                {pageNumbers.map(number => (
                    <li key={number} className='page-item'>
                        <button onClick={() => paginate(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default AdressesList;
